import * as React from "react";
import {chakra, HStack} from "@chakra-ui/react";
import VolumeButton from "./VolumeButton";
import {useRecoilValue} from "recoil";
import {menuVisibleState} from "../../room/roomState";
import PlayPauseButton from "./PlayPauseButton";
import MoviePlaybackBar from "./MoviePlaybackBar";
import {isValidMotionProp, motion} from "framer-motion";
import FullScreenButton from "./FullScreenButton";
import {isMobile} from "react-device-detect";
import SubtitlesButton from "./SubtitlesButton";
import PlaybackTime from "./PlaybackTime";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const BottomToolbar = () => {
  const hoverActivated = useRecoilValue(menuVisibleState);

  return (
    <ChakraBox
      width="full"
      background="linear-gradient(0deg, #111111 0%, rgba(17, 17, 17, 0.8) 100%);"
      animate={{opacity: hoverActivated ? 1 : 0}}
    >
      <MoviePlaybackBar/>
      <HStack p={4} justifyContent="space-between" w="full">
        <HStack spacing={4}>
          <PlayPauseButton/>
          <PlaybackTime/>
          {!isMobile && (
            <VolumeButton/>
          )}
        </HStack>
        <HStack>
          <SubtitlesButton/>
          <FullScreenButton/>
        </HStack>
      </HStack>
    </ChakraBox>
  );
};

export default BottomToolbar;
