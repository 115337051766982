import * as React from "react";
import {useMemo, useRef, useState} from "react";
import styled from "styled-components";
import useEventListener from "@use-it/event-listener";
import {PreviewThumbnails} from "../PreviewThumbnails";
import Tippy from "@tippyjs/react";
import {followCursor} from 'tippy.js';
import {Box} from "@chakra-ui/react";
import {between} from "../utils";
import {useWeWatchContext} from "../room/WeWatchProvider";

type PlaybackBarProps = {
  duration: number
  currentTime: number
  buffered: TimeRanges
  segmentStarts: number[]
  setTimestamp?: (timestamp: number) => void
  thumbnailVttUrl?: string
}

type Range = {
  start: number
  end: number
}

function extractRanges(timeRanges: TimeRanges, min: number = 0): Range[] {
  const result: Range[] = [];
  for (let i = 0; i < timeRanges.length; i++) {
    const start = Math.max(timeRanges.start(i), min);
    const end = Math.max(timeRanges.end(i), min);
    if (end - start > 0) {
      result.push({start, end});
    }
  }
  return result;
}

const Wrapper = styled.div`
  width: 100%;
  height: 8px;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Circle = styled.div`
  position: absolute;
  border-radius: 48px;
  width: 0;
  height: 0;
  margin-right: 0;
  margin-top: 0;
  background-color: #64DFDF;
  z-index: 150;
  opacity: 0;
  transition: width 150ms, height 150ms, margin-right 150ms, margin-top 150ms, opacity 150ms;
  display: none;

  ${Wrapper}:hover & {
    width: 12px;
    height: 12px;
    margin-right: -6px;
    margin-top: -6px;
    opacity: 1;
  }
`;

const Bar = styled.div`
  height: 8px;
  background: #828282;
  position: relative;
`

const CurrentTimeBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #64DFDF;
`;

const BufferedBar = styled(CurrentTimeBar)`
  background: #F2F2F2;
  opacity: 0.4;
`

const RestrictedBar = styled(CurrentTimeBar)`
  background: #552525;
  cursor: not-allowed;
`

const PlaybackBar = ({
                       currentTime,
                       duration,
                       buffered,
                       segmentStarts,
                       setTimestamp,
                       thumbnailVttUrl
                     }: PlaybackBarProps) => {

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [localTimestamp, setLocalTimestamp] = useState<number | undefined>(undefined);
  const playbackPlaylist = useWeWatchContext()?.reactionController?.playbackPlaylist;
  const totalDuration = playbackPlaylist?.durationSec ?? duration;

  const previewThumbnails = useMemo(() => {
      if (thumbnailVttUrl) {
        return new PreviewThumbnails(thumbnailVttUrl);
      } else {
        return undefined;
      }
    },
    [thumbnailVttUrl]
  );

  const tippyContentContainerRef = useRef<HTMLDivElement>(null);
  const tippyContentTimestampRef = useRef<HTMLDivElement>(null);
  const tippyContentImageRef = useRef<HTMLImageElement>(null);

  const tippyContent = <>
    <div ref={tippyContentContainerRef}>
      <img ref={tippyContentImageRef} alt=""/>
    </div>
    <Box
      ref={tippyContentTimestampRef}
      textAlign="center"
      mt={1}
    >
      &nbsp;
    </Box>
  </>;

  const handleClick = (e: any) => {
    if (setTimestamp) {
      const clickPos = e.clientX;
      const wrapperLeft = wrapperRef.current!.offsetLeft;
      const wrapperWidth = wrapperRef.current!.clientWidth;
      setTimestamp((clickPos - wrapperLeft) / wrapperWidth * totalDuration);
    }
  }

  const handleMouseMove = (e: any) => {
    if (!wrapperRef.current) {
      return;
    }
    const clickPos = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const wrapperLeft = wrapperRef.current!.offsetLeft;
    const wrapperWidth = wrapperRef.current!.clientWidth;
    const newTimestamp = between((clickPos - wrapperLeft) / wrapperWidth, 0, 1) * totalDuration;
    if (setTimestamp && dragging) {
      setLocalTimestamp(newTimestamp);
    }
    if (previewThumbnails && playbackPlaylist) {
      previewThumbnails.updateThumbnail(
        tippyContentContainerRef.current!,
        tippyContentImageRef.current!,
        tippyContentTimestampRef.current!,
        playbackPlaylist.timestampToMovieTime(newTimestamp),
        newTimestamp
      );
    }
  }

  const handleMouseUp = () => {
    setDragging(false);
    if (setTimestamp && localTimestamp) {
      setTimestamp(localTimestamp);
    }
    setLocalTimestamp(undefined);
  }

  useEventListener('mousemove', handleMouseMove);
  useEventListener('touchmove', handleMouseMove);
  useEventListener('mouseup', handleMouseUp);
  useEventListener('touchend', handleMouseUp);

  const timestamp = Math.min(localTimestamp !== undefined ? localTimestamp : currentTime, duration);

  return <Tippy
    content={tippyContent}
    hideOnClick={false}
    followCursor="horizontal"
    plugins={[followCursor]}
    disabled={!previewThumbnails}
  >
    <Wrapper
      ref={wrapperRef}
      onClick={handleClick}
    >
      <Circle
        style={{right: `${100 - (timestamp / totalDuration * 100)}%`}}
        onMouseDown={() => setDragging(true)}
      />
      <Bar onMouseDown={() => setDragging(true)} onTouchStart={() => setDragging(true)}>
        <CurrentTimeBar
          style={{right: `${100 - (timestamp / totalDuration * 100)}%`}}
        />
        {/*{extractRanges(buffered, timestamp).map((range, i) => (*/}
        {/*  <BufferedBar*/}
        {/*    key={i}*/}
        {/*    style={{*/}
        {/*      right: `${100 - (range.end / totalDuration * 100)}%`,*/}
        {/*      left: `${range.start / totalDuration * 100}%`*/}
        {/*    }}*/}
        {/*  />*/}
        {/*))}*/}
        {segmentStarts.filter(Boolean).map(start => (
          <Box
            key={start}
            bgColor="#F2F2F2"
            opacity={1}
            width="2px"
            position="absolute"
            top={0}
            bottom={0}
            left={`${(start / totalDuration * 100)}%`}
          />
        ))}
        {totalDuration !== duration && (
          <RestrictedBar
            style={{
              right: 0,
              left: `${duration / totalDuration * 100}%`
            }}
          />
        )}
      </Bar>
    </Wrapper>
  </Tippy>;
};

export default PlaybackBar;
