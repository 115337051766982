import {useEffect} from "react";
import {useCalculatePrice} from "../../useWewatchApi";

type PriceCalculator = {
  total: string
  subtotal: string
  discount?: string
  promoDiscount?: string
  promoCodeStatus?: 'VALID' | 'EXPIRED' | 'INVALID'
};

export const formatMoney = (valueCents: number): string => {
  const dollars = Math.floor(valueCents / 100);
  const cents = Math.round(valueCents) % 100;

  return dollars + '.' + (cents < 10 ? '0' : '') + cents;
}

const usePriceCalculator = (movieId: string, quantity: number, promoCode?: string): PriceCalculator | undefined => {
  const {loading, data, post} = useCalculatePrice();

  useEffect(() => {
    post({
      movieId,
      quantity,
      promoCode
    }).catch((e) => console.log('Error calculating price', e));
  }, [movieId, quantity, promoCode]);

  if (loading || !data) {
    return undefined;
  } else {
    return {
      total: formatMoney(data.totalCents),
      subtotal: formatMoney(data.subtotalCents),
      discount: data.discountCents ? formatMoney(data.discountCents) : undefined,
      promoDiscount: data.promoDiscountCents ? formatMoney(data.promoDiscountCents) : undefined,
      promoCodeStatus: data.promoCodeStatus
    }
  }
}

export default usePriceCalculator;
