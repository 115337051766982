import * as React from "react";
import FixedPageHeader from "../../components/FixedPageHeader";
import {Avatar, Box, Container, Flex, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {Navigate, useMatch, useNavigate} from "react-router";
import MyPurchases from "./MyPurchases";
import MyRecordings from "./MyRecordings";
import Settings from "./Settings";
import FullscreenLoader from "../../components/FullscreenLoader";

const pathToIndex = (path: string) => {
  switch (path) {
    case '/me/purchases':
      return 0;
    case '/me/recordings':
      return 1;
    case '/me/settings':
      return 2;
    default:
      return 0;
  }
}

const MePage = () => {

  const {user} = useAuth0();
  const navigate = useNavigate();
  const match = useMatch('*');
  const tabIndex = pathToIndex(match!.pathname);

  if (!user) {
    return <Navigate to="/"/>
  }

  return (
    <>
      <FixedPageHeader/>
      <Container maxW='container.lg'>
        <Box pt={20} pb={20}>
          <HStack align={'center'} justify={'center'}>
            <Avatar name={user.name} src={user.picture} size="lg"/>
            <Text fontWeight={800}>{user.name}</Text>
          </HStack>
        </Box>
        <Tabs isLazy index={tabIndex}>
          <TabList>
            <Tab onClick={() => navigate('/me/purchases')}>Purchases</Tab>
            <Tab onClick={() => navigate('/me/recordings')}>Curations</Tab>
            <Flex grow={1}/>
            <Tab onClick={() => navigate('/me/settings')}>Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MyPurchases/>
            </TabPanel>
            <TabPanel>
              <MyRecordings/>
            </TabPanel>
            <TabPanel>
              <Settings/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
};

export default withAuthenticationRequired(
  MePage,
  {
    onRedirecting: () => <FullscreenLoader title="Signing in&hellip;"/>
  }
);
