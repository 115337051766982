import * as React from "react";
import {useEffect, useState} from "react";
import {Box, ButtonGroup, IconButton, Menu, MenuButton, MenuItemOption, MenuList} from "@chakra-ui/react";
import {useDevices} from "../../mediaDevices";
import {useRecoilValue} from "recoil";
import {selfDeviceState} from "../../room/roomState";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useWeWatchController} from "../../room/WeWatchProvider";

type DeviceButtonProps = {
  kind: MediaKind
  enabledIcon: React.ReactElement
  disabledIcon: React.ReactElement
}

const getStream = async (kind: MediaKind, deviceId: string) => {
  return await navigator.mediaDevices.getUserMedia({
    [kind]: {
      deviceId: {ideal: deviceId}
    }
  });
}

const DeviceButton = ({kind, enabledIcon, disabledIcon}: DeviceButtonProps) => {

  const weWatchController = useWeWatchController();
  const devices = useDevices(kind);
  const state = useRecoilValue(selfDeviceState(kind));
  const [selectedDevice, setSelectedDevice] = useState<string>();

  const enabled = state.status === 'connected';

  const controller = kind === 'video' ? weWatchController?.showroomClient?.webcamController : weWatchController?.showroomClient?.microphoneController

  useEffect(() => {
    setSelectedDevice(controller?.selectedDeviceId);
  }, [controller, state])

  const onChange = async (deviceId?: string) => {
    if (deviceId) {
      const stream = await getStream(kind, deviceId)
      const track = kind === 'video' ? stream.getVideoTracks()[0] : stream.getAudioTracks()[0];
      if (track) {
        await controller?.selectDeviceAndTrack(deviceId, track);
        return;
      }
    }
    controller?.selectDevice(undefined);
  }

  const toggle = () => {
    if (enabled) {
      controller?.pause();
    } else {
      controller?.resume();
    }
  }

  if (!controller) {
    return <Box>
      <ButtonGroup isAttached>
        <IconButton
          aria-label={`Toggle ${kind}`}
          variant="ghost"
          isLoading={true}
        />
        <IconButton
          aria-label={`Adjust ${kind}`}
          icon={<ChevronDownIcon/>}
          variant="ghost"
          disabled
        />
      </ButtonGroup>
    </Box>
  }

  return (
    <Box>
      <Menu isLazy={true}>
        <ButtonGroup isAttached>
          <IconButton
            aria-label={`Toggle ${kind}`}
            icon={enabled ? enabledIcon : disabledIcon}
            onClick={toggle}
          />
          <MenuButton
            as={IconButton}
            aria-label={`Adjust ${kind}`}
            icon={<ChevronDownIcon/>}
            variant="ghost"
          />
          <MenuList>
            <MenuItemOption
              onClick={() => onChange(undefined)}
              isChecked={selectedDevice === undefined}
            >
              None
            </MenuItemOption>
            {devices.map(device => (
              <MenuItemOption
                key={device.deviceId}
                onClick={() => onChange(device.deviceId)}
                isChecked={selectedDevice === device.deviceId}
              >
                {device.label}
              </MenuItemOption>
            ))}
          </MenuList>
        </ButtonGroup>
      </Menu>
    </Box>
  );
};

export default DeviceButton;
