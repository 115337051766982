import {useAuth0} from "@auth0/auth0-react";
import {apiBaseUrl} from "./http";
import {useEffect, useRef, useState} from "react";

type WeWatchApiGet<T> = {
  loading: boolean
  data?: T
  forceReload: () => Promise<T>
}

type WeWatchApiPost<T, I> = {
  loading: boolean
  data?: T
  post: (input: I) => Promise<T>
}

type NoInputWeWatchApiPost<T> = {
  loading: boolean
  data?: T
  post: () => Promise<T>
}

type ApiOptions = {
  authenticated?: boolean
  anonymousFallbackPath?: string
}

export const useWeWatchGetApi = <TRes>(path: string | undefined, opts: ApiOptions, deps: any[] = []): WeWatchApiGet<TRes> => {
  const {authenticated = true, anonymousFallbackPath} = opts;
  const {getAccessTokenSilently, isAuthenticated} = useAuth0();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const loadData = async () => {
    if (!path) {
      setData(undefined);
      return;
    }
    setLoading(true);
    try {
      const shouldAuth = authenticated && (isAuthenticated || !anonymousFallbackPath);
      const url = apiBaseUrl + ((!isAuthenticated ? anonymousFallbackPath : undefined) ?? path)
      const resp = await fetch(url, {
        headers: shouldAuth ? {
          'Authorization': `Bearer ${await getAccessTokenSilently()}`
        } : {}
      });

      const value = await resp.json();
      setData(value);
      setLoading(false);
      return value;
    } catch (e) {
      console.error(e);
      setData(undefined);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    forceReload: loadData
  }
};

type InputOrFunction<I, T> = T | (I extends undefined ? never : (input: I) => T)

export function useWeWatchPostApi<TReq extends {}, TRes>(path: InputOrFunction<undefined, string>, body?: InputOrFunction<undefined, TReq>): NoInputWeWatchApiPost<TRes>;
export function useWeWatchPostApi<TInput extends {}, TReq extends {}, TRes>(path: InputOrFunction<TInput, string>, body?: InputOrFunction<TInput, TReq>): WeWatchApiPost<TRes, TInput>;
export function useWeWatchPostApi<TInput, TReq extends {}, TRes>(path: InputOrFunction<TInput, string>, body?: InputOrFunction<TInput, TReq>): WeWatchApiPost<TRes, TInput> {
  const {getAccessTokenSilently} = useAuth0();
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef<AbortController>();
  const [data, setData] = useState();

  const loadData = async (input: TInput) => {
    if (loading) {
      abortControllerRef.current?.abort(); // Abort existing request
    }
    setLoading(true);
    abortControllerRef.current = new AbortController()
    const token = await getAccessTokenSilently();

    const resolvedPath = path instanceof Function ? path(input) : path;
    const resolvedBody = body instanceof Function ? body(input) : body;

    const resp = await fetch(apiBaseUrl + resolvedPath, {
      signal: abortControllerRef.current.signal,
      body: JSON.stringify(resolvedBody),
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    const responseBody = await resp.json();
    setData(responseBody);
    setLoading(false);
    return responseBody;
  }

  return {
    loading,
    data,
    post: loadData
  }
}

export type MovieDetailsResponse = {
  genres: string[]
  title: string
  year: number
  lengthMs: number
  synopsis: string
  directors: string[]
  casts: string[]
  languageAudio: string
  s3BackgroundKey: string
  trailer?: string
  price: string
}

type MovieResponse = {
  id: number
  s3PosterKey: string
  language: string
  lengthMs: number
  title: string
  year: number
  free: boolean
}

type MyReactionResponse = {
  id: string
  movieId: string
  movieTitle: string
  movieThumbnail: string
  reactionTitle: string
  reactionThumbnail: string
  lengthMs?: number
  visibility: 'PUBLIC' | 'PRIVATE'
  recordedDate?: Date
}

type UpdateReactionRequest = {
  visibility: 'PUBLIC' | 'PRIVATE',
  reactionTitle: string
}

type MyPurchaseResponse = {
  id: string
  movieId: string
  movieTitle: string
  tickets: Array<{
    status: 'INACTIVE' | 'ACTIVE' | 'EXPIRED' | 'CANCELLED',
    expiresAt?: string
  }>
  purchasedAt: string
  initialPrice: string
  settledPrice?: string
}

type CalculatePriceRequest = {
  movieId: string,
  quantity: number,
  promoCode?: string
}

type CalculatePriceResponse = {
  totalCents: number
  subtotalCents: number
  discountCents?: number
  promoDiscountCents?: number
  promoCodeValid: boolean
  promoCodeStatus?: 'VALID' | 'EXPIRED' | 'INVALID';
}

export type ReactionPart = {
  name: string
  userId: string
  clips: ReactionPartClip[]
}

export type ReactionPartClip = {
  movieTimeMs: number
  reactionTimeMs: number
  movieState: 'PLAY' | 'PAUSE'
  s3HlsPlaylistKey: string
  lengthMs: number
}

export type ReactionResponse = {
  id: string
  reactionTitle: string
  individuals: string[]
  parts: ReactionPart[]
  reactionThumbnail: string
  movieLengthMs: number
}

export const useListMovies = () => {
  return useWeWatchGetApi<MovieResponse[]>('/movies', {authenticated: false});
}

export const useGetMovieDetails = (movieId: string) => {
  return useWeWatchGetApi<MovieDetailsResponse>('/movies/' + movieId, {authenticated: false}, [movieId]);
}

export const useListMyReactions = () => {
  return useWeWatchGetApi<MyReactionResponse[]>('/reactionsV2', {authenticated: true});
}

export const useListMyPurchases = () => {
  return useWeWatchGetApi<MyPurchaseResponse[]>('/purchases', {authenticated: true});
}

export const useUpdateReaction = () => {
  return useWeWatchPostApi<{ reactionId: string, visibility: 'PUBLIC' | 'PRIVATE', title: string }, UpdateReactionRequest, void>(
    input => `/reactionV2/${input.reactionId}`,
    input => ({
      visibility: input.visibility,
      reactionTitle: input.title
    })
  )
}

export const useActivateTicket = (movieId: string) => {
  return useWeWatchPostApi<{}, void>('/activate-ticket/' + movieId, {});
}

export const useCalculatePrice = () => {
  return useWeWatchPostApi<CalculatePriceRequest, CalculatePriceRequest, CalculatePriceResponse>(
    '/calculate-price',
    input => input
  );
}

export const useGetReaction = (movieId?: string, reactionId?: string) => {
  const path = (movieId && reactionId) ? `/movies/${movieId}/reactionsV2/${reactionId}` : undefined;
  return useWeWatchGetApi<ReactionResponse>(path, {authenticated: false}, [movieId, reactionId]);
}

export type LoadTicketResponse = {
  movieUrl?: string
  previewUrl?: string
  movieTitle: string
  movieThumbnailKey: string
  hasActiveTicket: boolean
  numberInactiveTickets: number
  lengthMs?: number
}

export const useLoadTicket = (movieId: string) => {
  const path = movieId ? '/movie-purchase/' + movieId : undefined;
  return useWeWatchGetApi<LoadTicketResponse>(path, {authenticated: true, anonymousFallbackPath: '/public-movie-purchase/' + movieId}, [movieId]);
}

export type GetReactionsResponse = {
  reactions: ReactionListResponse[]
}

export type ReactionListResponse = {
  id: string
  individuals: string[]
  reactionThumbnail: string
}

export const useGetReactions = (movieId: string) => {
  const path = movieId ? `/movies/${movieId}/reactionsV2` : undefined;
  return useWeWatchGetApi<GetReactionsResponse>(path, {authenticated: false}, [movieId]);
}
