import EventEmitter from "eventemitter3";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";
import {ProducerTransport} from "./streaming/ProducerTransport";
import {Producer} from "mediasoup-client/lib/Producer";

export type InputDeviceStatus = 'disconnected' | 'inProgress' | 'connected' | 'disabled';

export class InputDeviceController {

  private readonly eventEmitter = new EventEmitter();

  private readonly kind: MediaKind;
  private readonly transport: ProducerTransport;
  private readonly constraints: MediaTrackConstraints;

  private readonly devices = new Map<string, MediaDeviceInfo>();
  private producer?: Producer;
  private currentDeviceId?: string;

  constructor(kind: MediaKind, transport: ProducerTransport, constraints: MediaTrackConstraints = {}) {
    this.transport = transport;
    this.kind = kind;
    this.constraints = constraints;
  }

  on(event: 'stateUpdated', fn: (status: InputDeviceStatus, track: MediaStreamTrack) => void): InputDeviceController;
  on(event: string, fn: EventEmitter.EventListener<any, any>) {
    this.eventEmitter.on(event, fn);
    return this;
  }

  async start() {
    if (this.producer) {
      return;
    }

    this.eventEmitter.emit('stateUpdated', 'inProgress', undefined);

    try {
      await this.updateDevices();
      if (!this.currentDeviceId) {
        this.eventEmitter.emit('stateUpdated', 'disconnected', undefined);
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        [this.kind]: {
          deviceId: {ideal: this.currentDeviceId},
          ...this.constraints
        }
      });

      const track = this.kind === 'video' ? stream.getVideoTracks()[0] : stream.getAudioTracks()[0];

      this.producer = await this.transport.produce(track);

      this.producer.on('trackended', () => {
        this.stop();
      });

      this.producer.observer.on('close', () => {
        this.eventEmitter.emit('stateUpdated', 'disconnected', track);
      });

      this.eventEmitter.emit('stateUpdated', 'connected', track);
    } catch (e) {
      console.error(e);
    }
  }

  stop() {
    if (!this.producer) {
      return;
    }

    this.producer.close();
    this.producer = undefined;
  }

  async resume() {
    if (!this.producer) {
      await this.start();
    } else {
      this.producer?.resume();
      this.eventEmitter.emit('stateUpdated', 'connected', this.producer?.track);
    }
  }

  async pause() {
    if (!this.producer) {
      return;
    }
    this.producer.pause();
    this.eventEmitter.emit('stateUpdated', 'disabled');
  }

  async updateDevices(updateCurrentDevice: boolean = true) {
    this.devices.clear();

    const filteredDevices = (await navigator.mediaDevices.enumerateDevices())
      .filter(d => d.kind === (this.kind + 'input'));

    filteredDevices.forEach(device => {
      this.devices.set(device.deviceId, device);
    });

    if (!filteredDevices.length) {
      this.currentDeviceId = undefined;
      return;
    }

    if (this.currentDeviceId && this.devices.has(this.currentDeviceId)) {
      return;
    }

    if (updateCurrentDevice) {
      this.currentDeviceId = filteredDevices[0].deviceId;
    }
  }

  get deviceList(): MediaDeviceInfo[] {
    return Array.from(this.devices.values());
  }

  get selectedDeviceId(): string | undefined {
    return this.currentDeviceId;
  }

  async selectDeviceAndTrack(deviceId: string, track: MediaStreamTrack) {
    if (deviceId !== this.currentDeviceId) {
      this.currentDeviceId = deviceId;
      this.stop();
      if (this.producer) {
        return;
      }

      this.eventEmitter.emit('stateUpdated', 'inProgress', undefined);

      try {
        await this.updateDevices();
        if (!this.currentDeviceId) {
          this.eventEmitter.emit('stateUpdated', 'disconnected', undefined);
          return;
        }

        this.producer = await this.transport.produce(track);

        this.producer.on('trackended', () => {
          this.stop();
        });

        this.producer.observer.on('close', () => {
          this.eventEmitter.emit('stateUpdated', 'disconnected', track);
        });

        this.eventEmitter.emit('stateUpdated', 'connected', track);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async selectDevice(device: MediaDeviceInfo | undefined) {
    this.currentDeviceId = device?.deviceId;
    this.stop();
    if (device) {
      await this.start();
    }
  }
}
