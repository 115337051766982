import {
  atom,
  AtomEffect,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue
} from "recoil";
import {MovieDetails, ReactionDetails, ShowroomState} from "./ShowroomState";
import {InputDeviceStatus} from "./InputDeviceController";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";

const localStorageEffect = <T>(key: string): AtomEffect<T> => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  });
};

export const showroomState = atom<Partial<ShowroomState>>({
  key: 'showroomState',
  default: {
    members: []
  }
});

export const useShowroomState = () => useRecoilValue(showroomState)!;

export const movieDetailsState = atom<MovieDetails | undefined>({
  key: 'movieDetails',
  default: undefined
});

export const selectedReactionIdState = atom<string | undefined>({
  key: 'selectedReactionId',
  default: undefined
});

export const reactionDetailsState = atom<ReactionDetails | undefined>({
  key: 'reactionDetails',
  default: undefined
});

export const fullScreenState = atom<boolean>({
  key: 'fullScreen',
  default: false
})

export type PlaybackState = 'playing' | 'paused';

export const videoPlaybackState = atom<PlaybackState>({
  key: 'playbackState',
  default: 'paused'
});

export const textTracksState = atom<TextTrack[]>({
  key: 'textTracks',
  default: []
})

export const movieBufferedState = atom<TimeRanges>({
  key: 'movieBuffered',
  default: {
    length: 0,
    start: function () {
      throw new Error('This TimeRanges object is empty');
    },
    end: function () {
      throw new Error('This TimeRanges object is empty');
    }
  }
});

export type MemberState = {
  id: string
  name: string
  videoTrack?: MediaStreamTrack
  audioTrack?: MediaStreamTrack
  videoEnabled?: boolean
  audioEnabled?: boolean
}

export const membersState = atom<string[]>({
  key: 'members',
  default: []
});

export const memberNameState = atomFamily<string, string>({
  key: 'memberName',
  default: memberId => memberId
});

export const memberVideoTrackState = atomFamily<MediaStreamTrack | undefined, string>({
  key: 'memberVideoTrack',
  default: undefined
});

export const memberVideoEnabledState = atomFamily<boolean, string>({
  key: 'memberVideoEnabled',
  default: false
});

export const memberAudioTrackState = atomFamily<MediaStreamTrack | undefined, string>({
  key: 'memberAudioTrack',
  default: undefined
});

export const memberAudioEnabledState = atomFamily<boolean, string>({
  key: 'memberAudioEnabled',
  default: false
});

export const memberState = selectorFamily<MemberState, string>({
  key: 'member',
  get: (memberId) => ({get}) => {
    return {
      id: memberId,
      name: get(memberNameState(memberId)),
      videoTrack: get(memberVideoTrackState(memberId)),
      videoEnabled: get(memberVideoEnabledState(memberId)),
      audioTrack: get(memberAudioTrackState(memberId)),
      audioEnabled: get(memberAudioEnabledState(memberId)),
    }
  }
});

export type SelfDeviceState = {
  track?: MediaStreamTrack
  status: InputDeviceStatus
}

export const selfDeviceState = atomFamily<SelfDeviceState, MediaKind>({
  key: 'selfDevice',
  default: {
    status: 'disconnected'
  }
});

export const hoverActivatedState = atom<boolean>({
  key: 'hoverActivated',
  default: false
});

export const forceMenuState = atom<number[]>({
  key: 'forceMenu',
  default: []
});

export const menuVisibleState = selector<boolean>({
  key: 'menuVisible',
  get: ({get}) => get(hoverActivatedState) || get(forceMenuState).length > 0
})

export const videoVolumeState = atom<number>({
  key: 'videoVolume',
  default: 1,
  effects: [
    localStorageEffect('videoVolume')
  ]
});

export const chatVolumeState = atom<number>({
  key: 'chatVolume',
  default: 1,
  effects: [
    localStorageEffect('chatVolume')
  ]
});

export const addUserModalOpenState = atom<boolean>({
  key: 'addUserModalOpen',
  default: false
});

export const sidebarVisibleState = atom<boolean>({
  key: 'sidebarVisible',
  default: false
});

export const watchTogetherActivatedState = atom<boolean>({
  key: 'watchTogetherActivated',
  default: false
});

export const useResetRoomState = () => {
  return useRecoilCallback(
    ({reset}) => () => {
      reset(videoPlaybackState);
      reset(movieBufferedState);
      reset(membersState);
      reset(selfDeviceState('video'));
      reset(selfDeviceState('audio'));
      reset(watchTogetherActivatedState);
      reset(sidebarVisibleState);
      reset(addUserModalOpenState);
      reset(showroomState);
      reset(selectedReactionIdState);
      reset(movieDetailsState);
      reset(reactionDetailsState);
      reset(textTracksState);
    },
    []
  );
}
