import * as React from "react";
import {useEffect, useState} from "react";
import PlaybackBar from "../../components/PlaybackBar";
import {useRecoilValue} from "recoil";
import {movieBufferedState, movieDetailsState} from "../../room/roomState";
import {useHotkeys} from "react-hotkeys-hook";
import {useDebouncedShowroomMixpanelTracker} from "../../mixpanelHooks";
import {isMobile} from "react-device-detect";
import {useWeWatchContext, useWeWatchController} from "../../room/WeWatchProvider";
import {MoviePlayerListener} from "../../room/MoviePlayerListener";

const MoviePlaybackBar = () => {

  const weWatchController = useWeWatchController();
  const showroomClient = weWatchController.showroomClient;
  const playbackController = weWatchController.playbackController;
  const movieDetails = useRecoilValue(movieDetailsState);
  const playbackPlaylist = useWeWatchContext()?.reactionController?.playbackPlaylist;
  const duration = playbackPlaylist?.durationSec ?? 0;
  const buffered = useRecoilValue(movieBufferedState);
  const currentTimeTracker = useDebouncedShowroomMixpanelTracker('Adjust Movie Playback');

  const [currentTime, setCurrentTime] = useState<number>(0);
  useEffect(() => {
    const playbackController = weWatchController.playbackController;

    const listener: MoviePlayerListener = {
      onUpdate: (paused, currentTime) => {
        setCurrentTime(currentTime);
      }
    };

    playbackController.registerListener(listener);

    return () => {
      playbackController.removeListener(listener);
    }
  }, [weWatchController]);

  useHotkeys('right', () => {
    setCurrentTime(currentTime => {
      const newTime = Math.min(duration, currentTime + 10);
      playbackController?.setTime(newTime);
      currentTimeTracker({'Final Content Timestamp': newTime}, {'Initial Content Timestamp': currentTime});
      return newTime;
    })
  }, [showroomClient, duration]);

  useHotkeys('left', () => {
    setCurrentTime(currentTime => {
      const newTime = Math.max(0, currentTime - 10);
      playbackController?.setTime(newTime);
      currentTimeTracker({'Final Content Timestamp': newTime}, {'Initial Content Timestamp': currentTime});
      return newTime;
    })
  }, [showroomClient]);

  return (
    <PlaybackBar
      duration={duration}
      currentTime={currentTime}
      buffered={buffered}
      segmentStarts={playbackPlaylist?.segments?.map(s => s.startTimeSec) ?? []}
      thumbnailVttUrl={isMobile ? undefined : movieDetails?.movieThumbnailSrc}
      setTimestamp={(timestamp) => {
        currentTimeTracker({'Final Content Timestamp': timestamp}, {'Initial Content Timestamp': currentTime});
        setCurrentTime(timestamp);
        playbackController?.setTime(timestamp);
      }}
    />
  );
};

export default MoviePlaybackBar;
