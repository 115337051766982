import {createIcon, Icon, IconProps} from "@chakra-ui/react";

export const AddUserIcon = createIcon({
  displayName: 'AddIcon',
  viewBox: "0 0 16 16",
  d: "M7 7C8.10457 7 9 6.10457 9 5C9 3.89543 8.10457 3 7 3C5.89543 3 5 3.89543 5 5C5 6.10457 5.89543 7 7 7ZM10 5C10 6.1736 9.32611 7.18979 8.34417 7.68275C10.4528 8.26986 12 10.2042 12 12.5C12 12.7761 11.7761 13 11.5 13C11.2239 13 11 12.7761 11 12.5C11 10.2909 9.20914 8.5 7 8.5C4.79086 8.5 3 10.2909 3 12.5C3 12.7761 2.77614 13 2.5 13C2.22386 13 2 12.7761 2 12.5C2 10.2042 3.54724 8.26986 5.65583 7.68275C4.67389 7.1898 4 6.1736 4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5ZM12.5 9C12.2239 9 12 8.77614 12 8.5V7.5H11C10.7239 7.5 10.5 7.27614 10.5 7C10.5 6.72386 10.7239 6.5 11 6.5H12V5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V6.5H14C14.2761 6.5 14.5 6.72386 14.5 7C14.5 7.27614 14.2761 7.5 14 7.5H13V8.5C13 8.77614 12.7761 9 12.5 9Z"
});

export const AddCurationIcon = createIcon({
  displayName: 'AddCuration',
  viewBox: "0 0 24 24",
  path: [
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 5.75C1.5 4.23122 2.73122 3 4.25 3H19.75C21.2688 3 22.5 4.23122 22.5 5.75V12C22.5 12.4142 22.1642 12.75 21.75 12.75C21.3358 12.75 21 12.4142 21 12V5.75C21 5.05964 20.4404 4.5 19.75 4.5H4.25C3.55964 4.5 3 5.05964 3 5.75V18.25C3 18.9404 3.55964 19.5 4.25 19.5H12C12.4142 19.5 12.75 19.8358 12.75 20.25C12.75 20.6642 12.4142 21 12 21H4.25C2.73122 21 1.5 19.7688 1.5 18.25V5.75ZM9.11833 16.0658C8.71939 16.2653 8.25 15.9752 8.25 15.5292V8.47082C8.25 8.02479 8.71939 7.73469 9.11833 7.93416L16.1767 11.4633C16.6189 11.6845 16.6189 12.3155 16.1767 12.5367L9.11833 16.0658ZM19.5 21C19.0858 21 18.75 20.6642 18.75 20.25V18.75H17.25C16.8358 18.75 16.5 18.4142 16.5 18C16.5 17.5858 16.8358 17.25 17.25 17.25H18.75V15.75C18.75 15.3358 19.0858 15 19.5 15C19.9142 15 20.25 15.3358 20.25 15.75V17.25H21.75C22.1642 17.25 22.5 17.5858 22.5 18C22.5 18.4142 22.1642 18.75 21.75 18.75H20.25V20.25C20.25 20.6642 19.9142 21 19.5 21Z" fill="currentColor"/>
  ]
})

export const ToolbarMoreIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M15.667 16.6665C16.2193 16.6665 16.667 16.2188 16.667 15.6665C16.667 15.1142 16.2193 14.6665 15.667 14.6665C15.1147 14.6665 14.667 15.1142 14.667 15.6665C14.667 16.2188 15.1147 16.6665 15.667 16.6665Z" stroke="#F6F6F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.667 8.6665C16.2193 8.6665 16.667 8.21879 16.667 7.6665C16.667 7.11422 16.2193 6.6665 15.667 6.6665C15.1147 6.6665 14.667 7.11422 14.667 7.6665C14.667 8.21879 15.1147 8.6665 15.667 8.6665Z" stroke="#F6F6F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.667 24.6665C16.2193 24.6665 16.667 24.2188 16.667 23.6665C16.667 23.1142 16.2193 22.6665 15.667 22.6665C15.1147 22.6665 14.667 23.1142 14.667 23.6665C14.667 24.2188 15.1147 24.6665 15.667 24.6665Z" stroke="#F6F6F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </Icon>
);

export const EditIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path d="M8 13.333H14" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11 2.33316C11.2652 2.06794 11.6249 1.91895 12 1.91895C12.1857 1.91895 12.3696 1.95553 12.5412 2.0266C12.7128 2.09767 12.8687 2.20184 13 2.33316C13.1313 2.46448 13.2355 2.62038 13.3066 2.79196C13.3776 2.96354 13.4142 3.14744 13.4142 3.33316C13.4142 3.51888 13.3776 3.70277 13.3066 3.87435C13.2355 4.04594 13.1313 4.20184 13 4.33316L4.66667 12.6665L2 13.3332L2.66667 10.6665L11 2.33316Z" stroke="#F6F6F6" strokeLinecap="round" strokeLinejoin="round"/>
  </Icon>
);

export const RecordingIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" fill="currentColor"/>
  </Icon>
);

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  defaultProps: {
    boxSize: 6
  },
  viewBox: '0 0 32 32',
  path: [
    <path d="M18.6678 16.6279H5.33447" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M18.668 24.6279V8.62793" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M24.0015 24.6279V8.62793" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M12.0011 23.2943L5.33447 16.6276L12.0011 9.96094" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  ]
});

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  defaultProps: {
    boxSize: 6
  },
  viewBox: '0 0 32 32',
  path: [
    <path d="M13.3322 15.3721L26.6655 15.3721" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M13.332 7.37207L13.332 23.3721" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M7.99854 7.37207L7.99854 23.3721" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M19.9989 8.70573L26.6655 15.3724L19.9989 22.0391" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  ]
});

export const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    boxSize: 6
  },
  d: 'M6 5.70985C6 4.94502 6.82366 4.46331 7.49026 4.83827L25.7839 15.1284C26.4635 15.5107 26.4635 16.4893 25.7839 16.8716L7.49026 27.1617C6.82366 27.5367 6 27.055 6 26.2902V5.70985Z'
});

export const PauseIcon = createIcon({
  displayName: 'PauseIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    boxSize: 6
  },
  path: [
    <rect x="10" y="4" width="2" height="24" rx="1" fill="currentColor"/>,
    <rect x="20" y="4" width="2" height="24" rx="1" fill="currentColor"/>
  ]
})

export const VolumeIcon = createIcon({
  displayName: 'VolumeIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    boxSize: 6
  },
  path: <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M26.1337 5.86624C25.7432 5.47566 25.1101 5.47556 24.7195 5.86603C24.3289 6.25649 24.3288 6.88966 24.7193 7.28024C27.0314 9.59308 28.3303 12.7296 28.3303 15.9999C28.3303 19.2703 27.0314 22.4067 24.7193 24.7196C24.3288 25.1102 24.3289 25.7433 24.7195 26.1338C25.1101 26.5243 25.7432 26.5242 26.1337 26.1336C28.8208 23.4457 30.3303 19.8006 30.3303 15.9999C30.3303 12.1992 28.8208 8.55414 26.1337 5.86624ZM21.427 10.5729C21.0366 10.1823 20.4034 10.1822 20.0128 10.5727C19.6222 10.9632 19.6221 11.5963 20.0126 11.9869C21.0749 13.0496 21.6717 14.4906 21.6717 15.9932C21.6717 17.4958 21.0749 18.9369 20.0126 19.9996C19.6221 20.3902 19.6222 21.0233 20.0128 21.4138C20.4034 21.8043 21.0366 21.8042 21.427 21.4136C22.8643 19.9759 23.6717 18.0262 23.6717 15.9932C23.6717 13.9603 22.8643 12.0106 21.427 10.5729ZM15.6665 6.66626C15.6665 6.28185 15.4462 5.93148 15.0997 5.76496C14.7532 5.59844 14.342 5.64526 14.0418 5.88539L7.64906 10.9996H2.6665C2.11422 10.9996 1.6665 11.4473 1.6665 11.9996V19.9996C1.6665 20.5519 2.11422 20.9996 2.6665 20.9996H7.64906L14.0418 26.1138C14.342 26.3539 14.7532 26.4008 15.0997 26.2342C15.4462 26.0677 15.6665 25.7173 15.6665 25.3329V6.66626ZM8.62453 12.7805L13.6665 8.74689V23.2523L8.62453 19.2187C8.44722 19.0769 8.22691 18.9996 7.99984 18.9996H3.6665V12.9996H7.99984C8.22691 12.9996 8.44722 12.9223 8.62453 12.7805Z"
    fill="currentColor"
  />
});

export const MicrophoneOnIcon = createIcon({
  displayName: 'MicrophoneOnIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: 5
  },
  path: [
    <path d="M8 23H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M12 19V23" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1V1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>,
    <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
  ]
});

export const MicrophoneSpeakingIcon = createIcon({
  displayName: 'MicrophoneSpeakingIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: 5
  },
  path: [
    <path d="M8 23H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M12 19V23" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1V1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="currentColor"/>,
    <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
  ]
});

export const MicrophoneOffIcon = createIcon({
  displayName: 'MicrophoneOffIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 5,
    fill: 'none'
  },
  path: [
    <path d="M5.3335 15.3333H10.6668" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M10 6.22657V2.66657C10.0005 2.17058 9.81669 1.69211 9.48425 1.32403C9.15181 0.955952 8.69447 0.724526 8.201 0.674681C7.70753 0.624835 7.21314 0.760127 6.81381 1.05429C6.41448 1.34845 6.1387 1.7805 6.04 2.26657M6 5.9999V7.9999C6.00035 8.39521 6.11783 8.78154 6.33762 9.11012C6.55741 9.43869 6.86964 9.69477 7.23487 9.846C7.60011 9.99723 8.00197 10.0368 8.3897 9.95981C8.77743 9.88278 9.13363 9.69258 9.41333 9.41323L6 5.9999Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M11.3331 11.3001C10.6823 11.9644 9.84788 12.4191 8.93687 12.6058C8.02586 12.7926 7.07982 12.7029 6.22013 12.3483C5.36044 11.9936 4.62631 11.3903 4.11191 10.6155C3.59752 9.84078 3.32633 8.93002 3.33313 8.00008V6.66675M12.6665 6.66675V8.00008C12.6662 8.27504 12.6417 8.54944 12.5931 8.82008" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M8 12.6667V15.3334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M0.666992 0.666504L15.3337 15.3332" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
  ],
})

export const VideoOnIcon = createIcon({
  displayName: 'VideoOnIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: 5
  },
  path: [
    <path d="M22.5002 7.90918L15.8184 12.6819L22.5002 17.4546V7.90918Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>,
    <path d="M13.9091 6H3.40909C2.35473 6 1.5 6.85473 1.5 7.90909V17.4545C1.5 18.5089 2.35473 19.3636 3.40909 19.3636H13.9091C14.9635 19.3636 15.8182 18.5089 15.8182 17.4545V7.90909C15.8182 6.85473 14.9635 6 13.9091 6Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
  ]
});

export const VideoOffIcon = createIcon({
  displayName: 'VideoOffIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 5
  },
  path: [
    <path d="M7.1065 3.33325H9.33317C9.68679 3.33325 10.0259 3.47373 10.276 3.72378C10.526 3.97382 10.6665 4.31296 10.6665 4.66659V6.89325L11.3332 7.55992L15.3332 4.66659V11.3333M10.6665 10.6666V11.3333C10.6665 11.6869 10.526 12.026 10.276 12.2761C10.0259 12.5261 9.68679 12.6666 9.33317 12.6666H1.99984C1.64622 12.6666 1.30708 12.5261 1.05703 12.2761C0.80698 12.026 0.666504 11.6869 0.666504 11.3333V4.66659C0.666504 4.31296 0.80698 3.97382 1.05703 3.72378C1.30708 3.47373 1.64622 3.33325 1.99984 3.33325H3.33317L10.6665 10.6666Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>,
    <path d="M0.666504 0.666748L15.3332 15.3334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  ]
})

export const AudioIndicatorIcon = createIcon({
  displayName: 'AudioIndicatorIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 5
  },
  path: [
      <path fillRule="evenodd" clipRule="evenodd" d="M6 3C5.72386 3 5.5 3.22386 5.5 3.5V12.5C5.5 12.7761 5.72386 13 6 13C6.27614 13 6.5 12.7761 6.5 12.5V3.5C6.5 3.22386 6.27614 3 6 3ZM2.5 5C2.22386 5 2 5.22386 2 5.5V9.5C2 9.77614 2.22386 10 2.5 10C2.77614 10 3 9.77614 3 9.5V5.5C3 5.22386 2.77614 5 2.5 5ZM9 4.5C9 4.22386 9.22386 4 9.5 4C9.77614 4 10 4.22386 10 4.5V10.5C10 10.7761 9.77614 11 9.5 11C9.22386 11 9 10.7761 9 10.5V4.5ZM13 4C12.7239 4 12.5 4.22386 12.5 4.5V11.5C12.5 11.7761 12.7239 12 13 12C13.2761 12 13.5 11.7761 13.5 11.5V4.5C13.5 4.22386 13.2761 4 13 4Z" fill="currentColor"/>
  ]
})

export const FullScreenIcon = createIcon({
  displayName: 'FullScreenIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    boxSize: 6
  },
  path: [
    <path fillRule="evenodd" clipRule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6V12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12V6H12C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4H6ZM26 4C27.1046 4 28 4.89543 28 6V12C28 12.5523 27.5523 13 27 13C26.4477 13 26 12.5523 26 12V6L20 6C19.4477 6 19 5.55228 19 5C19 4.44772 19.4477 4 20 4H26ZM4 26C4 27.1046 4.89543 28 6 28H12C12.5523 28 13 27.5523 13 27C13 26.4477 12.5523 26 12 26H6V20C6 19.4477 5.55228 19 5 19C4.44772 19 4 19.4477 4 20V26ZM26 28C27.1046 28 28 27.1046 28 26V20C28 19.4477 27.5523 19 27 19C26.4477 19 26 19.4477 26 20V26H20C19.4477 26 19 26.4477 19 27C19 27.5523 19.4477 28 20 28H26Z" fill="currentColor"/>
  ]
});

export const ExitFullScreenIcon = createIcon({
  displayName: 'ExitFullScreenIcon',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: 6
  },
  path: [
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 5C5.5 5.27614 5.27614 5.5 5 5.5H2.5C2.22386 5.5 2 5.72386 2 6C2 6.27614 2.22386 6.5 2.5 6.5H5C5.82843 6.5 6.5 5.82843 6.5 5V2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5V5ZM10.5 5C10.5 5.27614 10.7239 5.5 11 5.5H13.5C13.7761 5.5 14 5.72386 14 6C14 6.27614 13.7761 6.5 13.5 6.5H11C10.1716 6.5 9.5 5.82843 9.5 5V2.5C9.5 2.22386 9.72386 2 10 2C10.2761 2 10.5 2.22386 10.5 2.5V5ZM5 10.5C5.27614 10.5 5.5 10.7239 5.5 11V13.5C5.5 13.7761 5.72386 14 6 14C6.27614 14 6.5 13.7761 6.5 13.5V11C6.5 10.1716 5.82843 9.5 5 9.5H2.5C2.22386 9.5 2 9.72386 2 10C2 10.2761 2.22386 10.5 2.5 10.5H5ZM10.5 11C10.5 10.7239 10.7239 10.5 11 10.5H13.5C13.7761 10.5 14 10.2761 14 10C14 9.72386 13.7761 9.5 13.5 9.5H11C10.1716 9.5 9.5 10.1716 9.5 11V13.5C9.5 13.7761 9.72386 14 10 14C10.2761 14 10.5 13.7761 10.5 13.5V11Z" fill="currentColor"/>
  ]
});

export const SubtitlesIcon = createIcon({
  displayName: 'SubtitlesIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    boxSize: 6
  },
  path: [
    <path fillRule="evenodd" clipRule="evenodd" d="M4 6H28V26H4V6ZM2 6C2 4.89543 2.89543 4 4 4H28C29.1046 4 30 4.89543 30 6V26C30 27.1046 29.1046 28 28 28H4C2.89543 28 2 27.1046 2 26V6ZM14.9956 11.939C14.1851 11.1127 13.087 10.6 11.8711 10.6C9.09674 10.6 7 12.741 7 15.9207C7 19.1313 8.99602 21.1395 11.7941 21.1395C13.1355 21.1395 14.2852 20.6208 15.1497 19.6185L15.3184 19.4231L13.7216 17.5206L13.488 17.7742C13.0318 18.2692 12.4845 18.5513 11.8839 18.5513C10.7014 18.5513 9.73134 17.5685 9.73134 15.8443C9.73134 14.2135 10.7937 13.1748 11.9094 13.1748C12.4396 13.1748 12.8649 13.3829 13.3642 13.841L13.5996 14.0575L15.1867 12.1331L14.9956 11.939ZM24.6766 11.939C23.8667 11.1127 22.7686 10.6 21.5522 10.6C18.7784 10.6 16.6816 12.741 16.6816 15.9207C16.6816 19.1313 18.6776 21.1395 21.4751 21.1395C22.8171 21.1395 23.9668 20.6208 24.8313 19.6185L25 19.4231L23.4026 17.5206L23.169 17.7742C22.7134 18.2692 22.1661 18.5513 21.5655 18.5513C20.3825 18.5513 19.4142 17.5685 19.4142 15.8443C19.4142 14.2135 20.4747 13.1748 21.591 13.1748C22.1206 13.1748 22.5465 13.3829 23.0452 13.841L23.2812 14.0575L24.8677 12.1331L24.6766 11.939Z" fill="currentColor"/>
  ]
})
