import * as React from "react";
import {ReactElement, useEffect, useRef, useState, VideoHTMLAttributes} from "react";
import {Box, chakra, Circle, HStack, Text} from "@chakra-ui/react";
import {AudioIndicatorIcon, MicrophoneOffIcon} from "../../icons";
import {useAudioLevelAnalyzer} from "./useAudioLevelAnalyzer";

export type LiveVideoProps = {
  name?: string
  videoTrack?: MediaStreamTrack
  audioTrack?: MediaStreamTrack
  videoEnabled?: boolean
  audioEnabled?: boolean
  mirrored?: boolean
  fallback?: ReactElement
} & VideoHTMLAttributes<HTMLVideoElement>;

const MutedCircle = () => <Box
  width={6}
  height={6}
  bg="red.500"
  zIndex={50}
  position="absolute"
  bottom={1}
  right={1}
  borderRadius="full"
  display="flex"
  alignItems="center"
  justifyContent="center"
>
  <MicrophoneOffIcon boxSize={4}/>
</Box>

const LiveVideo = (props: LiveVideoProps) => {

  const {
    name,
    videoTrack,
    audioTrack,
    videoEnabled,
    audioEnabled,
    fallback,
    ...rest
  } = props;

  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const audioIndicator = useAudioLevelAnalyzer(audioTrack);

  useEffect(() => {
    const player = videoPlayerRef.current!;
    if (videoTrack && videoEnabled) {
      const stream = new MediaStream();
      stream.addTrack(videoTrack);
      player.srcObject = stream;
    }
    return () => {
      if (player) {
        player.srcObject = null;
      }
    }
  }, [videoEnabled, videoTrack]);

  return (
    <Box width="full" height="full" position="relative">
      {videoEnabled ? (
        <chakra.video
          ref={videoPlayerRef}
          autoPlay
          transform={rest.mirrored ? 'scale(-1, 1)' : undefined}
          width="full"
        />
      ) : fallback}
      <Box position="absolute" top={0} right={0} p={1}>
        <Circle bgColor="purple.500" p={1} display={audioIndicator ? undefined : 'none'}>
          <AudioIndicatorIcon/>
        </Circle>
      </Box>
      <Box
        position="absolute"
        width="full"
        bottom="0"
        bgColor={name ? 'rgba(17, 17, 17, 0.3)' : undefined}
        p={1}
      >
        <HStack justifyContent="space-between" justifyItems="flex-end">
          {name && (
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {name}
            </Text>
          )}
          <Box marginLeft="auto">
            {!audioEnabled && (
              <MutedCircle/>
            )}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}

export default LiveVideo;
