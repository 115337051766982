import * as React from "react";
import {Button, Text, VStack} from "@chakra-ui/react";
import {useActivateTicket} from "../../useWewatchApi";

type ActivateTicketDialogProps = {
  movieId: string
  onSuccess: () => void
}

const ActivateTicketDialog = ({movieId, onSuccess}: ActivateTicketDialogProps) => {

  const {loading, post} = useActivateTicket(movieId);

  const handleClick = async () => {
    await post();
    onSuccess();
  }

  return (
    <VStack
      bgColor="gray.700"
      borderRadius="lg"
      padding={4}
      maxWidth={500}
      alignItems="start"
    >
      <Text fontSize="2xl">Activate Ticket</Text>
      <Text>
        Once activated, you will have 48 hours to finish watching this video, during which you will have unlimited access to friends and influencers.
      </Text>
      <Button
        colorScheme="cyan"
        onClick={handleClick}
        isLoading={loading}
        alignSelf="flex-end"
      >
        Activate
      </Button>
    </VStack>
  );
};

export default ActivateTicketDialog;
