import * as React from "react";
import {chakra, Hide, HStack, IconButton} from "@chakra-ui/react";
import {ArrowBackIcon} from "@chakra-ui/icons";
import ShowroomTitle from "./ShowroomTitle";
import RecordButton from "./RecordButton";
import WatchWithFriendsButton from "./WatchWithFriendsButton";
import {ArrowLeftIcon} from "../../icons";
import {
  addUserModalOpenState,
  menuVisibleState,
  movieDetailsState,
  reactionDetailsState,
  sidebarVisibleState,
  watchTogetherActivatedState
} from "../../room/roomState";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import AddReactionModal from "./AddReactionModal";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";
import {isValidMotionProp, motion} from "framer-motion";
import {useWeWatchController} from "../../room/WeWatchProvider";
import {useAuth0} from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import {useSpecialReactionLink} from "./useSpecialReactionLink";
import {Link} from "react-router-dom";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const TopBar = () => {

  const {isAuthenticated} = useAuth0();
  const hoverActivated = useRecoilValue(menuVisibleState);
  const weWatchController = useWeWatchController();
  const reactionDetails = useRecoilValue(reactionDetailsState)
  const movieDetails = useRecoilValue(movieDetailsState)
  const watchTogetherActivated = useRecoilValue(watchTogetherActivatedState);
  const [sidebarVisible, setSidebarVisible] = useRecoilState(sidebarVisibleState);
  const setAddUserModalOpen = useSetRecoilState(addUserModalOpenState);
  const specialReactionLink = useSpecialReactionLink();

  const mixpanelTracker = useShowroomMixpanelTracker();

  const isPreview = Boolean(movieDetails?.lengthMs);

  return (
    <ChakraBox
      width="full"
      animate={{opacity: hoverActivated ? 1 : 0}}
    >
      <HStack
        p={4}
        justifyContent="space-between"
        w="full"
        background="linear-gradient(180deg, #111111 0%, rgba(17, 17, 17, 0.8) 100%);"
      >
        <HStack>
          <IconButton
            as={Link}
            aria-label="Exit Showroom"
            icon={<ArrowBackIcon boxSize={6}/>}
            variant="ghost"
            to={specialReactionLink ?? `/movie/${weWatchController.movieId}`}
          />
          <ShowroomTitle
            title={movieDetails?.movieTitle}
            reactionTitle={reactionDetails?.reactionTitle}
            onReactionClick={() => {
              mixpanelTracker('Open Add Curation');
              setAddUserModalOpen(true);
            }}
          />
          <AddReactionModal/>
        </HStack>
        <Hide below="lg">
          {isAuthenticated ? (
            <HStack spacing={4}>
              {!isPreview && (
                <RecordButton/>
              )}
              {!watchTogetherActivated && (
                <WatchWithFriendsButton/>
              )}
              {!sidebarVisible && watchTogetherActivated && (
                <IconButton
                  aria-label="Expand Side-Panel"
                  icon={<ArrowLeftIcon/>}
                  variant="ghost"
                  onClick={() => setSidebarVisible(true)}
                />
              )}
            </HStack>
          ) : (
            <LoginButton/>
          )}
        </Hide>
      </HStack>
    </ChakraBox>
  );
};

export default TopBar;
