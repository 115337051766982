import * as React from "react";
import {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import http from "./http";
import mixpanel from "mixpanel-browser";
import {Provider} from 'use-http'
import LogRocket from "logrocket";

export const apiBaseUrl = process.env.REACT_APP_CONTROL_SERVER_URL;

const HttpInterceptorProvider = ({children}: { children: React.ReactNode }) => {

  const {getAccessTokenSilently, user} = useAuth0();

  useEffect(() => {
    const num = http.interceptors.request.use(async (config) => {
      if (config?.headers) {
        const token = await getAccessTokenSilently();
        config.headers['Authorization'] = `Bearer ${token!}`;
        return config;
      }
    });

    return () => {
      http.interceptors.request.eject(num);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.sub);
      mixpanel.people.set({
        "$email": user.email,
        "name": user.name
      });
      LogRocket.identify(user.sub!, {
        name: user.name || '',
        email: user.email || ''
      });
    }
  }, [user]);

  return <Provider
    url={apiBaseUrl}
    options={{
      interceptors: {
        request: async ({options}) => {
          const token = await getAccessTokenSilently();
          const headers = options.headers as Record<string, string>;
          headers['Authorization'] = `Bearer ${token}`;
          return options;
        }
      }
    }}
  >
    {children}
  </Provider>;
}

export default HttpInterceptorProvider;
