import * as React from "react";
import {useRecoilValue} from "recoil";
import {videoPlaybackState} from "../../room/roomState";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";
import {useHotkeys} from "react-hotkeys-hook";
import {PauseIcon, PlayIcon} from "../../icons";
import {IconButton} from "@chakra-ui/react";
import {useWeWatchController} from "../../room/WeWatchProvider";

const PlayPauseButton = () => {
  const playbackController = useWeWatchController().playbackController;
  const playing = useRecoilValue(videoPlaybackState) === 'playing';

  const mixpanelTracker = useShowroomMixpanelTracker();

  const togglePlay = () => {
    if (playing) {
      playbackController?.pause();
      mixpanelTracker('Pause Content');
    } else {
      playbackController?.play();
      mixpanelTracker('Play Content');
    }
  };

  useHotkeys('space', togglePlay, [playbackController, playing]);

  return (
    <IconButton
      onClick={togglePlay}
      aria-label="Play"
      icon={playing ? <PauseIcon/> : <PlayIcon/>}
      variant="ghost"
    />
  );
};

export default PlayPauseButton;
