import * as React from "react";
import {Box, HStack} from "@chakra-ui/react";
import DeviceButton from "./DeviceButton";
import {MicrophoneOffIcon, MicrophoneOnIcon, MicrophoneSpeakingIcon, VideoOffIcon, VideoOnIcon} from "../../icons";
import {useAuth0} from "@auth0/auth0-react";
import {useRecoilValue} from "recoil";
import {menuVisibleState, selfDeviceState} from "../../room/roomState";
import MemberVideo from "./MemberVideo";
import {useAudioLevelAnalyzer} from "./useAudioLevelAnalyzer";

const SelfVideoBox = () => {

  const {user} = useAuth0();

  const webcam = useRecoilValue(selfDeviceState('video'));
  const microphone = useRecoilValue(selfDeviceState('audio'));

  const hoverActivated = useRecoilValue(menuVisibleState);

  const audioIndicator = useAudioLevelAnalyzer(microphone.track);

  return (
    <Box backgroundColor="gray.800" borderRadius={10}>
        <MemberVideo
          name={hoverActivated ? user?.name : undefined}
          // name={user?.name}
          videoTrack={webcam.track}
          // audioTrack={microphone.track}
          videoEnabled={webcam.status === 'connected'}
          audioEnabled={microphone.status === 'connected'}
          mirrored
        />
      <Box width="full">
        <HStack p={2} w="full" justifyContent="end" spacing={4}>
          <DeviceButton
            kind="audio"
            enabledIcon={audioIndicator ? <MicrophoneSpeakingIcon color="rgba(166, 148, 255, 1)"/> : <MicrophoneOnIcon/>}
            disabledIcon={<MicrophoneOffIcon/>}
          />
          <DeviceButton
            kind="video"
            enabledIcon={<VideoOnIcon/>}
            disabledIcon={<VideoOffIcon/>}
          />
        </HStack>
      </Box>
    </Box>
  );
};

export default SelfVideoBox;
