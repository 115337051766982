import axios from 'axios';

export const apiBaseUrl = process.env.REACT_APP_CONTROL_SERVER_URL;

const http = axios.create({
  baseURL: apiBaseUrl
});
export default http;

type JoinShowroomHttpResponse = {
  movieId: string
  mediaServerUrl?: string
  error: false
} | {
  error: true
}

export const joinShowroom = async (roomId: string): Promise<JoinShowroomHttpResponse> => {
  try {
    const resp = await http.post('/showroom/joinShowroom', {
      showroomId: roomId
    });
    const data = resp.data;
    if (data['mediaServerState'] === 'running' && 'mediaServerState' in  data && data['mediaServerState'] !== '') {
      return {
        movieId: data['movieId'],
        mediaServerUrl:'https://' + data['mediaServerHost'],
        error: false
      }
    } else if (data['success']) {
      return {
        movieId: data['movieId'],
        error: false
      }
    } else {
      console.error(`Unexpected error joining showroom ${roomId}: ${JSON.stringify(data)}`);
      return {
        error: true
      }
    }

  } catch {
    console.error(`Unexpected error joining showroom ${roomId}`);
    return {
      error: true
    }
  }
}

export const startShowroom = async (movieId: string): Promise<string> => {
  if (process.env.REACT_APP_MEDIA_SERVER_OVERRIDE) {
    return movieId;
  }

  const resp = await http.post('/showroom/createShowroom', {
    movieId
  });
  return resp.data.showroomId;
}
