import * as React from "react";
import {Avatar, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, Text} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

const PageHeader = () => {

  const navigate = useNavigate();
  const {user, logout} = useAuth0();

  return (
    <HStack
      position="absolute"
      top={0}
      width="100%"
      background="linear-gradient(180deg, #111111 0%, rgba(17, 17, 17, 0) 100%)"
      paddingX={2}
      paddingY={4}
    >
      <Link to="/">
        <img alt="WeWatch Logo" src="/logo.png" style={{height: 36}}/>
      </Link>
      <Link to="/">
        <Text fontSize="3xl">
          WeWatch
        </Text>
      </Link>
      <Spacer/>
      {user && (
        <Menu>
          <MenuButton>
            <Avatar name={user.name} src={user.picture} size="sm"/>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate('/me/purchases')}>My Purchases</MenuItem>
            <MenuItem onClick={() => navigate('/me/recordings')}>My Curations</MenuItem>
            <MenuItem onClick={() => navigate('/me/settings')}>Settings</MenuItem>
            <MenuDivider/>
            <MenuItem onClick={() => logout({returnTo: window.location.origin})}>Log Out</MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default PageHeader;
