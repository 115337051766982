import * as React from "react";
import DropdownSelect from "../../components/DropdownSelect";
import {useDevices} from "../../mediaDevices";

interface Dictionary {
  [index: string]: string
}

const toOptions = (devices: InputDeviceInfo[]): Dictionary => {
  const result: Dictionary = {};

  devices.forEach(d => result[d.deviceId] = d.label);

  return result;
}

type DeviceDropdownProps = {
  kind: 'audio' | 'video'
  value?: string
  onChange: (val: string | undefined) => void
  leftIcon?: React.ReactElement
}

const DeviceDropdown = ({kind, value, onChange, leftIcon}: DeviceDropdownProps) => {

  const devices = useDevices(kind);

  return (
    <DropdownSelect
      value={value}
      onChange={onChange}
      options={toOptions(devices)}
      leftIcon={leftIcon}
      includeUndefined={true}
    />
  );
};

export default DeviceDropdown;
