import {VideoProvider} from "./VideoProvider";
import Hls from "hls.js";

export class HlsVideoProvider implements VideoProvider {

  private readonly playlistUrl: string;
  private hls?: Hls;
  private _actualDuration?: number;

  constructor(playlistUrl: string, onDuration?: (dur: number) => void) {
    this.playlistUrl = playlistUrl;
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.on(Hls.Events.LEVEL_LOADED, (evt, data) => {
        this._actualDuration = data.details.totalduration;
        if (onDuration) {
          onDuration(data.details.totalduration);
        }
      });
      this.hls.loadSource(this.playlistUrl);
    } else if (document.createElement('video').canPlayType('application/vnd.apple.mpegurl')) {
      const element = document.createElement('video');
      element.preload = 'metadata';
      element.ondurationchange = (ev) => {
        console.log('Calculated duration length ' + element.duration);
        this._actualDuration = element.duration;
        if (onDuration) {
          onDuration(element.duration);
        }
      }
      element.src = playlistUrl;
    } else {
      throw new Error('Cannot play hls video');
    }
  }

  attach(element: HTMLVideoElement): void {
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.on(Hls.Events.ERROR, (evt, data) => {
        if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
          element.pause();
        }
      });
      this.hls.loadSource(this.playlistUrl);
      const url = new URL(this.playlistUrl);
      if (url.hash && url.hash.includes('#t=')) {
        const startAt = parseFloat(url.hash.substring(url.hash.indexOf('#t=') + 3));
        this.hls.startLoad(startAt);
      }
      this.hls.attachMedia(element);
    } else if (element.canPlayType('application/vnd.apple.mpegurl')) {
      element.src = this.playlistUrl;
      element.load();
    } else {
      throw new Error('Cannot play hls video');
    }
  }

  get actualDuration(): number | undefined {
    return this._actualDuration;
  }

  detach(): void {
    this.hls?.destroy();
  }
}
