import * as React from "react";

export const between = (v: number, min: number, max: number) => {
  if (v < min) {
    return min;
  }
  if (v > max) {
    return max;
  }
  return v;
}

const isTouchEvent = (e: Event | React.SyntheticEvent): e is (React.TouchEvent | TouchEvent) => {
  return e.type.startsWith('touch');
}

const isMouseEvent = (e: Event | React.SyntheticEvent): e is (React.MouseEvent | MouseEvent) => {
  return e.type.startsWith('mouse');
}

export type MousePosition = {clientX: number, clientY: number};

export const extractPosition = (e: Event | React.SyntheticEvent): MousePosition => {
  if (isTouchEvent(e)) {
    return {
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY
    };
  } else if (isMouseEvent(e)) {
    return {
      clientX: e.clientX,
      clientY: e.clientY
    }
  } else {
    throw new Error('Invalid event ' + e);
  }
}
