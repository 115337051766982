import {HlsVideoProvider} from "../components/HlsVideoProvider";
import {ClipId} from "./PlaybackPlaylist";
import {AutoplayPlaybackManager} from "./AutoplayPlaybackManager";
import {ReactionPlaybackController} from "./ReactionPlaybackController";

export class SegmentPlayer {

  public readonly id: ClipId;
  private readonly _autoplayManager: AutoplayPlaybackManager;
  private _player?: { element: HTMLVideoElement, src?: string };
  private _hlsVideoProviderCache = new Map<string, HlsVideoProvider>();
  private _desiredState?: { paused: boolean, timestamp: number, src: string };
  private _reactionController?: ReactionPlaybackController;
  private readonly _onUpdate: () => void;

  constructor(id: ClipId, autoplayManager: AutoplayPlaybackManager) {
    this.id = id;
    this._autoplayManager = autoplayManager;
    this._onUpdate = async () => {
      if (!this._player || !this._player.src || !this._reactionController) {
        return;
      }
      const actualDuration = this._hlsVideoProviderCache.get(this._player.src)?.actualDuration;
      const endOfClip = actualDuration !== undefined && this._player.element.currentTime >= actualDuration;
      await this._reactionController.onUpdate(this.id, this._player.src, this._player.element.currentTime, this._player.element.paused, endOfClip);
    };
  }

  async play(src: string, timestamp?: number) {
    await this.update(src, false, timestamp);
  }

  async pause(src: string, timestamp?: number) {
    await this.update(src, true, timestamp);
  }

  async update(src: string, paused: boolean, timestamp?: number) {
    if (this._player) {
      const currentSrc = this._player.src;
      if (currentSrc !== src) {
        if (currentSrc) {
          this._hlsVideoProviderCache.get(currentSrc)!.detach();
        }
        const provider = this.getVideoProvider(src);
        this._player.src = src;
        provider.attach(this._player.element);
      }
      this._player.element.style.display = '';
      if (paused) {
        this._autoplayManager.pause(this._player.element, timestamp);
      } else {
        await this._autoplayManager.play(this._player.element, timestamp);
      }
    } else {
      this._desiredState = {
        paused: paused,
        timestamp: timestamp ?? 0,
        src: src
      }
    }
  }

  unload() {
    if (this._player) {
      this._player.element.style.display = 'none';
      this._player.element.pause();
    }
    this._desiredState = undefined;
  }

  get paused() {
    if (this._player) {
      return this._player.element.paused;
    } else {
      return this._desiredState?.paused ?? true;
    }
  }

  get currentTime(): number {
    if (this._player) {
      return this._player.element.currentTime;
    } else {
      return this._desiredState?.timestamp ?? 0;
    }
  }

  async attach(element: HTMLVideoElement) {
    if (this._player) {
      this.detach();
    }
    this._player = {
      element: element
    };
    element.style.display = 'none';
    element.addEventListener('timeupdate', this._onUpdate);
    element.addEventListener('play', this._onUpdate);
    element.addEventListener('pause', this._onUpdate);
    this._autoplayManager.add(element);
    if (this._desiredState) {
      await this.update(this._desiredState.src, this._desiredState.paused, this._desiredState.timestamp);
    }
  }

  detach() {
    if (!this._player) {
      return;
    }
    const currentSrc = this._player.src;
    if (currentSrc) {
      this._hlsVideoProviderCache.get(currentSrc)!.detach();
    }
    this._player.element.removeEventListener('timeupdate', this._onUpdate);
    this._player.element.removeEventListener('play', this._onUpdate);
    this._player.element.removeEventListener('pause', this._onUpdate);
    this._autoplayManager.remove(this._player.element);
    this._player = undefined;
  }

  private getVideoProvider(src: string) {
    if (!this._hlsVideoProviderCache.has(src)) {
      this._hlsVideoProviderCache.set(src, new HlsVideoProvider(src, () => {}));
    }
    return this._hlsVideoProviderCache.get(src) as HlsVideoProvider;
  }

  registerController(reactionController?: ReactionPlaybackController) {
    this._reactionController = reactionController;
    this.unload();
  }
}
