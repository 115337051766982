import {useEffect, useState} from "react";

export const useAudioLevelAnalyzer = (audioTrack: MediaStreamTrack | undefined): boolean => {

  const [audioIndicator, setAudioIndicator] = useState<boolean>(false);

  useEffect(() => {
    if (audioTrack) {
      const context = new AudioContext();
      const analyserNode = context.createAnalyser();
      analyserNode.fftSize = 32;
      analyserNode.minDecibels = -127;
      analyserNode.maxDecibels = 0;
      analyserNode.smoothingTimeConstant = 0.6;
      const bufferLength = analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        analyserNode.getByteFrequencyData(dataArray);
        let volumeSum = 0;
        for (const data of dataArray) {
          volumeSum += data;
        }
        const averageVolume = volumeSum / dataArray.length;
        const value = averageVolume * 100 / 127;
        setAudioIndicator(value > 50);
      }

      const timeout = setInterval(draw, 100);

      const stream = new MediaStream();
      stream.addTrack(audioTrack);
      const source = context.createMediaStreamSource(stream);
      source.connect(analyserNode);

      return () => {
        setAudioIndicator(false);
        clearInterval(timeout);
        context.close()
          .catch((e) => console.error(e));
      }
    }
  }, [audioTrack]);

  return audioIndicator;

}
