import * as React from "react";
import {useEffect} from "react";
import MovieCard from "./MovieCard";
import {useNavigate} from "react-router";
import mixpanel from "mixpanel-browser";
import {SimpleGrid} from "@chakra-ui/react";
import FixedPageHeader from "../../components/FixedPageHeader";
import {useListMovies} from "../../useWewatchApi";
import FullscreenLoader from "../../components/FullscreenLoader";

const HomePage = () => {

  const navigate = useNavigate();
  const {loading, data: movies} = useListMovies();

  useEffect(() => {
    mixpanel.track('Browse Movies');
  }, []);

  const goToDetails = async (movieId: number) => {
    navigate(`/movie/${movieId}`)
  };

  if (loading) {
    return <FullscreenLoader title="Loading&hellip;"/>
  }

  return (
    <>
      <FixedPageHeader/>
      <SimpleGrid spacing={8} minChildWidth='320px' padding={4}>
        {movies?.map(movie => (
          <MovieCard
            key={movie.id}
            posterUrl={`${process.env.REACT_APP_MEDIA_ASSET_URL}/${movie.s3PosterKey}`}
            title={movie.title}
            language={movie.language}
            year={movie.year}
            length={movie.lengthMs}
            free={movie.free}
            onClick={() => goToDetails(movie.id)}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default HomePage;
