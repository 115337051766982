import {ShowroomClient} from "../ShowroomClient";
import {Transport} from "mediasoup-client/lib/Transport";
import {Device} from "mediasoup-client";
import {Producer} from "mediasoup-client/lib/Producer";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";

export class ProducerTransport {

  private readonly transport: Transport;

  private constructor(transport: Transport) {
    this.transport = transport;
  }

  async produce(track: MediaStreamTrack): Promise<Producer> {
    return await this.transport.produce({track});
  }

  get closed() {
    return this.transport.closed;
  }

  close() {
    this.transport.close();
  }

  static async create(showroomClient: ShowroomClient, device: Device): Promise<ProducerTransport> {
    const producerTransportData = await showroomClient.socket.request('createProducerTransport');
    const transport = device.createSendTransport(producerTransportData);

    transport.on('connect', async ({dtlsParameters}, callback, errback) => {
      showroomClient.socket.request('connectProducerTransport', {dtlsParameters})
        .then(callback)
        .catch(errback);
    });

    transport.on('produce', async ({kind, rtpParameters}, callback, errback) => {
      try {
        const {id} = await showroomClient.socket.request('produce', {
          kind,
          rtpParameters,
        });
        callback({id});
      } catch (err: any) {
        errback(err);
      }
    });

    transport.on('connectionstatechange', (state) => {
      console.log('ProducerTransport State: ' + state);
      if (state === 'connected') {
        // TODO: event?
      }
    });

    transport.observer.on('newproducer', (producer: Producer) => {
      const kind = producer.kind as MediaKind;

      producer.observer.on('pause', () => {
        showroomClient.socket.send('pauseProducer', {kind});
      });

      producer.observer.on('resume', () => {
        showroomClient.socket.send('resumeProducer', {kind});
      });

      producer.observer.on('close', () => {
        showroomClient.socket.send('closeProducer', {kind});
      });
    });

    return new ProducerTransport(transport);
  }
}
