import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ChakraProvider} from "@chakra-ui/react"
import {BrowserRouter as Router} from 'react-router-dom';
import {RecoilRoot} from "recoil";
import {theme} from "./Theme";
import Auth0ProviderWithHistory from "./Auth0ProviderWithHistory";
import HttpInterceptorProvider from "./HttpInterceptorProvider";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";

mixpanel.init("3698176ea80680b566cd3bb963827524", {
  ignore_dnt: true,
  api_host: 'https://d15pkaw8chgrcx.cloudfront.net'
});

LogRocket.init((!process.env.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN?.indexOf('dev') >= 0) ? 'nmihi0/wewatch-dev' : 'nmihi0/wewatch-prod');

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ChakraProvider theme={theme}>
        <Auth0ProviderWithHistory>
          <RecoilRoot>
            <HttpInterceptorProvider>
              <App/>
            </HttpInterceptorProvider>
          </RecoilRoot>
        </Auth0ProviderWithHistory>
      </ChakraProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
