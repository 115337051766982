import {ReactionPart, ReactionPartClip} from "../useWewatchApi";
import {PlayerRegistry} from "./PlayerRegistry";

export type ClipId = 'movie' | string;

export type PlaybackSegment = {
  startTimeSec: number
  movieState: 'play' | 'pause'
  lengthSec: number
  primaryPlayer: ClipId
  reactions: {
    [key: ClipId]: {
      s3HlsPlaylistKey: string
      clipTimeSec: number
    }
  }
}

export type ReactionMember = {
  id: string
  name: string
}

export class PlaybackPlaylist {
  public readonly segments: PlaybackSegment[];
  public readonly members: ReactionMember[];

  constructor(segments: PlaybackSegment[], members: ReactionMember[]) {
    if (segments.length === 0) {
      throw new Error('Must be at least one segment');
    }
    this.segments = segments;
    this.members = members;
  }

  get lastSegment(): PlaybackSegment {
    return this.segments[this.segments.length - 1];
  }

  get durationSec(): number {
    return this.lastSegment.startTimeSec + this.lastSegment.lengthSec;
  }

  timestampToMovieTime(time: number): number {
    const segment = this.getSegmentForTimestamp(time);
    const movieReaction = segment.reactions['movie'];
    if (segment.movieState === 'pause') {
      return movieReaction.clipTimeSec;
    } else {
      return movieReaction.clipTimeSec + (time - segment.startTimeSec);
    }
  }

  getSegmentForTimestamp(time: number): PlaybackSegment {
    const reversed = [...this.segments].reverse();
    return reversed.find(s => s.startTimeSec <= time) ?? this.lastSegment;
  }

  static fromMovieOnly(movieSrc: string, movieLengthMs: number): PlaybackPlaylist {
    return new PlaybackPlaylist(
      [
        {
          startTimeSec: 0,
          movieState: 'play',
          primaryPlayer: 'movie',
          lengthSec: movieLengthMs / 1000,
          reactions: {
            movie: {
              s3HlsPlaylistKey: movieSrc,
              clipTimeSec: 0
            }
          }
        }
      ],
      []
    )
  }

  static fromReaction(movieSrc: string, movieLengthMs: number, parts: ReactionPart[]) {
    if (parts.length === 0) {
      return this.fromMovieOnly(movieSrc, movieLengthMs);
    }

    const segments: PlaybackSegment[] = [];

    const segmentTimes = Array.from(new Set([
      0,
      ...parts.flatMap(p => p.clips).flatMap(clip => [clip.reactionTimeMs, clip.reactionTimeMs + clip.lengthMs])
    ])).sort((a, b) => a - b);

    console.log(segmentTimes);

    for (let i = 0; i < segmentTimes.length - 1; i++) {
      const startMs = segmentTimes[i];
      const endMs = segmentTimes[i + 1];
      const lengthMs = endMs - startMs;

      const lastSegment = segments[i - 1] as PlaybackSegment | undefined;
      const lastEndMovieTimeSec = (lastSegment?.reactions?.['movie']?.clipTimeSec ?? 0) + (lastSegment?.movieState === 'play' ? lastSegment.lengthSec : 0);

      const clips: { userId: string; clip: ReactionPartClip }[] = parts.map(p => ({
        userId: p.userId,
        clip: p.clips.find(c => c.reactionTimeMs <= startMs && c.reactionTimeMs + c.lengthMs >= endMs)
      })).filter(d => d.clip) as { userId: string; clip: ReactionPartClip }[];

      if (clips.length === 0) {
        segments[i] = {
          startTimeSec: startMs / 1000.0,
          primaryPlayer: 'movie',
          movieState: 'play',
          lengthSec: lengthMs / 1000.0,
          reactions: {
            movie: {
              s3HlsPlaylistKey: movieSrc,
              clipTimeSec: lastEndMovieTimeSec
            }
          }
        }
      } else {
        const clip = clips[0];
        segments[i] = {
          startTimeSec: startMs / 1000.0,
          primaryPlayer: clip.clip.movieState === 'PLAY' ? 'movie' : clip.userId,
          movieState: clip.clip.movieState === 'PLAY' ? 'play' : 'pause',
          lengthSec: lengthMs / 1000.0,
          reactions: {
            movie: {
              s3HlsPlaylistKey: movieSrc,
              clipTimeSec: (clip.clip.movieTimeMs + (startMs - clip.clip.reactionTimeMs)) / 1000.0
            },
            ...Object.fromEntries(clips.map(c => {
              const s3HlsPlaylistKey = `${process.env.REACT_APP_REACTIONS_ASSETS_URL}/${c.clip.s3HlsPlaylistKey}`;
              const lastReaction = lastSegment?.reactions?.[c.userId];
              return [c.userId, {
                s3HlsPlaylistKey: s3HlsPlaylistKey,
                clipTimeSec: lastReaction?.s3HlsPlaylistKey === s3HlsPlaylistKey ? lastReaction.clipTimeSec + lastSegment!.lengthSec : 0
              }];
            }))
          }
        }
      }
    }

    // const lastSegment = segments[segmentTimes.length - 2];
    // const lastSegmentMovieEndSec = lastSegment.reactions['movie'].clipTimeSec + (lastSegment.movieState === 'play' ? lastSegment.lengthSec : 0);
    // const remainingMovieSec = (movieLengthMs / 1000) - lastSegmentMovieEndSec;
    // if (remainingMovieSec > 0) {
    //   segments.push({
    //     startTimeSec: lastSegment.startTimeSec + lastSegment.lengthSec,
    //     primaryPlayer: 'movie',
    //     movieState: 'play',
    //     lengthSec: remainingMovieSec,
    //     reactions: {
    //       movie: {
    //         s3HlsPlaylistKey: movieSrc,
    //         clipTimeSec: lastSegmentMovieEndSec
    //       }
    //     }
    //   })
    // }

    const members = parts.map(p => ({
      id: p.userId,
      name: p.name
    }));

    return new PlaybackPlaylist(segments, members);
  }
}
