import * as React from "react";
import {VStack} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";
import {useRecoilValue} from "recoil";
import {membersState, memberState, menuVisibleState, selfDeviceState} from "../../room/roomState";
import MemberVideo from "./MemberVideo";

const LocalMemberVideo = () => {

  const {user} = useAuth0();

  const webcam = useRecoilValue(selfDeviceState('video'));
  const microphone = useRecoilValue(selfDeviceState('audio'));

  const hoverActivated = useRecoilValue(menuVisibleState);

  return <MemberVideo
    name={hoverActivated ? user?.name : undefined}
    // name={user?.name}
    videoTrack={webcam.track}
    audioTrack={microphone.track}
    videoEnabled={webcam.status === 'connected'}
    audioEnabled={microphone.status === 'connected'}
    mirrored
  />;
}

const RemoteMemberVideo = ({memberId}: { memberId: string }) => {
  const member = useRecoilValue(memberState(memberId));
  const hoverActivated = useRecoilValue(menuVisibleState);

  return <MemberVideo
    name={hoverActivated ? member.name : undefined}
    // name={member.name}
    videoTrack={member.videoTrack}
    videoEnabled={member.videoEnabled}
    audioEnabled={member.audioEnabled}
  />
}


const MemberSidebar = () => {

  const members = useRecoilValue(membersState);

  return (
    <VStack>
      <LocalMemberVideo/>
      {members.map(memberId => (
        <RemoteMemberVideo key={memberId} memberId={memberId}/>
      ))}
    </VStack>
  );
};

export default MemberSidebar;
