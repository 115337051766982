export class EchoCancellationLoopback {

  private readonly stream = new MediaStream();
  private readonly audioContext = new AudioContext();
  private readonly destination;
  private readonly rtcConnection = new RTCPeerConnection();
  private readonly rtcLoopbackConnection = new RTCPeerConnection();
  private readonly sources = new Map<HTMLMediaElement, MediaElementAudioSourceNode>();
  private readonly htmlAudioElement: HTMLAudioElement;

  constructor() {
    this.rtcConnection.onicecandidate = e =>
      e.candidate && this.rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
    this.rtcLoopbackConnection.onicecandidate = e =>
      e.candidate && this.rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

    this.rtcLoopbackConnection.ontrack = e => {
      console.log('ontrack: ' + e);
      this.stream.addTrack(e.track);
    };

    this.destination = new MediaStreamAudioDestinationNode(this.audioContext);
    this.rtcConnection.addTrack(this.destination.stream.getAudioTracks()[0]);

    console.log('Creating audio output element');
    this.htmlAudioElement = document.createElement('audio');
    document.getElementsByTagName('body')[0].append(this.htmlAudioElement);
    this.htmlAudioElement.srcObject = this.stream;
    this.htmlAudioElement.play()
      .catch(() => {
        console.warn('Cannot start audio element -- but that\'s okay, we\'ll try again soon');
      });

    this.start();
  }

  private async start() {
    const offer = await this.rtcConnection.createOffer({
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    });
    await this.rtcConnection.setLocalDescription(offer);

    await this.rtcLoopbackConnection.setRemoteDescription(offer);
    const answer = await this.rtcLoopbackConnection.createAnswer();
    await this.rtcLoopbackConnection.setLocalDescription(answer);

    await this.rtcConnection.setRemoteDescription(answer);
    console.log('Loopback ready');
  }

  addSource(element: HTMLMediaElement): AudioNode {
    console.log('Adding source', element);
    // this.removeSource(element);
    const source = new MediaElementAudioSourceNode(this.audioContext, {
      mediaElement: element
    });

    source.connect(this.destination);
    this.sources.set(element, source);

    return source;
  }

  removeSource(element: HTMLMediaElement) {
    console.log('Removing source', element);
    const source = this.sources.get(element);
    if (source) {
      source.disconnect();
      this.sources.delete(element);
    } else {
      console.log(`Didn't find ${element}, that's weird`);
    }
  }

  async resume() {
    if (this.audioContext.state !== 'running') {
      await this.audioContext.resume();
    }
    if (this.htmlAudioElement.paused) {
      this.htmlAudioElement.play();
    }
  }
}
