import * as React from "react";
import ReactionCard from "../../components/ReactionCard";
import {Center, SimpleGrid, Spinner, Text} from "@chakra-ui/react";
import {selectedReactionIdState} from "../../room/roomState";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";
import {useWeWatchController} from "../../room/WeWatchProvider";
import {useRecoilValue} from "recoil";
import { useGetReactions } from "../../useWewatchApi";

const RecordingList = () => {

  const weWatchController = useWeWatchController();
  const selectedReactionId = useRecoilValue(selectedReactionIdState);
  const {loading, data} = useGetReactions(weWatchController.movieId);
  const mixpanelTracker = useShowroomMixpanelTracker();

  if (loading) {
    return <Center>
      <Spinner/>
    </Center>;
  }

  if (!data?.reactions.length) {
    return <Center mb={8}>
      <Text fontSize="xl">
        More curations to come&hellip;
      </Text>
    </Center>
  }

  return (
    <SimpleGrid columns={3} spacing={4}>
      {data?.reactions.map(reaction => (
        <ReactionCard
          thumbnail={reaction.reactionThumbnail}
          key={reaction.id}
          names={reaction.individuals}
          selected={reaction.id === selectedReactionId}
          onSelect={async () => {
            if (reaction.id === selectedReactionId) {
              mixpanelTracker('Deselect Reaction', {reactionId: reaction.id});
            } else {
              mixpanelTracker('Select Reaction', {reactionId: reaction.id});
            }
            const newReaction = reaction.id === selectedReactionId ? undefined : reaction.id;
            weWatchController.selectReaction(newReaction);
          }}
        />
      ))}
    </SimpleGrid>
  );
};

export default RecordingList;
