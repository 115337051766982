import {useSetRecoilState} from "recoil";
import {hoverActivatedState} from "../../room/roomState";
import useEventListener from "@use-it/event-listener";
import {useEffect, useRef} from "react";

const TIMEOUT = 2500;

const useHoverState = () => {
  const setHoverActivated = useSetRecoilState(hoverActivatedState);
  const lastMouseMoveRef = useRef<number>(0);

  useEventListener('mousemove', () => {
    lastMouseMoveRef.current = new Date().getTime();
    setHoverActivated(true);
  });

  useEventListener('touchstart', () => {
    lastMouseMoveRef.current = new Date().getTime();
    setHoverActivated(true);
  });

  useEventListener('touchmove', () => {
    lastMouseMoveRef.current = new Date().getTime();
    setHoverActivated(true);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (lastMouseMoveRef.current < new Date().getTime() - TIMEOUT) {
        setHoverActivated(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [setHoverActivated]);
};

export default useHoverState;
