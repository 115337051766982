import * as React from "react";
import {useEffect, useState} from "react";
import {Flex} from "@chakra-ui/react";
import {useRecoilValue} from "recoil";
import {movieDetailsState} from "../../room/roomState";
import ContinueWatchingContent from "./ContinueWatchingContent";
import {useWeWatchContext, useWeWatchController} from "../../room/WeWatchProvider";
import {MoviePlayerListener} from "../../room/MoviePlayerListener";

const ContinueWatchingPopup = ({forceReload}: { forceReload: () => void }) => {
  const movieDetails = useRecoilValue(movieDetailsState);
  const duration = useWeWatchContext()?.reactionController?.playbackPlaylist?.durationSec ?? 0;

  const [currentTime, setCurrentTime] = useState<number>(0);

  const weWatchController = useWeWatchController();

  useEffect(() => {
    const playbackController = weWatchController.playbackController;

    const listener: MoviePlayerListener = {
      onUpdate: (paused, currentTime) => {
        setCurrentTime(currentTime);
      }
    };

    playbackController.registerListener(listener);

    return () => {
      playbackController.removeListener(listener);
    }
  }, [weWatchController]);

  if (!movieDetails?.lengthMs || currentTime < duration - 5) {
    // not a preview -- hind of a hack
    return <></>;
  }

  return (
    <Flex
      position="absolute"
      top={0}
      bottom={0}
      right={0}
      left={0}
      bgColor="rgba(36, 36, 36, 0.9)"
      backdropFilter="auto"
      backdropBlur="4px"
      zIndex={50}
      overflowY="scroll"
      alignItems="center"
      justifyContent="center"
    >
      <ContinueWatchingContent forceReload={forceReload}/>
    </Flex>
  );
};

export default ContinueWatchingPopup;
