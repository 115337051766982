import * as React from "react";
import {useEffect} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {membersState, sidebarVisibleState, watchTogetherActivatedState} from "../../room/roomState";
import {Modal, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import SelectDevicePopup from "./SelectDevicePopup";

const AutoJoinExistingMembers = () => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const setSidebarVisible = useSetRecoilState(sidebarVisibleState);
  const members = useRecoilValue(membersState);
  const [watchTogetherActivated, setWatchTogetherActivated] = useRecoilState(watchTogetherActivatedState);

  useEffect(() => {
    if (members.length > 0 && !watchTogetherActivated) {
      setWatchTogetherActivated(true);
      setSidebarVisible(true);
      onOpen();
    }
  }, [members, setWatchTogetherActivated, watchTogetherActivated, onOpen, setSidebarVisible]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay/>
      <ModalContent p={2}>
        <SelectDevicePopup
          onSubmit={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

export default AutoJoinExistingMembers;
