import * as React from "react";
import {useEffect, useRef} from "react";
import styled from "styled-components";
import {chatVolumeState} from "../../room/roomState";
import {useRecoilValue} from "recoil";
import {ReactComponent as ProfileFull} from "../../icons/profile-full.svg";
import {AspectRatio, Box, chakra} from "@chakra-ui/react";
import {useWeWatchController} from "../../room/WeWatchProvider";

const Name = styled.div`
  position: absolute;
  z-index: 40;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 20px;
  background: rgba(17, 17, 17, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type ReactionVideoProps = {
  name: string
  userId: string
  showName?: boolean
}

const RecordedVideo = ({name, userId}: Pick<ReactionVideoProps, 'userId' | 'name'>) => {

  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const playbackController = useWeWatchController().playbackController;
  const chatVolume = useRecoilValue(chatVolumeState);

  useEffect(() => {
    if (videoPlayerRef.current) {
      playbackController.attachReaction(userId, videoPlayerRef.current);

      return () => {
        playbackController.detachReaction(userId);
      }
    }
  }, [videoPlayerRef, playbackController]);

  useEffect(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.volume = chatVolume;
    }
  }, [chatVolume]);

  return <>
    <chakra.video
      ref={videoPlayerRef}
      width="full"
      playsInline
      zIndex={5}
    />
    <Box position="absolute" zIndex={4}>
      <ProfileFull/>
    </Box>
  </>;
}

const ReactionVideo = ({name, showName, userId}: ReactionVideoProps) => {
  return (
    <AspectRatio
      ratio={16 / 9}
      backgroundColor="gray.800"
      borderRadius="8px"
      style={{
        WebkitMaskImage:'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)'
      }}
      overflow="hidden"
      width="full"
    >
      <Box width="full" height="full" position="relative">
        <RecordedVideo name={name} userId={userId}/>
        {showName && (
          <Name>
            {name}
          </Name>
        )}
      </Box>
    </AspectRatio>
  );
};

export default ReactionVideo;
