import {AspectRatio, Box, Image, Text} from "@chakra-ui/react";
import * as React from "react";

type ReactionCardProps = {
  selected?: boolean
  names: string[]
  thumbnail: string;
  onSelect?: () => void
}

const renderNames = (names: string[]): string => {
  if (!names.length) {
    return '';
  }
  const [first, ...rest] = names;
  if (rest.length) {
    return `${first} & ${rest.length} other${rest.length > 1 ? 's' : ''}`;
  } else {
    return first;
  }
}

const ReactionCard = ({names, thumbnail, selected = false, onSelect}: ReactionCardProps) => {

  const reactionThumbnail = thumbnail ? `${process.env.REACT_APP_MEDIA_ASSET_URL}/${thumbnail}` : '/default_reaction_thumbnail.png';
  return (
    <Box cursor={onSelect ? 'pointer' : undefined} onClick={onSelect}>
      <AspectRatio
        ratio={240 / 135}
        borderRadius={8}
        overflow="hidden"
        _after={selected ? {
          width: '100%',
          height: '100%',
          content: '" "',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: 8,
          borderColor: 'cyan.200',
          borderStyle: 'solid',
          borderWidth: 4
        } : {}}
      >
        <Image
          src={reactionThumbnail}
        />
      </AspectRatio>
      <Box paddingTop={2}>
        <Text
          color={selected ? 'cyan.200' : 'white'}
          fontSize="sm"
        >
          {renderNames(names)}
        </Text>
      </Box>
    </Box>
  );
};

export default ReactionCard;
