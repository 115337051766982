import * as React from "react";
import {useRecoilValue} from "recoil";
import {chatVolumeState, membersState, memberState} from "../../room/roomState";
import LiveAudio from "./LiveAudio";


const RemoveMemberAudio = ({memberId}: { memberId: string }) => {
  const member = useRecoilValue(memberState(memberId));
  const chatVolume = useRecoilValue(chatVolumeState);

  return <LiveAudio
    audioTrack={member.audioTrack}
    volume={chatVolume}
  />
}

const MemberAudio = () => {

  const members = useRecoilValue(membersState);

  return <>
    {members.map(memberId => (
      <RemoveMemberAudio memberId={memberId} key={memberId}/>
    ))}
  </>;
};

export default MemberAudio;
