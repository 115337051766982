import {Box, Button, Flex, Heading, Stack, Text} from "@chakra-ui/react";
import * as React from "react";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import FullscreenLoader from "../../components/FullscreenLoader";
import PageHeader from "../../components/PageHeader";
import {useGetMovieDetails, useGetReaction} from "../../useWewatchApi";
import ReactionCard from "../../components/ReactionCard";
import mixpanel from "mixpanel-browser";

const lengthToTime = (length: number) => {
  const minutesTotal = length / 1000 / 60;
  const minutes = Math.round(minutesTotal % 60);
  const hours = Math.floor(minutesTotal / 60);
  return `${hours}h ${minutes}m`;
}

const MoviePage = () => {

  const {movieId} = useParams<{ movieId: string }>();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const reactionId = params.get('reactionId') || undefined;

  const {loading, data: movie} = useGetMovieDetails(movieId!);
  const {data: reactionData} = useGetReaction(movieId!, reactionId!);

  useEffect(() => {
    mixpanel.track('Movie Details', {
      movieId: movieId,
      reactionId: reactionId
    });
  }, [movieId, reactionId]);

  if (loading || !movie) {
    return <FullscreenLoader title="Loading&hellip;"/>
  }

  const buttonLink = `/movie/${movieId}/play`;

  return (
    <Flex
      minHeight="100vh"
      background={`linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(17,17,17,.25) 40%, rgba(17,17,17,.25) 60%, rgba(17,17,17,1) 100%), url(${process.env.REACT_APP_MEDIA_ASSET_URL}/${movie.s3BackgroundKey})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundAttachment="fixed"
      paddingTop={4}
      alignItems="center"
      justifyContent="center"
    >
      <PageHeader/>
      <Box
        width="900px"
      >
        <Box width="100%">
          <Stack
            maxWidth="500px"
            spacing={4}
            pl={4}
            pr={4}
          >
            <Heading>{movie.title}</Heading>
            <Stack
              align="left"
              direction="row"
              spacing={4}
            >
              <Text>{movie.year}</Text>
              <Text>{lengthToTime(movie.lengthMs)}</Text>
              {movie.genres && (
                <Text>{movie.genres.join(', ')}</Text>
              )}
            </Stack>
            <Text>
              {movie.synopsis}
            </Text>
            <Text>
              {movie.directors.length > 0 && (
                <>
                  <Text color="gray.200" as="span">Director{movie.directors.length > 1 ? 's' : ''}:</Text>
                  {' '}
                  {movie.directors.join(', ')}<br/>
                </>
              )}
              {movie.casts.length > 0 && (
                <>
                  <Text color="gray.200" as="span">Lead Cast:</Text>
                  {' '}
                  {movie.casts.join(', ')}<br/>
                </>
              )}
              <Text color="gray.200" as="span">
                Audio Language:
              </Text>
              {' '}
              {movie.languageAudio}
            </Text>
            <Stack
              align="left"
              direction="row"
              spacing={4}
            >
              <Button
                colorScheme="cyan"
                size="lg"
                onClick={() => reactionId ? navigate(`${buttonLink}?reactionId=${reactionId}`) : navigate(buttonLink)}
              >
                Watch Now
              </Button>
              {movie.trailer && (
                <Button size="lg" onClick={() => window.open(movie.trailer, "_blank")}>Watch Trailer</Button>
              )}
            </Stack>
            {reactionId && reactionData && (
              <Box maxWidth={320}>
                <ReactionCard
                  names={reactionData.individuals}
                  thumbnail={reactionData.reactionThumbnail}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default MoviePage;
