import * as React from "react";
import {useState} from "react";
import {Box, IconButton, Input, Spinner, Td, Tr} from "@chakra-ui/react";
import {EditIcon} from "../../icons";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import DropdownSelect from "../../components/DropdownSelect";

type ReactionRowProps = {
  title: string
  visibility: 'PUBLIC' | 'PRIVATE'
  movieTitle: string
  onUpdate: (val: {title: string, visibility: 'PUBLIC' | 'PRIVATE'}) => void
}

const ReactionRow = (props: ReactionRowProps) => {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [visibility, setVisibility] = useState(props.visibility);
  const [updating, setUpdating] = useState(false);

  const edit = () => {
    if (!updating) {
      setEditing(true);
    }
  }

  const reset = () => {
    setEditing(false);
    setTitle(props.title);
    setVisibility(props.visibility);
  }

  const save = () => {
    setUpdating(true);
    setEditing(false);
    props.onUpdate({
      title,
      visibility
    })
  }

  return (
    <Tr>
      <Td>
        {editing ? (
          <Input variant='filled' value={title} onChange={(e) => setTitle(e.target.value)}/>
        ) : (
          <Box>
            {title}
          </Box>
        )}
      </Td>
      <Td>
        {editing ? (
          <DropdownSelect
            value={visibility}
            onChange={setVisibility}
            options={{
              'PUBLIC': 'Public',
              'PRIVATE': 'Private'
            }}
          />
        ) : (
          <Box>
            {visibility}
          </Box>
        )}
      </Td>
      <Td>
        {props.movieTitle}
      </Td>
      <Td textAlign="right" width="120px">
        {updating ? (
          <Spinner />
        ) : (
          editing ? (
              <>
                <IconButton
                  aria-label="Cancel"
                  icon={<CloseIcon/>}
                  onClick={reset}
                  mr={2}
                />
                <IconButton
                  aria-label="Save"
                  icon={<CheckIcon/>}
                  colorScheme="cyan"
                  onClick={save}
                />
              </>
            ) : (
              <IconButton
                aria-label="Edit"
                icon={<EditIcon/>}
                onClick={edit}/>
            )
        )}

      </Td>
    </Tr>
  );
};

export default ReactionRow;
