import * as React from "react";
import {useEffect, useState} from "react";
import {SubtitlesIcon} from "../../icons";
import {Box, IconButton, Menu, MenuButton, MenuItemOption, MenuList} from "@chakra-ui/react";
import {useRecoilValue} from "recoil";
import {textTracksState} from "../../room/roomState";


const SubtitlesButton = () => {

  const tracks = useRecoilValue(textTracksState);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();

  useEffect(() => {
    tracks.forEach(track => {
      if (selectedLanguage && track.language === selectedLanguage) {
        track.mode = 'showing';
      } else {
        track.mode = 'hidden';
      }
    });
  }, [tracks, selectedLanguage]);
  
  if (!tracks || !tracks.length) {
    return <></>;
  }

  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Subtitles"
          icon={<SubtitlesIcon/>}
          variant="ghost"
        />
        <MenuList>
          <MenuItemOption
            onClick={() => setSelectedLanguage(undefined)}
            isChecked={selectedLanguage === undefined}
          >
            Disabled
          </MenuItemOption>
          {tracks.map(track => (
            <MenuItemOption
              key={track.language}
              onClick={() => setSelectedLanguage(track.language)}
              isChecked={selectedLanguage === track.language}
            >
              {track.label}
            </MenuItemOption>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SubtitlesButton;
