import {useEffect, useState} from "react";
import {initializeDeviceAccess} from "./mediaDevices";

type AccessStatus = 'none' | 'attempting' | 'successful' | 'failed';

type DeviceAccess = [AccessStatus, () => void]

let accessStatus: AccessStatus = 'none';

const subscriptions = new Set<() => void>();
const dispatch = () => {
  subscriptions.forEach(s => s());
}

const attemptAccess = () => {
  if (accessStatus === 'attempting') {
    return;
  }

  accessStatus = 'attempting';
  dispatch();

  initializeDeviceAccess('both')
    .then(a => {
      accessStatus = a ? 'successful' : 'failed';
      dispatch();
    })
    .catch(() => {
      accessStatus = 'failed';
      dispatch();
    })
}

export const useDeviceAccess = (eagerlyAttempt: boolean = false): DeviceAccess => {
  const [state, setState] = useState(accessStatus);

  useEffect(() => {
    const handler = () => {
      setState(accessStatus);
    };

    subscriptions.add(handler);

    return () => {
      subscriptions.delete(handler);
    };
  }, []);

  useEffect(() => {
    if (eagerlyAttempt) {
      attemptAccess();
    }
  }, [eagerlyAttempt]);

  return [state, attemptAccess];
}
