import * as React from "react";
import {ClipId, PlaybackPlaylist, PlaybackSegment} from "./PlaybackPlaylist";
import {PlayerRegistry} from "./PlayerRegistry";
import {CreateNotificationToast, getNotificationMessage} from "../useNotificationToast";
import {MessageKey} from "../client/ClientSocket";
import {chakra, Text} from "@chakra-ui/react";

export class ReactionPlaybackController {
  private static UPDATE_THRESHOLD_SEC = 1.0;

  public readonly playbackPlaylist: PlaybackPlaylist;
  private readonly players: PlayerRegistry;
  private readonly notificationToast: CreateNotificationToast;
  public listener: (() => void) | undefined = undefined;

  private _currentSegment: PlaybackSegment;

  constructor(playbackPlaylist: PlaybackPlaylist, players: PlayerRegistry, notificationToast: CreateNotificationToast) {
    this.playbackPlaylist = playbackPlaylist;
    this.players = players;
    this.notificationToast = notificationToast;
    this._currentSegment = playbackPlaylist.segments[0];
    const clipIds = new Set<string>(playbackPlaylist.segments.flatMap(s => Object.keys(s.reactions)));
    clipIds.forEach(id => this.players.getPlayer(id).registerController(this));
    this.pause(0);
  }

  async onUpdate(id: ClipId, src: string, currentClipTime: number, paused: boolean, endOfClip: boolean) {
    if (this._currentSegment.primaryPlayer !== id) {
      return;
    }

    const currentSegmentTime = endOfClip ? this._currentSegment.lengthSec + .001 : currentClipTime - this._currentSegment.reactions[id].clipTimeSec;

    if (currentSegmentTime >= this._currentSegment.lengthSec || currentSegmentTime < 0) {
      console.log(`Current segmentTime ${currentSegmentTime} is not between 0 and ${this._currentSegment.lengthSec}`);
      const timestamp = currentSegmentTime + this._currentSegment.startTimeSec;
      if (paused && !endOfClip) {
        await this.pause(timestamp, true);
      } else {
        await this.play(timestamp, true);
      }
    } else {
      await Promise.all(this.players.getAllPlayers().map(async (player) => {
        const reaction = this._currentSegment.reactions[player.id];
        if (!reaction) {
          // There is no reaction for this segment. I don't have anything to do here.
          player.unload();
        } else {
          const calculatedClipTime = reaction.clipTimeSec + currentSegmentTime;

          if (player.id === 'movie' && this._currentSegment.movieState === 'pause') {
            await player.pause(reaction.s3HlsPlaylistKey, reaction.clipTimeSec);
          } else {
            if (paused) {
              await player.pause(reaction.s3HlsPlaylistKey, calculatedClipTime);
            } else {
              if (Math.abs(calculatedClipTime - player.currentTime) > ReactionPlaybackController.UPDATE_THRESHOLD_SEC) {
                console.log(`[Player ${player.id} | Segment ${this._currentSegment.startTimeSec}] Calculated time ${calculatedClipTime} doesn't match ${player.currentTime}. Updating.`)
                await player.play(reaction.s3HlsPlaylistKey, calculatedClipTime);
              }
            }
          }
        }
      }));
    }

    if (this.listener) {
      this.listener();
    }
  }

  get paused() {
    const playerId = this._currentSegment.primaryPlayer;
    return this.players.getPlayer(playerId).paused;
  }

  async play(time?: number, notify: boolean = false) {
    if (time) {
      this.updateSegmentIfNecessary(time, notify);
    }
    const currentSegmentTime = (time ?? this.currentTime) - this._currentSegment.startTimeSec;
    const player = this.primarySegmentPlayer();
    const reaction = this._currentSegment.reactions[player.id];
    const calculatedClipTime = reaction.clipTimeSec + currentSegmentTime;
    await player.update(reaction.s3HlsPlaylistKey, false, calculatedClipTime);
  }

  async pause(time?: number, notify: boolean = false) {
    if (time) {
      this.updateSegmentIfNecessary(time, notify);
    }
    const currentSegmentTime = (time ?? this.currentTime) - this._currentSegment.startTimeSec;
    await Promise.all(this.players.getAllPlayers().map(async (player) => {
      const reaction = this._currentSegment.reactions[player.id];
      if (!reaction) {
        // There is no reaction for this segment. I don't have anything to do here.
        player.unload();
      } else {
        if (player.id === 'movie' && this._currentSegment.movieState === 'pause') {
          await player.pause(reaction.s3HlsPlaylistKey, reaction.clipTimeSec);
        } else {
          const calculatedClipTime = reaction.clipTimeSec + currentSegmentTime;
          await player.update(reaction.s3HlsPlaylistKey, true, calculatedClipTime);
        }
      }
    }));
  }

  private updateSegmentIfNecessary(time: number, notify: boolean = false) {
    if (this._currentSegment.startTimeSec + this._currentSegment.lengthSec <= time || this._currentSegment.startTimeSec > time) {
      const oldSegment = this._currentSegment;
      this._currentSegment = this.playbackPlaylist.getSegmentForTimestamp(time);
      console.log(`Switching to segment ${this._currentSegment.startTimeSec}`);
      if (notify) {
        const newSegment = this._currentSegment;
        const member = this.playbackPlaylist.members.find(m => newSegment.reactions[m.id])
        if (!member) {
          return;
        }
        const oldMovieStart = oldSegment.reactions['movie'].clipTimeSec;
        const oldMovieEnd = oldMovieStart + (oldSegment.movieState === 'play' ? oldSegment.lengthSec : 0);
        const newMovieStart = newSegment.reactions['movie'].clipTimeSec;
        const isContinuous = Math.abs(oldMovieEnd - newMovieStart) < 1.5;

        if (isContinuous) {
          if (oldSegment.movieState !== newSegment.movieState) {
            this.notify(
              member.name,
              newSegment.movieState === 'play' ? 'PLAY_MOVIE' : 'PAUSED_MOVIE'
            );
          }
        } else {
          if (newMovieStart > oldMovieEnd) {
            this.notify(
              member.name,
            'JUMP_AHEAD'
            );
          } else {
            this.notify(
              member.name,
              'JUMP_BACK'
            );
          }
        }
      }
    }
  }

  notify(memberName: string, message: MessageKey) {
    this.notificationToast(
      undefined,
      <>
        <chakra.span color="purple.500">{memberName}</chakra.span> {getNotificationMessage(message, false)}
      </>
    );
  }

  private primarySegmentPlayer() {
    const playerId = this._currentSegment.primaryPlayer;
    return this.players.getPlayer(playerId);
  }

  get currentTime(): number {
    const player = this.primarySegmentPlayer();
    console.log(player.id, this._currentSegment.startTimeSec, player.currentTime, this._currentSegment.reactions[player.id].clipTimeSec);
    return this._currentSegment.startTimeSec + (player.currentTime - this._currentSegment.reactions[player.id].clipTimeSec);
  }
}
