import {useContext, useRef} from "react";
import mixpanel, {Dict} from "mixpanel-browser";
import {useRecoilCallback} from "recoil";
import {movieDetailsState, selectedReactionIdState} from "./room/roomState";
import {WeWatchContext} from "./room/WeWatchProvider";

export const useMovieState = (): () => Dict => {
  const ctx = useContext(WeWatchContext);
  return useRecoilCallback(({snapshot}) => {
    return () => {
      const state = snapshot.getLoadable(movieDetailsState).getValue();
      return {
        'Movie ID': ctx?.controller?.movieId,
        'Movie Title': state?.movieTitle,
        'Content Timestamp': ctx?.controller?.playbackController?.currentTime,
        'Reaction Id': snapshot.getLoadable(selectedReactionIdState).getValue()
      };
    };
  });
}

export const useDebouncedMixpanelTracker = (eventName: string, globalProps: () => Dict, debounceDelayMs: number = 5000): (props?: Dict, initialProps?: Dict) => void => {

  let initialPropsRef = useRef<Dict>({});
  let timeoutRef = useRef<number | undefined>(undefined);

  return (props: Dict = {}, initialProps: Dict = {}) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    } else {
      initialPropsRef.current = initialProps;
    }
    timeoutRef.current = window.setTimeout(() => {
      mixpanel.track(eventName, {...globalProps(), ...initialPropsRef.current, ...props});
      timeoutRef.current = undefined;
      initialPropsRef.current = {};
    }, debounceDelayMs);
  }
}

export const useShowroomMixpanelTracker = (): (eventName: string, props?: Dict) => void => {
  const movieState = useMovieState();
  return (eventName: string, props: Dict = {}) => {
    mixpanel.track(
      eventName,
      {...movieState(), ...props}
    )
  }
}

export const useDebouncedShowroomMixpanelTracker = (eventName: string, debounceDelayMs?: number): (props?: Dict, initialProps?: Dict) => void => {
  return useDebouncedMixpanelTracker(eventName, useMovieState(), debounceDelayMs);
}
