import * as React from "react";
import {useEffect, useRef} from "react";

type LiveAudioProps = {
  audioTrack?: MediaStreamTrack
  volume?: number
}

const LiveAudio = ({audioTrack, volume = 1}: LiveAudioProps) => {

  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const player = audioPlayerRef.current!;
    if (audioTrack) {
      const stream = new MediaStream();
      stream.addTrack(audioTrack);
      player.srcObject = stream;
    }
    return () => {
      if (player) {
        player.srcObject = null;
      }
    }
  }, [audioTrack]);

  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.volume = volume;
    }
  }, [volume])

  if (!audioTrack) {
    return <></>;
  }

  return (
    <audio
      ref={audioPlayerRef}
      autoPlay
    />

  );
};

export default LiveAudio;
