import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Button, Flex, FormControl, FormLabel, HStack, Spinner, Text, VStack} from "@chakra-ui/react";
import {MicrophoneOnIcon, VideoOnIcon} from "../../icons";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import DeviceDropdown from "./DeviceDropdown";
import {useDeviceAccess} from "../../useDeviceAccess";
import {ThemeTypings} from "@chakra-ui/styled-system";
import MemberVideo from "./MemberVideo";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";
import {determineDefaultDevices} from "../../mediaDevices";
import {useWeWatchController} from "../../room/WeWatchProvider";
import {useNavigate} from "react-router";

type SelectDevicePopupProps = {
  colorScheme?: ThemeTypings["colorSchemes"]
  startButtonLabel?: string
  helpText?: string
  helpLink?: string
  onSubmit?: () => void
}

const getStream = async (kind: MediaKind, deviceId: string) => {
  return await navigator.mediaDevices.getUserMedia({
    [kind]: {
      deviceId: {ideal: deviceId}
    }
  });
}

const SelectDevicePopup = ({colorScheme = 'cyan', startButtonLabel, helpText, helpLink, onSubmit}: SelectDevicePopupProps) => {

  const [accessStatus, reattemptAccess] = useDeviceAccess(true);

  const navigate = useNavigate();

  const [videoStream, setVideoStream] = useState<MediaStreamTrack>();
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>();
  const [selectedWebcam, setSelectedWebcam] = useState<string>();
  const [starting, setStarting] = useState(false);

  const weWatchController = useWeWatchController();

  useEffect(() => {
    if (!selectedWebcam) {
      setVideoStream(undefined);
      return;
    }
    (async () => {
      const stream = await getStream('video', selectedWebcam);
      setVideoStream(stream.getVideoTracks()[0]);
    })();
  }, [selectedWebcam]);

  useEffect(() => {
    return () => {
      // videoStream?.stop();
    }
  }, [videoStream]);

  useEffect(() => {
    if (accessStatus === 'successful') {
      const [webcamDefault, microphoneDefault] = determineDefaultDevices();
      setSelectedWebcam(webcamDefault);
      setSelectedMicrophone(microphoneDefault);
    }
  }, [accessStatus]);

  const handleStart = async () => {
    setStarting(true);
    if (weWatchController.status !== 'in_session') {
      console.log('Starting showroom');
      await weWatchController.startShowroom();
      navigate(`/play/${weWatchController.roomId!}`, {replace: true})
      console.log('Waiting for showroom');
      while (!await weWatchController.attemptJoin()) {
        console.log('Still connecting');
      }
      console.log('Got the showroom!');
    } else {
      console.log('Session already exists!');
    }
    const webcamController = weWatchController.showroomClient!.webcamController;
    const microphoneController = weWatchController.showroomClient!.microphoneController;
    await Promise.all([
      (async () => {
        if (selectedMicrophone) {
          const stream = await getStream('audio', selectedMicrophone);
          await microphoneController?.selectDeviceAndTrack(selectedMicrophone, stream.getAudioTracks()[0]);
        } else {
          await microphoneController?.selectDevice(undefined);
        }
      })(),
      (async () => {
        if (selectedWebcam) {
          const track = videoStream?.clone() || (await getStream('video', selectedWebcam)).getVideoTracks()[0];
          await webcamController?.selectDeviceAndTrack(selectedWebcam, track);
        } else {
          await webcamController?.selectDevice(undefined);
        }
      })()
    ]);
    if (onSubmit) {
      onSubmit();
    }
    setStarting(false);
  }

  if (accessStatus === 'none') {
    return <></>;
  }

  if (accessStatus === 'attempting') {
    return <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Spinner/>
    </Flex>;
  }

  if (accessStatus === 'failed') {
    return <VStack width="full" spacing={4}>
      <Text fontSize="lg" fontWeight={600} textAlign="left" width="full">
        Allow Permissions
      </Text>
      <HStack alignSelf="start">
        <Flex>
          <MicrophoneOnIcon boxSize={7}/>
        </Flex>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="md" fontWeight={600}>
            Use Microphone
          </Text>
          <Text fontSize="md" color="gray">
            To record your voice
          </Text>
        </VStack>
      </HStack>
      <HStack alignSelf="start">
        <Flex>
          <VideoOnIcon boxSize={7}/>
        </Flex>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="md" fontWeight={600}>
            Use Camera
          </Text>
          <Text fontSize="md" color="gray">
            To record your face
          </Text>
        </VStack>
      </HStack>
      <Button
        width="full"
        colorScheme={colorScheme}
        onClick={reattemptAccess}
      >
        Allow
      </Button>
      <Button variant="ghost" width="full" leftIcon={<ExternalLinkIcon/>}>
        Learn More About Recording
      </Button>
    </VStack>
  }

  return (
    <VStack width="full" spacing={4}>
      <Box width="full">
        <MemberVideo
          videoTrack={videoStream}
          videoEnabled={Boolean(videoStream)}
          audioEnabled={Boolean(selectedMicrophone)}
          mirrored
        />
      </Box>
      <FormControl>
        <FormLabel>Microphone</FormLabel>
        <DeviceDropdown
          kind="audio"
          value={selectedMicrophone}
          onChange={setSelectedMicrophone}
          leftIcon={<MicrophoneOnIcon/>}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Camera</FormLabel>
        <DeviceDropdown
          kind="video"
          value={selectedWebcam}
          onChange={setSelectedWebcam}
          leftIcon={<VideoOnIcon/>}
        />
      </FormControl>
      <Button width="full" colorScheme={colorScheme} onClick={handleStart} isLoading={starting}>
        {startButtonLabel || 'Start Co-Watching'}
      </Button>
      {helpText && helpLink && (
        <Button
          as="a"
          href={helpLink}
          target="_blank"
          variant="ghost"
          width="full"
          leftIcon={<ExternalLinkIcon/>}
        >
          {helpText}
        </Button>
      )}
    </VStack>
  );
};

export default SelectDevicePopup;
