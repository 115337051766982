import * as React from "react";
import {GridLoader} from "react-spinners";
import {Flex} from "@chakra-ui/react";

const CenteredLoader = () => {
  return (
    <Flex direction="column" align="center" p={8}>
      <GridLoader color="white"/>
    </Flex>
  );
};

export default CenteredLoader;
