import * as React from "react";
import {useCallback, useEffect} from "react";
import PageHeader from "../../components/PageHeader";
import {Box} from "@chakra-ui/react";
import {stripe} from "../../stripeConfig";
import {Elements} from "@stripe/react-stripe-js";
import Checkout from "../../components/checkout/Checkout";
import FullscreenLoader from "../../components/FullscreenLoader";
import {useNavigate, useParams} from "react-router";
import {useGetMovieDetails, useLoadTicket} from "../../useWewatchApi";
import {useSearchParams} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {withAuthenticationRequired} from "@auth0/auth0-react";

const CheckoutPage = () => {

  const {movieId} = useParams<{ movieId: string }>();
  const [params] = useSearchParams();
  const reactionId = params.get('reactionId') || undefined;

  const {loading, data: movie} = useGetMovieDetails(movieId!);
  const navigate = useNavigate();
  const {data: ticketInfo, loading: ticketInfoLoading} = useLoadTicket(movieId!);

  const goToPlayer = useCallback((movieId: string, reactionId?: string) => {
    if (reactionId) {
      navigate(`/movie/${movieId}/play?reactionId=${reactionId}`, {replace: true});
    } else {
      navigate(`/movie/${movieId}/play`, {replace: true});
    }
  }, [navigate]);

  useEffect(() => {
    if (movie?.price && parseInt(movie.price) === 0) {
      goToPlayer(movieId!, reactionId);
    }
  }, [movieId, movie?.price, reactionId, goToPlayer]);

  useEffect(() => {
    mixpanel.track('Checkout Page', {
      movieId: movieId,
    });
  }, []);

  useEffect(() => {
    if (ticketInfo && movie) {
      const hasTicket = ticketInfo && (ticketInfo.hasActiveTicket || ticketInfo.numberInactiveTickets > 0);
      const requiresTicket = parseInt(movie.price) > 0 && !hasTicket;
      if (!requiresTicket) {
        goToPlayer(movieId!, reactionId);
      }
    }
  }, [ticketInfo, movie]);

  if (loading || !movie || ticketInfoLoading) {
    return <FullscreenLoader title="Loading&hellip;"/>
  }

  if (parseInt(movie.price) === 0) {
    return <></>;
  }

  return (
    <>
      <PageHeader/>
      <Box maxWidth="900px" margin="0 auto" paddingTop="20vh">
        <Elements stripe={stripe}>
          <Checkout
            movieId={movieId!}
            movieTitle={movie.title}
            price={parseFloat(movie.price)}
            onSuccess={() => goToPlayer(movieId!, reactionId)}
          />
        </Elements>
      </Box>
    </>
  );
};

export default withAuthenticationRequired(
  CheckoutPage,
  {
    onRedirecting: () => <FullscreenLoader title="Signing in&hellip;"/>
  }
);
