import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import * as React from "react";
import {useRecoilState} from "recoil";
import {addUserModalOpenState} from "../../room/roomState";
import RecordingList from "./RecordingList";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";

const AddReactionModal = () => {

  const [isOpen, setIsOpen] = useRecoilState(addUserModalOpenState);

  const mixpanelTracker = useShowroomMixpanelTracker();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        mixpanelTracker('Close Add Curation');
        setIsOpen(false);
      }}
      size="4xl"
      scrollBehavior="inside"
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Creators' Cuts</ModalHeader>
        <ModalCloseButton/>
        <ModalBody overflowY="scroll" flexGrow={1}>
          <RecordingList/>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddReactionModal;
