import * as React from "react";
import {AddUserIcon} from "../../icons";
import {
  Box,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useClipboard
} from "@chakra-ui/react";
import {LinkIcon} from "@chakra-ui/icons";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";

const WatchWithFriendsButton = () => {

  const {hasCopied, onCopy} = useClipboard(window.location.href);

  const mixpanelTracker = useShowroomMixpanelTracker();


  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label="Invite Friends"
            icon={<AddUserIcon boxSize={6}/>}
            variant="ghost"
          />
        </PopoverTrigger>
        <PopoverContent>
          <Text fontSize="lg" fontWeight={600}>
            Invite Link
          </Text>
          <Text fontSize="md" color="gray.200" fontWeight={500}>
            Invite friends to watch together by sharing the below link.
          </Text>
          <InputGroup size='md' backgroundColor="gray.800" borderRadius={5}>
            <InputLeftElement>
              <LinkIcon/>
            </InputLeftElement>
            <Input pr='4.5rem' isReadOnly value={window.location.href}/>
            <InputRightElement width='4.5rem'>
              <Button
                size='sm'
                onClick={() => {
                  mixpanelTracker('Copy Link');
                  onCopy();
                }}
              >
                {hasCopied ? 'Copied' : 'Copy'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default WatchWithFriendsButton;
