import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Flex} from "@chakra-ui/react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {movieDetailsState, reactionDetailsState, selectedReactionIdState, showroomState} from "../../room/roomState";
import {useGetReaction, useLoadTicket} from "../../useWewatchApi";
import MoviePlayer from "./MoviePlayer";
import FullscreenLoader from "../../components/FullscreenLoader";
import ActivateTicketDialog from "./ActivateTicketDialog";
import {Elements} from "@stripe/react-stripe-js";
import {stripe} from "../../stripeConfig";
import Checkout from "../../components/checkout/Checkout";
import {useWeWatchContext, useWeWatchController} from "../../room/WeWatchProvider";
import {PlaybackPlaylist} from "../../room/PlaybackPlaylist";
import {ReactionPlaybackController} from "../../room/ReactionPlaybackController";
import useNotificationToast from "../../useNotificationToast";

const PlaybackZone = () => {

  const {movieId, playbackController} = useWeWatchController();
  const selectedReactionId = useRecoilValue(selectedReactionIdState);
  const [movieSrc, setMovieSrc] = useState<string>();
  const setMovieDetails = useSetRecoilState(movieDetailsState);
  const setReactionDetails = useSetRecoilState(reactionDetailsState);
  const setShowroomState = useSetRecoilState(showroomState);
  const {loading, data, forceReload} = useLoadTicket(movieId);
  const reactionDetails = useGetReaction(movieId, selectedReactionId);
  const {setReactionController} = useWeWatchContext();
  const notificationToast = useNotificationToast();

  useEffect(() => {
    if (!data) {
      return;
    }

    setMovieDetails({
      movieId: movieId,
      movieTitle: data.movieTitle,
      movieThumbnailSrc: `${process.env.REACT_APP_MEDIA_ASSET_URL}/${data.movieThumbnailKey}`,
      lengthMs: data?.previewUrl ? data.lengthMs : undefined // Only include the lengthMs if in preview mode
    });

    if (data?.movieUrl) {
      setMovieSrc(`${process.env.REACT_APP_MEDIA_ASSET_URL}/${data.movieUrl}`);
    } else if (data?.previewUrl) {
      setMovieSrc(`${process.env.REACT_APP_MEDIA_ASSET_URL}/${data.previewUrl}`);
    } else {
      setMovieSrc(undefined);
    }
  }, [movieId, setMovieSrc, setShowroomState, setMovieDetails, data]);

  useEffect(() => {
    if (selectedReactionId) {
      setReactionDetails(reactionDetails?.data);
    } else {
      setReactionDetails(undefined);
    }
  }, [setReactionDetails, selectedReactionId, reactionDetails?.data?.id]);

  useEffect(() => {
    if (movieSrc && data?.lengthMs) {
      if (reactionDetails?.data) {
        setReactionController(new ReactionPlaybackController(
          PlaybackPlaylist.fromReaction(
            movieSrc,
            data.lengthMs,
            reactionDetails.data.parts
          ),
          playbackController.players,
          notificationToast
        ));
      } else {
        setReactionController(new ReactionPlaybackController(
          PlaybackPlaylist.fromMovieOnly(
            movieSrc,
            data.lengthMs
          ),
          playbackController.players,
          notificationToast
        ));
      }
    } else {
      setReactionController(undefined)
    }
  }, [playbackController, reactionDetails?.data, reactionDetails?.data?.id, data?.lengthMs, movieSrc])

  if (movieSrc) {
    return <MoviePlayer forceReload={forceReload}/>;
  }

  if (!movieId || loading || !data || data.hasActiveTicket) {
    return <FullscreenLoader title="Loading&hellip;"/>;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      width="full"
      height="full"
    >
      {data.numberInactiveTickets >= 1 ? (
        <ActivateTicketDialog movieId={movieId} onSuccess={forceReload}/>
      ) : (
        <Box maxWidth="900px">
          <Elements stripe={stripe}>
            <Checkout
              movieId={movieId}
              movieTitle={data.movieTitle}
              price={3.99}
              onSuccess={forceReload}
            />
          </Elements>
        </Box>
      )}
    </Flex>
  );
};

export default PlaybackZone;
