import * as React from "react";
import {useListMyReactions, useUpdateReaction} from "../../useWewatchApi";
import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/react";
import CenteredLoader from "../../components/CenteredLoader";
import ReactionRow from "./ReactionRow";

const MyRecordings = () => {
  const {loading, data: reactions, forceReload} = useListMyReactions();
  const {post} = useUpdateReaction();

  if (loading) {
    return <CenteredLoader/>;
  }

  const onUpdate = (reactionId: string) => async (val: { title: string, visibility: 'PUBLIC' | 'PRIVATE' }) => {
    await post({
      reactionId,
      visibility: val.visibility,
      title: val.title
    });
    await forceReload();
  }

  return (
    <>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Curation Title</Th>
            <Th width="20%">Visibility</Th>
            <Th width="20%">Movie</Th>
            <Th/>
          </Tr>
        </Thead>
        <Tbody>
          {reactions?.map(reaction => <ReactionRow
            key={reaction.id}
            title={reaction.reactionTitle}
            visibility={reaction.visibility}
            movieTitle={reaction.movieTitle}
            onUpdate={onUpdate(reaction.id)}
          />)}
        </Tbody>
      </Table>
    </>
  );
};

export default MyRecordings;
