import {Auth0Provider} from "@auth0/auth0-react";
import * as React from "react";
import {useNavigate} from "react-router";
import {AppState} from "@auth0/auth0-react/dist/auth0-provider";

const Auth0ProviderWithHistory = ({children}: {children: React.ReactNode}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || 'wewatch-dev.us.auth0.com'}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || 'G58Lm5yWU9xlQfJriaysmFZdqB4iZrag'}
      redirectUri={window.location.origin}
      audience="https://api.wewatch.best"
      scope="openid profile"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
