import {extendTheme, StyleFunctionProps} from "@chakra-ui/react";
import {mode} from "@chakra-ui/theme-tools"

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark'
  },
  fonts: {
    heading: '"Inter", sans-serif',
    body: '"Inter", sans-serif'
  },
  fontWeights: {
    normal: 600
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '2rem',
  },
  colors: {
    white: '#F6F6F6',
    gray: {
      50: '#F6F6F6',
      200: '#A0A0A0',
      400: '#686868',
      500: '#363636',
      700: '#242424',
      800: '#121212',
      900: '#121212',
    },
    cyan: {
      100: '#7AE1DF',
      200: '#64DFDF',
      300: '#2FACAC'
    },
    purple: {
      100: '#A694FF',
      200: '#A694FF',
      500: '#BB6BD9'
    }
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'normal',
        borderRadius: 'full'
      },
      sizes: {
        lg: {
          h: 10,
          minWidth: 10
        },
        md: {
          h: 8,
          minWidth: 8
        },
        sm: {
          h: 6,
          minWidth: 6
        },
        xs: {
          h: 4,
          minWidth: 4
        },
      },
      variants: {
        solid: (props: StyleFunctionProps) => {
          if (props.colorScheme === 'gray') {
            return {
              bg: mode('gray.100', 'gray.800')(props),
              _hover: {
                bg: mode('gray.200', 'gray.600')(props)
              },
              _active: {
                bg: mode('gray.300', 'rgba(36, 36, 36, 0.6)')(props)
              }
            };
          } else if (props.colorScheme === 'cyan') {
            return {
              bg: 'cyan.200',
              _hover: {
                bg: 'cyan.100'
              },
              _active: {
                bg: 'rgba(100, 223, 223, 0.6)'
              }
            }
          } else if (props.colorScheme === 'purple') {
            return {
              bg: 'purple.200',
              _hover: {
                bg: 'purple.100'
              },
              _active: {
                bg: 'rgba(187, 107, 217, 0.6)'
              }
            }
          }
          return {};
        }
      }
    },
    Icon: {},
    Tooltip: {
      baseStyle: {
        '--tooltip-bg': 'colors.gray.700',
        px: '16px',
        py: '8px',
        '--popper-arrow-bg': 'var(--tooltip-bg)',
        color: 'whiteAlpha.900',
      }
    },
    Popover: {
      baseStyle: {
        content: {
          p: 3,
          bg: 'rgba(36, 36, 36, 0.7)',
          backdropFilter: 'auto',
          backdropBlur: '4px',
          border: 'none'
        }
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'rgba(36, 36, 36, 0.7)',
          backdropFilter: 'auto',
          backdropBlur: '4px',
        }
      }
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: 9
        }
      }
    }
  },
  styles: {
    global: {
      body: {
        fontWeight: 600
      },
      '::-webkit-scrollbar': {
        width: '20px'
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.24)',
        borderRadius: '20px',
        border: '6px solid transparent',
        backgroundClip: 'content-box'
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.36)'
      },
      '::-webkit-scrollbar-button': {
        height: 0,
        width: 0,
        display: 'none'
      },
    }
  }
});
