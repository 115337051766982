import * as React from "react";
import {useEffect, useRef, VideoHTMLAttributes} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {textTracksState, videoPlaybackState, videoVolumeState} from "../../room/roomState";
import {chakra} from "@chakra-ui/react";
import {useWeWatchController} from "../../room/WeWatchProvider";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";
import {MoviePlayerListener} from "../../room/MoviePlayerListener";

const toTextTrackArray = (list: TextTrackList): TextTrack[] => {
  const arr: TextTrack[] = [];
  for (let i = 0; i < list.length; i++) {
    arr.push(list[i]);
  }
  return arr;
}

const MovieVideo = (props: VideoHTMLAttributes<HTMLVideoElement>) => {

  const playbackController = useWeWatchController().playbackController;
  const setPlaybackState = useSetRecoilState(videoPlaybackState);
  const videoVolume = useRecoilValue(videoVolumeState);
  const setTextTracks = useSetRecoilState(textTracksState);

  const videoPlayerRef = useRef<HTMLVideoElement>(null);

  const mixpanelTracker = useShowroomMixpanelTracker();

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoPlayerRef.current) {
        if (!videoPlayerRef.current.paused) {
          mixpanelTracker('Playing');
        }
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [videoPlayerRef]);

  useEffect(() => {
    if (playbackController) {
      playbackController.attach(videoPlayerRef.current!);
      const listener: MoviePlayerListener = {
        onUpdate(paused) {
          setPlaybackState(paused ? 'paused' : 'playing');
        }
      };
      playbackController.registerListener(listener)

      return () => {
        playbackController.detach();
        playbackController.removeListener(listener);
      };
    }
  }, [playbackController]);

  useEffect(() => {
    videoPlayerRef.current!.volume = videoVolume;
  }, [videoVolume]);

  useEffect(() => {
    if (videoPlayerRef.current) {
      const player = videoPlayerRef.current;
      const handler = () => {
        setTextTracks(toTextTrackArray(player.textTracks));
      }
      player.textTracks.addEventListener('addtrack', handler);
      player.textTracks.addEventListener('change', handler);
      player.textTracks.addEventListener('removetrack', handler);
      handler();

      return () => {
        player.textTracks.removeEventListener('addtrack', handler);
        player.textTracks.removeEventListener('change', handler);
        player.textTracks.removeEventListener('removetrack', handler);

      }
    }
  }, [videoPlayerRef.current]);

  return (
    <chakra.div position="absolute" top={0} right={0} bottom={0} left={0} zIndex={-1}>
      <chakra.video
        onClick={() => playbackController?.togglePlayPause()}
        zIndex={-5}
        width="full"
        height="full"
        ref={videoPlayerRef}
        backgroundColor="#000"
        playsInline
        {...props}
      />
    </chakra.div>
  );
};

export default MovieVideo;
