import * as React from "react";
import {Button, HStack, Text, VStack} from "@chakra-ui/react";
import {AddCurationIcon, AddUserIcon} from "../../icons";

type ShowroomTitleProps = {
  title?: string
  reactionTitle?: string
  onReactionClick?: () => void
}

const ShowroomTitle = ({title, reactionTitle, onReactionClick}: ShowroomTitleProps) => {

  if (reactionTitle) {
    return (
      <>
        <VStack spacing={0} alignItems="start">
          <HStack>
            <Text>
              {reactionTitle}
            </Text>
            <Button
              colorScheme="cyan"
              variant="outline"
              onClick={onReactionClick}
            >
              Change
            </Button>
          </HStack>
          <Text fontSize="sm" color="gray.200">
            {title}
          </Text>
        </VStack>
      </>
    );
  } else {
    return (
      <>
        <Text>
          {title}
        </Text>
        <Button
          colorScheme="cyan"
          leftIcon={<AddCurationIcon boxSize={6}/>}
          onClick={onReactionClick}
        >
          Add Creator
        </Button>
      </>
    );
  }
};

export default ShowroomTitle;
