import * as React from "react";
import {Button} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";

const LoginButton = () => {
  const {isAuthenticated, loginWithPopup} = useAuth0();

  if (isAuthenticated) {
    return <></>;
  }

  return (
    <Button
      variant="outline"
      onClick={() => loginWithPopup()}
    >
      Login
    </Button>
  );
};

export default LoginButton;
