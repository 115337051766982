import {Transport} from "mediasoup-client/lib/Transport";
import {ShowroomClient} from "../ShowroomClient";
import {Device} from "mediasoup-client";
import {Consumer, ConsumerOptions} from "mediasoup-client/lib/Consumer";

export class ConsumerTransport {

  private readonly transport: Transport;

  private constructor(transport: Transport) {
    this.transport = transport;
  }

  async consume(options: ConsumerOptions): Promise<Consumer> {
    return this.transport.consume(options);
  }

  get closed() {
    return this.transport.closed;
  }

  close() {
    this.transport.close();
  }

  static async create(showroomClient: ShowroomClient, device: Device): Promise<ConsumerTransport> {
    const producerTransportData = await showroomClient.socket.request('createConsumerTransport');
    const transport = device.createRecvTransport(producerTransportData);

    transport.on('connect', async ({dtlsParameters}, callback, errback) => {
      showroomClient.socket.request('connectConsumerTransport', {dtlsParameters})
        .then(callback)
        .catch(errback);
    });

    transport.on('connectionstatechange', (state) => {
      console.log('ConsumerTransport State: ' + state);
    });

    return new ConsumerTransport(transport);
  }
}
