import * as React from "react";
import ShowroomPlayer from "./ShowroomPlayer";

// TODO: Inline ShowroomPlayer into PlayPage
const PlayPage = () => {
  return (
    <ShowroomPlayer/>
  );
}

export default PlayPage;
