const leadZero = (num: number): string => {
  if (num < 10) {
    return '0' + num;
  } else {
    return num.toString();
  }
};

export const toTimeString = (time: number) => {
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time) % 60;

  if (hours > 0) {
    return `${hours}:${leadZero(minutes)}:${leadZero(seconds)}`;
  } else {
    return `${minutes}:${leadZero(seconds)}`;
  }
};
