import * as React from "react";
import {useState} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  useTheme
} from "@chakra-ui/react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {StripeCardElementChangeEvent} from "@stripe/stripe-js";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import usePriceCalculator, {formatMoney} from "./usePriceCalculator";
import PromoCodeInput from "./PromoCodeInput";
import {apiBaseUrl} from "../../http";
import {useLoadTicket} from "../../useWewatchApi";
import {SpinnerIcon} from "@chakra-ui/icons";
import FullscreenLoader from "../FullscreenLoader";

type CheckoutParams = {
  movieId: string
  movieTitle: string
  price: number
  onSuccess: () => void
}

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const Checkout = ({movieId, movieTitle, price, onSuccess}: CheckoutParams) => {

  const theme = useTheme<{ colors: { gray: { 50: string, 200: string } } }>();
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  // const [ticketQuantity, setTicketQuantity] = useState<number>(1);
  const ticketQuantity = 1;
  const [error, setError] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe()!;
  const elements = useElements();
  const [promoCode, setPromoCode] = useState<string | undefined>();
  const calculatedPrice = usePriceCalculator(movieId, ticketQuantity, promoCode);
  // const {data: ticketInfo, forceReload: reloadTickets} = useLoadTicket(movieId);
  const {forceReload: reloadTickets} = useLoadTicket(movieId);

  // const hasTicket = ticketInfo && (ticketInfo.hasActiveTicket || ticketInfo.numberInactiveTickets > 0);

  const waitForTicket = async (): Promise<boolean> => {
    for (let i = 0; i < 10; i++) {
      const response = await reloadTickets();
      if (response.hasActiveTicket || response.numberInactiveTickets > 0) {
        return true;
      }
      await timeout(1000);
    }
    return false;
  }

  const cardStyle = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: theme.colors.gray['50'],
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: theme.colors.gray['200']
        }
      },
      invalid: {
        iconColor: "#EB5757",
        color: "#EB5757"
      }
    }
  };

  const handleCardChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (evt: React.SyntheticEvent) => {
    if (!calculatedPrice) {
      return;
    }
    setProcessing(true);
    evt.preventDefault();

    const token = await getAccessTokenSilently();

    const purchaseIntentResult = await fetch(apiBaseUrl + '/create-purchase-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        movieId: movieId,
        quantity: ticketQuantity,
        promoCode: calculatedPrice?.promoCodeStatus === 'VALID' ? promoCode : undefined,
        total: calculatedPrice.total
      })
    });

    if (!purchaseIntentResult.ok) {
      setError('An unexpected error has occurred');
      setProcessing(false);
      return;
    }

    const {clientSecret, paymentNotNecessary} = await purchaseIntentResult.json();

    if (!paymentNotNecessary) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements?.getElement(CardElement)!
        }
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
        return;
      }
      await waitForTicket();
    }

    setError('');
    onSuccess();
  }

  return (
    <Flex justifyContent="center">
      <Stack direction={['column', 'column', 'row']} spacing={4} width="full">
        <Box maxWidth={['full', 'full', 350]} flexGrow={1}>
          <Flex bgColor="gray.700" borderRadius="lg" direction="column" padding={4}>
            <Text fontSize="2xl">{movieTitle}</Text>
            <Flex alignItems="center">
              <Box>
                <Text as="span">${formatMoney(price * 100)}</Text>
                <Text as="span" fontSize="sm" color="gray.200">{' / ticket'}</Text>
              </Box>
              <Spacer/>
              {/*<TicketQuantitySelector value={ticketQuantity} onUpdate={setTicketQuantity}/>*/}
            </Flex>
            <Accordion allowToggle mt={2}>
              <AccordionItem border="none">
                <Flex>
                  <AccordionButton px={0}>
                    <Text>Total</Text>
                    <Spacer/>
                    {calculatedPrice ? (
                      <>
                        ${calculatedPrice.total}
                        {calculatedPrice.discount && (
                          <Text as="span" fontSize="sm" px={1} color="purple.500">
                            10% OFF
                          </Text>
                        )}
                      </>
                    ) : <SpinnerIcon/>}
                    <AccordionIcon/>
                  </AccordionButton>
                </Flex>
                <AccordionPanel px={0}>
                  {calculatedPrice && (
                    <>
                      <Flex>
                        <Box>
                          $3.99 x {ticketQuantity}
                        </Box>
                        <Spacer/>
                        <Box>
                          ${calculatedPrice.subtotal}
                        </Box>
                      </Flex>
                      {calculatedPrice.discount && (
                        <Flex color="purple.500">
                          <Box>
                            Discount
                          </Box>
                          <Spacer/>
                          <Box>
                            -${calculatedPrice.discount}
                          </Box>
                        </Flex>
                      )}
                      {calculatedPrice.promoDiscount && (
                        <Flex>
                          <Box>
                            Promo: {promoCode}
                          </Box>
                          <Spacer/>
                          <Box>
                            -${calculatedPrice.promoDiscount}
                          </Box>
                        </Flex>
                      )}
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Box fontSize="sm" backgroundColor="gray.500" borderRadius="lg" p={2}>
              {/*<Text>Enjoy 10% group discount with your friends! Any unused tickets are auto-refunded after 48hrs.</Text>*/}
              {/*<Text color="gray.200">You can invite and send the tickets to your friends when they join the*/}
              {/*  showroom. </Text>*/}
              <Text>Tickets include 48 hours to start watching this video and 48 hours to finish once activated in the showroom. Unactivated tickets will be automatically refunded.</Text>
            </Box>
          </Flex>
          <Box bgColor="gray.700" borderRadius="lg" px={4} py={2} mt={4}>
            <Accordion allowToggle mt={2}>
              <AccordionItem border="none">
                <AccordionButton px={0}>
                  <Text fontSize="2xl">Promotion Code</Text>
                  <Spacer/>
                  <AccordionIcon/>
                </AccordionButton>
                <AccordionPanel px={0}>
                  <PromoCodeInput value={promoCode || ''} onApply={setPromoCode}/>
                  {calculatedPrice?.promoCodeStatus === 'INVALID' && (
                    <Text>
                      Promo {promoCode} is invalid
                    </Text>
                  )}
                  {calculatedPrice?.promoCodeStatus === 'EXPIRED' && (
                    <Text>
                      Promo {promoCode} is expired
                    </Text>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          {calculatedPrice && parseInt(calculatedPrice.total) === 0 && (
            <Box textAlign="right" mt={4}>
              <Button
                colorScheme="cyan"
                size="lg"
                isLoading={processing}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </Box>
          )}
        </Box>
        {calculatedPrice && parseInt(calculatedPrice.total) > 0 && (
          <Box flexGrow={4} minWidth={300}>
            <form onSubmit={handleSubmit}>
              <Flex bgColor="gray.700" borderRadius="lg" direction="column" padding={4}>
                <Text fontSize="2xl">Payment Detail</Text>
                <Box
                  backgroundColor="gray.500"
                  borderRadius="8"
                  py={2}
                  px={4}
                  my={3}
                >
                  <CardElement options={cardStyle} onChange={handleCardChange}/>
                </Box>
                {error && (
                  <Alert status="error" mb={3}>
                    <AlertIcon/>
                    <AlertTitle>{error}</AlertTitle>
                  </Alert>
                )}
                <Box textAlign="right">
                  <Button
                    colorScheme="cyan"
                    size="lg"
                    isDisabled={parseInt(calculatedPrice.total) > 0 && (disabled || Boolean(error))}
                    isLoading={processing}
                    type="submit"
                  >
                    Purchase
                  </Button>
                </Box>
              </Flex>
            </form>
          </Box>
        )}
      </Stack>
    </Flex>
  )
    ;
};

export default withAuthenticationRequired(
  Checkout,
  {
    onRedirecting: () => <FullscreenLoader title="Signing in&hellip;"/>
  }
);

