import {io, Socket} from "socket.io-client"
import {MediaKind, RtpCapabilities, RtpParameters} from "mediasoup-client/lib/RtpParameters";
import {ServerShowroomState} from "../room/ShowroomState";
import {DtlsParameters, TransportOptions} from "mediasoup-client/lib/Transport";

type Handler<T> = (data: T) => void

export type NewConsumerMessage = {
  producerMemberId: string,
  producerName: string,
  producerId: string,
  consumerId: string,
  kind: MediaKind,
  rtpParameters: RtpParameters,
  producerPaused: boolean
}

export type NotificationMessage = {
  memberId: string
  message: MessageKey
}

export type MessageKey = 'PLAY_MOVIE' | 'PAUSED_MOVIE' | 'START_RECORDING' | 'STOP_RECORDING' | 'JUMP_AHEAD' | 'JUMP_BACK';

export class ClientSocket {
  private readonly socket: Socket;

  constructor(mediaServerUrl: string, roomId: string) {
    this.socket = io(mediaServerUrl, {
      autoConnect: false,
      query: {
        roomId
      }
    });
  }

  get id() {
    return this.socket.id;
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  on(event: 'ready', handler: Handler<void>): void;
  on(event: 'newConsumer', handler: Handler<NewConsumerMessage>): void;
  on(event: 'consumerResumed', handler: Handler<{ producerMemberId: string, consumerId: string }>): void;
  on(event: 'consumerPaused', handler: Handler<{ producerMemberId: string, consumerId: string }>): void;
  on(event: 'removeMember', handler: Handler<{ producerMemberId: string }>): void;
  on(event: 'playback', handler: Handler<{ videoUrl: string, thumbnailPath: string, title: string, paused: boolean, timestamp: number, recording: boolean }>): void;
  on(event: 'showroomState', handler: Handler<ServerShowroomState>): void;
  on(event: 'notification', handler: Handler<NotificationMessage>): void;
  on(event: string, handler: Handler<any>): void {
    this.socket.on(event, handler);
  }

  send(type: 'startRecording'): void;
  send(type: 'stopRecording'): void;
  send(type: 'pauseProducer', data: {kind: MediaKind}): void;
  send(type: 'resumeProducer', data: {kind: MediaKind}): void;
  send(type: 'closeProducer', data: {kind: MediaKind}): void;
  send(type: 'play', data: {timestamp: number}): void;
  send(type: 'pause', data: {timestamp: number}): void;
  send(type: 'selectReaction', data: {reactionId?: string}): void;
  send(type: string, data: any = {}): void {
    this.socket.emit(type, data);
  }

  async request(event: 'authenticate', data: { bearer: string }): Promise<{ error?: string }>;
  async request(event: 'getRouterRtpCapabilities'): Promise<RtpCapabilities>;
  async request(event: 'createProducerTransport'): Promise<TransportOptions>;
  async request(event: 'createConsumerTransport'): Promise<TransportOptions>;
  async request(event: 'connectProducerTransport', data: { dtlsParameters: DtlsParameters }): Promise<void>;
  async request(event: 'connectConsumerTransport', data: { dtlsParameters: DtlsParameters }): Promise<void>;
  async request(event: 'produce', data: { kind: MediaKind, rtpParameters: RtpParameters }): Promise<{ id: string }>
  async request(event: 'consume', data: { rtpCapabilities: RtpCapabilities }): Promise<void>
  async request(type: string, data: any = {}): Promise<any> {
    return new Promise(resolve => {
      this.socket.emit(type, data, resolve);
    });
  }
}
