import * as React from "react";
import LiveVideo, {LiveVideoProps} from "./LiveVideo";
import {ReactComponent as ProfileFull} from "../../icons/profile-full.svg";
import {AspectRatio, Box} from "@chakra-ui/react";

const MemberVideo = (props: Omit<LiveVideoProps, 'fallback'>) => {
  return (
    <AspectRatio
      ratio={180 / 135}
      backgroundColor="gray.800"
      borderRadius="8px"
      style={{
        WebkitMaskImage:'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)'
      }}
      overflow="hidden"
      width="full"
    >
      <LiveVideo
        fallback={<Box><ProfileFull/></Box>}
        autoPlay
        {...props}
      />
    </AspectRatio>
  );
};

export default MemberVideo;
