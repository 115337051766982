export type SpritePosition = {
  x: number
  y: number
  width: number
  height: number
}

export type VttThumbnailFrame = {
  startTime: number
  endTime: number
  src: string
  spritePosition?: SpritePosition
}

export const parseVtt = (baseDir: string, vttDataString: string): VttThumbnailFrame[] => {
  const processedList: VttThumbnailFrame[] = [];
  const frames = vttDataString.split(/\r\n\r\n|\n\n|\r\r/);

  frames.forEach(frame => {
    const result: Partial<VttThumbnailFrame> = {};
    const lines = frame.split(/\r\n|\n|\r/);

    lines.forEach(line => {
      if (result.startTime === undefined) {
        // The line with start and end times on it is the first line of interest
        const matchTimes = line.match(
          /([0-9]{2})?:?([0-9]{2}):([0-9]{2}).([0-9]{2,3})( ?--> ?)([0-9]{2})?:?([0-9]{2}):([0-9]{2}).([0-9]{2,3})/,
        ); // Note that this currently ignores caption formatting directives that are optionally on the end of this line - fine for non-captions VTT

        if (matchTimes) {
          result.startTime =
            Number(matchTimes[1] || 0) * 60 * 60 +
            Number(matchTimes[2]) * 60 +
            Number(matchTimes[3]) +
            Number(`0.${matchTimes[4]}`);
          result.endTime =
            Number(matchTimes[6] || 0) * 60 * 60 +
            Number(matchTimes[7]) * 60 +
            Number(matchTimes[8]) +
            Number(`0.${matchTimes[9]}`);
        }
      } else if (line.trim() && !result.src) {
        // If we already have the startTime, then we're definitely up to the text line(s)
        const lineSplit = line.trim().split('#xywh=');
        result.src = `${baseDir}/${lineSplit[0]}`;

        // If there's content in lineSplit[1], then we have sprites. If not, then it's just one frame per image
        if (lineSplit[1]) {
          const [x, y, width, height] = lineSplit[1].split(',').map(s => Number(s));
          result.spritePosition = {
            x, y, width, height
          }
        }
      }
    });

    if (result.src) {
      processedList.push(result as VttThumbnailFrame);
    } else if (!frame || frame === 'WEBVTT') {
      // Nothing, expected to be empty
    } else {
      console.log(`No sprite found in frame: \n${frame}`);
    }
  });

  return processedList;
}
