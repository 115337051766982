import * as React from "react";
import {AspectRatio, Box, HStack, Image, Tag} from "@chakra-ui/react";

type MovieCardProps = {
  posterUrl: string
  title: string
  year: number
  language: string
  length: number
  free: boolean
  onClick: () => void
}

const lengthToTime = (length: number) => {
  const minutesTotal = length / 1000 / 60;
  const minutes = Math.round(minutesTotal % 60);
  const hours = Math.floor(minutesTotal / 60);
  return `${hours}h ${minutes}m`;
}

const MovieCard = ({posterUrl, title, length, year, language, free, onClick}: MovieCardProps) => {
  return (
    <Box>
      <AspectRatio
        ratio={240 / 135}
        borderRadius={8}
        overflow="hidden"
        mb={2}
        transition="opacity 100ms"
        _hover={{
          opacity:0.8
        }}
      >
        <Image src={posterUrl} onClick={onClick} cursor="pointer"/>
      </AspectRatio>
      <Box>
        {title}
      </Box>
      <Box>
        <HStack justifyContent="space-between">
          <Box>
            {year} | {language} | {lengthToTime(length)}
          </Box>
          {free && (
            <Tag colorScheme="red" variant="solid">
              Free!
            </Tag>
          )}
        </HStack>
      </Box>
    </Box>
  );
};

export default MovieCard;
