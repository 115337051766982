import * as React from "react";
import {Box, Button, Popover, PopoverContent, PopoverTrigger} from "@chakra-ui/react";
import {RecordingIcon} from "../../icons";
import SelectDevicePopup from "./SelectDevicePopup";
import ForceMenuVisible from "./ForceMenuVisible";
import {useShowroomState} from "../../room/roomState";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";
import {useWeWatchController} from "../../room/WeWatchProvider";

const RecordButton = () => {

  const weWatchController = useWeWatchController();
  const {roomIsRecording} = useShowroomState();
  const mixpanelTracker = useShowroomMixpanelTracker();

  if (roomIsRecording) {
    return <Button
      colorScheme="gray"
      variant="solid"
      leftIcon={<RecordingIcon boxSize={6} color="red.500"/>}
      onClick={() => {
        weWatchController?.showroomClient?.stopRecording();
        mixpanelTracker('Create Recording', {actionType: 'Stop'});
      }}
    >
      Recording
    </Button>
  }

  return (
    <Box>
      <Popover isLazy>
        {({onClose}) => (
          <>
            <PopoverTrigger>
              <Button
                colorScheme="purple"
                leftIcon={<RecordingIcon boxSize={6}/>}
              >
                Record
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <ForceMenuVisible/>
              <SelectDevicePopup
                colorScheme="purple"
                startButtonLabel="Start Recording"
                helpText="Learn More About Recording"
                helpLink="https://www.wewatch.best/faqs/record-curations"
                onSubmit={async () => {
                  const showroomClient = weWatchController.showroomClient!;
                  showroomClient.startRecording();
                  mixpanelTracker('Create Recording', {actionType: 'Start'});
                  onClose();
                }}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default RecordButton;
