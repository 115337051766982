import * as React from "react";
import {Ref} from "react";
import {VolumeIcon} from "../../icons";
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack
} from "@chakra-ui/react";
import {useRecoilState} from "recoil";
import {chatVolumeState, videoVolumeState} from "../../room/roomState";
import {useDebouncedShowroomMixpanelTracker} from "../../mixpanelHooks";
import ForceMenuVisible from "./ForceMenuVisible";

type VolumeSliderProps = {
  title: string
  initialFocusRef?: Ref<HTMLDivElement>
  value: number
  onUpdate: (v: number) => void
}

const VolumeSlider = ({title, initialFocusRef, value, onUpdate}: VolumeSliderProps) => {

  return <VStack>
    <Text>{title}</Text>
    <Slider
      aria-label='slider-ex-3'
      defaultValue={0.3}
      orientation='vertical'
      minH='32'
      min={0}
      max={1}
      step={0.01}
      value={value}
      onChange={onUpdate}
    >
      <SliderTrack>
        <SliderFilledTrack bgColor="cyan"/>
      </SliderTrack>
      <SliderThumb ref={initialFocusRef}/>
    </Slider>

  </VStack>
}

const VolumeButton = () => {
  const initialFocusRef = React.useRef<HTMLDivElement>(null);

  const [videoVolume, setVideoVolume] = useRecoilState(videoVolumeState);
  const [chatVolume, setChatVolume] = useRecoilState(chatVolumeState);

  const trackVideoVolume = useDebouncedShowroomMixpanelTracker('Adjust Content Volume');
  const trackChatVolume = useDebouncedShowroomMixpanelTracker('Adjust Chat Volume');

  const handleVideoVolumeUpdate = (newVideoVolume: number) => {
    setVideoVolume(newVideoVolume);
    trackVideoVolume({'Final Volume': newVideoVolume}, {'Initial Volume': videoVolume});
  }

  const handleChatVolumeUpdate = (newChatVolume: number) => {
    setChatVolume(newChatVolume);
    trackChatVolume({'Final Volume': newChatVolume}, {'Initial Volume': chatVolume});
  }

  return (
    <Box>
      <Popover
        isLazy
        initialFocusRef={initialFocusRef}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Play"
            icon={<VolumeIcon/>}
            variant="ghost"
          />
        </PopoverTrigger>
        <PopoverContent width="auto">
          <ForceMenuVisible/>
          <PopoverArrow/>
          <PopoverBody>
            <HStack>
              <VolumeSlider
                title="Movie"
                initialFocusRef={initialFocusRef}
                value={videoVolume}
                onUpdate={handleVideoVolumeUpdate}
              />
              <VolumeSlider
                title="Chat"
                value={chatVolume}
                onUpdate={handleChatVolumeUpdate}
              />
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default VolumeButton;
