import React, {createContext, useCallback, useContext, useState} from "react";
import {Box, Button, Flex, Text} from "@chakra-ui/react";

type Handler = () => Promise<void>;

type AddAutoplayCallback = (element: HTMLMediaElement, handler: Handler) => void

export type AutoplayHandler = {
  addCallback: AddAutoplayCallback
  removeCallback: (element: HTMLMediaElement) => void
}

type Context = {
  callbacks: Handler[]
  addCallback: AddAutoplayCallback
  removeCallback: (element: HTMLMediaElement) => void
  clearCallbacks: () => void
}

export const AutoPlayHandlerContext = createContext<Context | undefined>(undefined);

export const useAutoplayHandler = (): AutoplayHandler => {
  const context = useContext(AutoPlayHandlerContext);
  if (!context) {
    throw new Error('Could not find AutoPlayHandlerContext in context. Are you inside a <AutoplayHandlerProvider>?');
  }
  return {
    addCallback: context.addCallback,
    removeCallback: context.removeCallback
  };
}

export const AutoplayHandlerProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {

  const [state, setState] = useState<[HTMLMediaElement, Handler][]>([]);

  const addState = useCallback((element: HTMLMediaElement, handler: Handler) => setState(s => {
    if (s.find(e => e[0] === element)) {
      return s;
    }
    return [...s, [element, handler]]
  }), [setState]);

  const removeState = useCallback((element: HTMLMediaElement) => setState(s => {
    return s.filter(e => e[0] !== element);
  }), [setState]);

  return <AutoPlayHandlerContext.Provider value={{
    callbacks: state.map(e => e[1]),
    addCallback: addState,
    removeCallback: removeState,
    clearCallbacks: () => setState([])
  }}>
    {children}
  </AutoPlayHandlerContext.Provider>
}

export const AutoplayHandler = () => {
  const context = useContext(AutoPlayHandlerContext);
  if (!context) {
    throw new Error('Could not find AutoPlayHandlerContext in context. Are you inside a <AutoplayHandlerProvider>?');
  }

  if (context.callbacks.length) {

    const handleJoinClick = async () => {
      const callbacks = [...context.callbacks];
      for (const cb of callbacks) {
        await cb();
      }
      context.clearCallbacks();
    };

    return <Flex
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      zIndex={15}
    >
      <Box bgColor="gray.700" borderRadius="lg" padding={4} maxWidth={500} textAlign="center">
        <Text fontSize="2xl">Are you still watching?</Text>
        <Button colorScheme="cyan" onClick={handleJoinClick}>
          Yes, continue
        </Button>
      </Box>
    </Flex>;
  }

  return <></>;
}
