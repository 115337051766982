import * as React from "react";
import {useEffect} from "react";
import Split from "../../components/Split";
import {Box, HStack, IconButton, VStack} from "@chakra-ui/react";
import {ArrowRightIcon, MicrophoneOffIcon, MicrophoneOnIcon, VideoOffIcon, VideoOnIcon} from "../../icons";
import useFullScreenState from "../../useFullScreenState";
import {fullScreenState, sidebarVisibleState} from "../../room/roomState";
import TopBar from "./TopBar";
import {useRecoilState} from "recoil";
import PlaybackZone from "./PlaybackZone";
import InviteButton from "./InviteButton";
import MemberSidebar from "./MemberSidebar";
import MemberAudio from "./MemberAudio";
import AutoJoinExistingMembers from "./AutoJoinExistingMembers";
import DeviceButton from "./DeviceButton";
import useHoverState from "./useHoverState";
import WeWatchProvider from "../../room/WeWatchProvider";
import {AutoplayHandler, AutoplayHandlerProvider} from "./AudoplayHandlerProvider";
import {useShowroomMixpanelTracker} from "../../mixpanelHooks";

const ShowroomPlayer = () => {
  const [sidebarVisible, setSidebarVisible] = useRecoilState(sidebarVisibleState);

  useFullScreenState(fullScreenState);
  const mixpanelTracker = useShowroomMixpanelTracker();

  useEffect(() => {
    mixpanelTracker('Showroom');
  }, [])

  useEffect(() => {
    const old = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = old;
    }
  }, []);

  useFullScreenState(fullScreenState);
  useHoverState();

  return (
    <AutoplayHandlerProvider>
      <WeWatchProvider>
        <AutoJoinExistingMembers/>
        <MemberAudio/>
        <Split enabled={sidebarVisible}>
          <VStack justifyContent="space-between" w="full" height="full" style={{maxHeight: '-webkit-fill-available'}}
                  spacing={0} position="relative">
            <AutoplayHandler/>
            <TopBar/>
            <PlaybackZone/>
          </VStack>
          <VStack justifyContent="space-between" w="full" height="full" spacing={0}>
            <HStack p={4} w="full" justifyContent="space-between">
              <IconButton
                aria-label="Collapse Side-Panel"
                icon={<ArrowRightIcon/>}
                variant="ghost"
                onClick={() => setSidebarVisible(false)}
              />
              {/*
            <Button
              variant="ghost"
              borderRadius={0}
              borderBottomStyle="solid"
              borderBottomWidth="2px"
              borderBottomColor="cyan.500"
            >
              Video
            </Button>
            <Button
              variant="ghost"
              borderRadius={0}
              borderBottomStyle="none"
              borderBottomWidth="2px"
              borderBottomColor="cyan.500"
            >
              Chat
            </Button>
            */}
              <InviteButton/>
            </HStack>
            <Box
              width="full"
              height="full"
              overflow="hidden"
              overflowY="auto"
              p={2}
            >
              <MemberSidebar/>
            </Box>
            <Box width="full">
              <HStack p={4} w="full" justifyContent="end" spacing={4}>
                <DeviceButton
                  kind="audio"
                  enabledIcon={<MicrophoneOnIcon/>}
                  disabledIcon={<MicrophoneOffIcon/>}
                />
                <DeviceButton
                  kind="video"
                  enabledIcon={<VideoOnIcon/>}
                  disabledIcon={<VideoOffIcon/>}
                />
              </HStack>
            </Box>
          </VStack>
        </Split>
      </WeWatchProvider>
    </AutoplayHandlerProvider>
  );
};

export default ShowroomPlayer;
