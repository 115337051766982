import {toTimeString} from "../../timestampUtils";
import {chakra, Text} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {movieDetailsState} from "../../room/roomState";
import {useWeWatchContext, useWeWatchController} from "../../room/WeWatchProvider";
import {MoviePlayerListener} from "../../room/MoviePlayerListener";

const PlaybackTime = () => {
  const [currentTime, setCurrentTime] = useState<number>(0);

  const weWatchController = useWeWatchController();
  const duration = useWeWatchContext()?.reactionController?.playbackPlaylist?.durationSec ?? 0;
  const movieDetails = useRecoilValue(movieDetailsState);

  useEffect(() => {
    const playbackController = weWatchController.playbackController;

    const listener: MoviePlayerListener = {
      onUpdate: (paused, currentTime) => {
        setCurrentTime(currentTime);
      }
    };

    playbackController.registerListener(listener);

    return () => {
      playbackController.removeListener(listener);
    }
  }, [weWatchController]);

  return (<Text style={{fontFeatureSettings: '"kearn" 1, "tnum" 1'}}>
    {toTimeString(currentTime)}
    <chakra.span color="gray.200">/ {toTimeString(movieDetails?.lengthMs ? movieDetails.lengthMs / 1000 : duration)}</chakra.span>
  </Text>);
}

export default PlaybackTime;
