import fscreen from 'fscreen';
import {useEffect} from "react";
import {RecoilState, useSetRecoilState} from "recoil";
import {useShowroomMixpanelTracker} from "./mixpanelHooks";

const useFullScreenState = (fullScreenState: RecoilState<boolean>) => {

  const setFullScreen = useSetRecoilState(fullScreenState);

  const mixpanelTracker = useShowroomMixpanelTracker();

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      const handleFsChange = () => {
        if (fscreen.fullscreenElement !== null) {
          mixpanelTracker('Adjust Fullscreen', {actionType: 'Enable'});
          setFullScreen(true);
        } else {
          mixpanelTracker('Adjust Fullscreen', {actionType: 'Disable'});
          setFullScreen(false);
        }
      };

      fscreen.addEventListener('fullscreenchange', handleFsChange, true);
      return () => {
        fscreen.removeEventListener('fullscreenchange', handleFsChange);
        if (fscreen.fullscreenElement !== null) {
          fscreen.exitFullscreen();
        }
      }
    }
  }, [setFullScreen]);
};

export default useFullScreenState;
