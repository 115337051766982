import {AutoplayPlaybackManager} from "./AutoplayPlaybackManager";
import {SegmentPlayer} from "./SegmentPlayer";

export class PlayerRegistry {

  private readonly autoplayManager: AutoplayPlaybackManager;
  private readonly players = new Map<string, SegmentPlayer>();

  constructor(autoplayManager: AutoplayPlaybackManager) {
    this.autoplayManager = autoplayManager;
  }

  removePlayer(id: string) {
    const player = this.players.get(id);
    if (player) {
      player.detach();
    }
    this.players.delete(id);
  }

  getAllPlayers(): SegmentPlayer[] {
    return Array.from(this.players.values());
  }

  getPlayer(id: string) {
    if (!this.players.has(id)) {
      this.players.set(id, new SegmentPlayer(id, this.autoplayManager));
    }
    return this.players.get(id) as SegmentPlayer;
  }
}
