import {ClientSocket, MessageKey} from "../client/ClientSocket";
import {Device} from "mediasoup-client";
import {ProducerTransport} from "./streaming/ProducerTransport";
import {ConsumerTransport} from "./streaming/ConsumerTransport";
import {Consumer} from "mediasoup-client/lib/Consumer";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";
import EventEmitter from "eventemitter3";
import {InputDeviceController, InputDeviceStatus} from "./InputDeviceController";
import {EchoCancellationLoopback} from "./EchoCancellationLoopback";
import {ServerShowroomState, ShowroomState} from "./ShowroomState";

export class ShowroomClient {
  private readonly eventEmitter = new EventEmitter();

  public readonly socket: ClientSocket;
  public readonly loopback?: EchoCancellationLoopback;

  private producerTransport?: ProducerTransport;
  private consumerTransport?: ConsumerTransport;
  private webcam?: InputDeviceController;
  private microphone?: InputDeviceController;

  private _latestShowroomState?: ShowroomState;

  constructor(socket: ClientSocket, loopback?: EchoCancellationLoopback) {
    this.socket = socket;
    this.loopback = loopback;
  }

  on(event: 'addMember', fn: (memberId: string) => void): ShowroomClient;
  on(event: 'removeMember', fn: (memberId: string) => void): ShowroomClient;
  on(event: 'addTrack', fn: (memberId: string, name: string, track: MediaStreamTrack) => void): ShowroomClient;
  on(event: 'disableTrack', fn: (memberId: string, kind: MediaKind) => void): ShowroomClient;
  on(event: 'enableTrack', fn: (memberId: string, kind: MediaKind) => void): ShowroomClient;
  on(event: 'setSelfDevice', fn: (kind: MediaKind, status: InputDeviceStatus, track?: MediaStreamTrack) => void): ShowroomClient;
  on(event: 'notification', fn: (memberId: string, message: MessageKey) => void): ShowroomClient;
  on(event: 'showroomState', fn: (state: ShowroomState) => void): ShowroomClient;
  on(event: string, fn: EventEmitter.EventListener<any, any>) {
    this.eventEmitter.on(event, fn);
    return this;
  }

  async connect() {
    const routerRtpCapabilities = await this.socket.request('getRouterRtpCapabilities');
    const device = new Device();
    await device.load({routerRtpCapabilities});
    this.producerTransport = await ProducerTransport.create(this, device);
    this.consumerTransport = await ConsumerTransport.create(this, device);
    const consumers = new Map<string, Consumer>();

    this.socket.on('notification', async (data) => {
      this.eventEmitter.emit('notification', data.memberId, data.message);
    })

    this.socket.on('removeMember', async ({producerMemberId}) => {
      this.eventEmitter.emit('removeMember', producerMemberId);
    });

    this.socket.on('newConsumer', async (data) => {
      const {
        producerMemberId,
        producerName,
        producerId,
        consumerId,
        kind,
        rtpParameters,
        producerPaused
      } = data;

      console.log('Connecting to ' + producerMemberId);

      this.eventEmitter.emit('addMember', producerMemberId);

      const consumer = await this.consumerTransport!.consume({
        id: consumerId,
        producerId,
        kind,
        rtpParameters
      });
      consumers.set(consumerId, consumer);
      this.eventEmitter.emit('addTrack', producerMemberId, producerName, consumer.track);
      if (producerPaused) {
        this.eventEmitter.emit('disableTrack', producerMemberId, kind);
      }
    });

    this.socket.on('consumerPaused', ({producerMemberId, consumerId}) => {
      const consumer = consumers.get(consumerId)!;
      consumer.pause();
      this.eventEmitter.emit('disableTrack', producerMemberId, consumer.track.kind as MediaKind);
    });

    this.socket.on('consumerResumed', ({producerMemberId, consumerId}) => {
      const consumer = consumers.get(consumerId)!;
      consumer.resume();
      this.eventEmitter.emit('enableTrack', producerMemberId, consumer.track.kind as MediaKind);
    });

    this.socket.on('showroomState', async (serverState) => {
      const state: ShowroomState = {
        ...serverState,
        selectedReactionId: serverState.reaction?.reactionId,
      }
      this._latestShowroomState = state;
      this.eventEmitter.emit('showroomState', state);
    })

    await this.socket.request('consume', {rtpCapabilities: device.rtpCapabilities});

    this.webcam = new InputDeviceController('video', this.producerTransport, {width: {min: 120, ideal: 320}});
    this.microphone = new InputDeviceController('audio', this.producerTransport);
    this.webcam.on('stateUpdated', (state, track) => {
      this.eventEmitter.emit('setSelfDevice', 'video', state, track);
    });
    this.microphone.on('stateUpdated', (state, track) => {
      this.eventEmitter.emit('setSelfDevice', 'audio', state, track);
    });
  }

  disconnect() {
    this.socket.disconnect();
    this.webcam?.stop()
    this.microphone?.stop();
    this.consumerTransport?.close();
    this.producerTransport?.close();
    this.eventEmitter.removeAllListeners();
  }

  startWebcam() {
    this.webcam?.start();
  }

  stopWebcam() {
    this.webcam?.stop();
  }

  get webcamController() {
    return this.webcam;
  }

  unmuteMic() {
    this.microphone?.resume();
  }

  muteMic() {
    this.microphone?.pause();
  }

  get microphoneController() {
    return this.microphone;
  }

  startRecording() {
    this.socket.send('startRecording');
  }

  stopRecording() {
    this.socket.send('stopRecording');
  }

  selectReaction(reactionId?: string) {
    this.socket.send('selectReaction', {reactionId: reactionId})
  }

  get latestShowroomState(): ShowroomState | undefined {
    return this._latestShowroomState;
  }
}
