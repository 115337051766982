import * as React from "react";
import {ChangeEvent, useEffect, useRef} from "react";
import {Box, useBreakpointValue} from "@chakra-ui/react";
import DraggableBox from "../../components/DraggableBox";
import MovieVideo from "./MovieVideo";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {menuVisibleState, movieBufferedState, sidebarVisibleState, useShowroomState} from "../../room/roomState";
import BottomToolbar from "./BottomToolbar";
import ReactionVideo from "./ReactionVideo";
import {useWeWatchContext, useWeWatchController} from "../../room/WeWatchProvider";
import ContinueWatchingPopup from "./ContinueWatchingPopup";
import SelfVideoBox from "./SelfVideoBox";

const MoviePlayer = ({forceReload}: { forceReload: () => void }) => {
  const {reactionController} = useWeWatchContext();
  const sidebarVisible = useRecoilValue(sidebarVisibleState);
  const menuVisible = useRecoilValue(menuVisibleState);
  const movieContainerRef = useRef(null);
  const clickBoxRef = useRef<HTMLDivElement>(null);
  const playbackController = useWeWatchController().playbackController;
  const {roomIsRecording} = useShowroomState();
  const setBuffered = useSetRecoilState(movieBufferedState);

  useEffect(() => {
    clickBoxRef.current?.addEventListener('touchstart', e => {
      e.preventDefault();
    });
  }, [clickBoxRef]);

  const defaultWindowWidth = useBreakpointValue(['180px', '225px', '270px', '360px'], {ssr: false});

  return (
    <>
      <Box width="full" height="full">
        <MovieVideo
          onProgress={(e: ChangeEvent<HTMLVideoElement>) => setBuffered(e.target.buffered)}
        />
        <Box width="full" height="full" p={0} position="relative">
          <ContinueWatchingPopup forceReload={forceReload}/>
          <Box width="full" height="full" p={0} position="relative">
            <Box
              width="full"
              height="full"
              position="absolute"
              top={0}
              right={0}
              left={0}
              bottom={0}
              onClick={() => playbackController?.togglePlayPause()}
              zIndex={8}
              ref={clickBoxRef}
            />
            <Box width="full" height="full" ref={movieContainerRef} position="relative">
              {roomIsRecording ? (
                <>
                  {!sidebarVisible && (
                    <DraggableBox
                      parentRef={movieContainerRef}
                      initialPosition={{bottom: 5, right: 5, width: defaultWindowWidth}}
                    >
                      <SelfVideoBox/>
                    </DraggableBox>
                  )}
                </>
              ) : (
                (reactionController?.playbackPlaylist?.members?.length ?? 0) > 0 && (
                  <DraggableBox
                    parentRef={movieContainerRef}
                    initialPosition={{bottom: 5, right: 5, width: defaultWindowWidth}}
                  >
                    <Box>
                      {reactionController?.playbackPlaylist?.members?.map(member => (
                        <ReactionVideo
                          key={member.id}
                          userId={member.id}
                          name={member.name}
                          showName={menuVisible}
                        />
                      ))}
                    </Box>
                  </DraggableBox>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <BottomToolbar/>
    </>
  );
};

export default MoviePlayer;
