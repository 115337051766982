import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import {WeWatchController} from "./WeWatchController";
import FullscreenLoader from "../components/FullscreenLoader";
import {useParams} from "react-router";
import {useResetRoomState} from "./roomState";
import {PlaybackController} from "../client/PlaybackController";
import {useWeWatchControllerListener} from "./WeWatchControllerListener";
import {useAuth0} from "@auth0/auth0-react";
import {useAutoplayHandler} from "../pages/play/AudoplayHandlerProvider";
import {PlaybackPlaylist} from "./PlaybackPlaylist";
import {ReactionPlaybackController} from "./ReactionPlaybackController";

export type WeWatchContextValue = {
  controller: WeWatchController
  reactionController?: ReactionPlaybackController
  setReactionController: (reactionController: ReactionPlaybackController | undefined) => void
}

export const WeWatchContext = createContext<WeWatchContextValue | undefined>(undefined);

export const useWeWatchController = (): WeWatchController => {
  const context = useContext(WeWatchContext);
  if (!context) {
    throw new Error('Could not find WeWatchController in context. Are you inside a <WeWatchProvider>?');
  }
  return context.controller;
}

export const useWeWatchContext = (): WeWatchContextValue => {
  const context = useContext(WeWatchContext);
  if (!context) {
    throw new Error('Could not find WeWatchController in context. Are you inside a <WeWatchProvider>?');
  }
  return context;
}

const WeWatchProvider: React.FC<{ children?: React.ReactNode }> = ({children}) => {

  const {roomId, movieId} = useParams<{ roomId: string, movieId: string }>();
  const {getIdTokenClaims, isAuthenticated, loginWithRedirect} = useAuth0();
  const resetRoomState = useResetRoomState();

  const [controller, setController] = useState<WeWatchController | undefined>();
  const [reactionController, setReactionController] = useState<ReactionPlaybackController | undefined>(undefined);

  const controllerListener = useWeWatchControllerListener();
  const autoplayHandler = useAutoplayHandler();

  useEffect(() => {
    if (controller) {
      if (movieId) {
        if (controller.movieId === movieId) {
          return;
        }
      } else if (roomId) {
        if (controller.roomId === roomId) {
          return;
        }
      }
    }

    (async () => {
      const claims = await getIdTokenClaims();
      const token = claims?.__raw;
      const playbackController = new PlaybackController(autoplayHandler);
      if (movieId) {
        const controller = WeWatchController.fromMovieId(movieId, playbackController, controllerListener, token);
        setController(controller);
        const params = new URLSearchParams(window.location.search);
        if (params.get('reactionId')) {
          controller.selectReaction(params.get('reactionId') || undefined);
        }
      } else if (roomId) {
        if (!token) {
          await loginWithRedirect({appState: {returnTo: window.location.pathname}});
          return;
        }
        WeWatchController.fromShowroomId(roomId, playbackController, controllerListener, token)
          .then(async c => {
            setController(c);
            while (!await c.attemptJoin()) {
              console.log('Attempting again');
            }
          });
      }
    })();

  }, [autoplayHandler, controller, setController, roomId, movieId, controllerListener]);

  // Make sure we disconnect the controller if the page unloads
  useEffect(() => {
    if (controller) {
      return () => {
        controller.disconnect();
        resetRoomState();
      };
    }
  }, [controller]);

  useEffect(() => {
    if (controller) {
      if (isAuthenticated) {
        getIdTokenClaims().then(c => controller.setToken(c?.__raw));
      } else {
        controller.setToken(undefined);
      }
    }
  }, [controller, isAuthenticated, getIdTokenClaims])

  if (!controller) {
    return <FullscreenLoader title="Connecting&hellip;"/>
  }

  return (
    <WeWatchContext.Provider value={{
      controller,
      reactionController: reactionController,
      setReactionController: (input: ReactionPlaybackController | undefined) => {
        console.log('Setting playlist to: ', input)
        controller?.playbackController?.setReactionController(input);
        setReactionController(input);
      }
    }}>
      {children}
    </WeWatchContext.Provider>
  );
};

export default WeWatchProvider;
