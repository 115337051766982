import {Flex, Text} from "@chakra-ui/react";
import * as React from "react";
import {GridLoader} from "react-spinners";

type FullscreenLoaderProps = {
  title: string
}

const FullscreenLoader = ({title}: FullscreenLoaderProps) => {
  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Flex direction="column">
        <GridLoader color="white"/>
      </Flex>
      <Flex direction="column">
        <Text fontWeight={500}>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FullscreenLoader;
