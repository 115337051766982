import * as React from "react";
import {Box} from "@chakra-ui/react";
import PageHeader from "./PageHeader";

const FixedPageHeader = () => {
  return (
    <>
      <Box position="fixed" width="100%" zIndex={100}>
        <PageHeader/>
      </Box>
      <Box height={16}/>
    </>
  );
};

export default FixedPageHeader;
