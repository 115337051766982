import {useRecoilValue} from "recoil";
import {reactionDetailsState} from "../../room/roomState";

const specialLinks = new Map([
  ['auth0|62296bb879736a0070434474', 'https://www.wewatch.best/spikima'],
  ['auth0|621c56d117a284006ab66144', 'https://www.wewatch.best'],
  ['auth0|626f294ba870880069ba20ac', 'https://www.wewatch.best'],
])

export const useSpecialReactionLink = (): string | undefined => {
  const reactionDetails = useRecoilValue(reactionDetailsState)

  for (const part of reactionDetails?.parts ?? []) {
    if (specialLinks.has(part.userId)) {
      return specialLinks.get(part.userId);
    }
  }
}
