import {chakra} from "@chakra-ui/react";
import * as React from "react";
import {RefObject, useEffect, useRef} from "react";
import {between, extractPosition, MousePosition} from "../utils";

type DraggableBoxProps = {
  parentRef: RefObject<HTMLDivElement>
  initialPosition: RelativePosition
  children?: React.ReactNode
}

type RelativePosition = {
  top?: number
  bottom?: number
  left?: number
  right?: number
  width?: string
};

type ResizeDirection = 'nw' | 'ne' | 'sw' | 'se';

type Offset = {
  left: number
  top: number
  width: number
  height: number
};

const setInitialPosition = (parentEl: HTMLDivElement, el: HTMLDivElement, initialPosition: RelativePosition) => {
  parentEl.style.position = 'relative';
  el.style.width = initialPosition.width ?? '180px';
  el.style.position = 'absolute';
  el.style.margin = '0 !important';
  if (initialPosition.top !== undefined) {
    el.style.top = `${initialPosition.top}px`;
  } else if (initialPosition.bottom !== undefined) {
    el.style.bottom = `${initialPosition.bottom}px`;
  }

  if (initialPosition.left !== undefined) {
    el.style.left = `${initialPosition.left}px`;
  } else if (initialPosition.right !== undefined) {
    el.style.right = `${initialPosition.right}px`;
  }
};

const calculateWidth = (direction: ResizeDirection | undefined, elementRect: DOMRect, position: MousePosition, offset: Offset) => {
  switch (direction) {
    case 'nw':
    case 'sw':
      return elementRect.width - (position.clientX - elementRect.left - offset.left);
    case 'ne':
    case 'se':
      return position.clientX - elementRect.left - offset.left + offset.width;
    case undefined:
      return elementRect.width;
  }
}

const calculateHeight = (direction: ResizeDirection | undefined, elementRect: DOMRect, position: MousePosition, offset: Offset) => {
  switch (direction) {
    case 'nw':
    case 'ne':
      return elementRect.height - (position.clientY - elementRect.top - offset.top);
    case 'sw':
    case 'se':
      return position.clientY - elementRect.top - offset.top + offset.height;
    case undefined:
      return elementRect.height;
  }
}

const adjustForAspectRatio = (elementRect: DOMRect, rawWidth: number, rawHeight: number): {
  width: number,
  height: number
} => {
  const ratio = elementRect.width / elementRect.height;
  const calculatedWidth = rawHeight * ratio;
  const calculatedHeight = rawWidth / ratio;
  return {
    width: Math.max(rawWidth, calculatedWidth),
    height: Math.max(rawHeight, calculatedHeight)
  }
}

const DraggableBox: React.FC<DraggableBoxProps> = (props) => {

  const {children, initialPosition, parentRef} = props;

  const draggingOffset = useRef<Offset | undefined>(undefined);
  const resizeDirection = useRef<ResizeDirection | undefined>(undefined);
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {

    const el = element.current;
    const parentEl = parentRef.current;

    if (!parentEl || !el) {
      return;
    }
    setInitialPosition(parentEl, el, initialPosition);

    const moveListener = (e: MouseEvent | TouchEvent) => {
      const offset = draggingOffset.current;
      if (!offset) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const elementRect = el.getBoundingClientRect();
      const parentRect = parentEl.getBoundingClientRect();

      const position = extractPosition(e);
      const direction = resizeDirection.current;

      const rawWidth = between(
        calculateWidth(direction, elementRect, position, offset),
        180,
        parentRect.width * 0.4
      );

      const rawHeight = between(
        calculateHeight(direction, elementRect, position, offset),
        0,
        parentRect.height * 0.4
      );

      const {width, height} = adjustForAspectRatio(elementRect, rawWidth, rawHeight);

      const top = between(
        direction ?
          (direction === 'sw' || direction === 'se' ? elementRect.top - parentRect.top : elementRect.bottom - height - parentRect.top) :
          position.clientY - offset.top - parentRect.top,
        0,
        parentRect.height - elementRect.height
      );
      const left = between(
        direction ?
          (direction === 'ne' || direction === 'se' ? elementRect.left - parentRect.left : elementRect.right - width - parentRect.left) :
          position.clientX - offset.left - parentRect.left,
        0,
        parentRect.width - elementRect.width
      );

      if (left < (parentRect.width / 2)) {
        el.style.left = `${left}px`;
        el.style.right = '';
      } else {
        el.style.left = '';
        el.style.right = `${parentRect.width - left - width}px`;
      }

      if (top < (parentRect.height / 2)) {
        el.style.top = `${top}px`;
        el.style.bottom = '';
      } else {
        el.style.top = '';
        el.style.bottom = `${parentRect.height - top - height}px`;
      }

      if (resizeDirection) {
        el.style.width = `${width}px`;
      }
    };

    const upListener = () => {
      draggingOffset.current = undefined;
      resizeDirection.current = undefined;
    }

    document.addEventListener('mousemove', moveListener);
    document.addEventListener('mouseup', upListener);
    document.addEventListener('touchmove', moveListener);
    document.addEventListener('touchend', upListener);

    return () => {
      document.removeEventListener('mousemove', moveListener);
      document.removeEventListener('mouseup', upListener);
      document.removeEventListener('touchmove', moveListener);
      document.removeEventListener('touchend', upListener);
    }
  }, [element, parentRef]);

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    const el = element.current;
    if (!el) {
      return;
    }
    const elementRect = el.getBoundingClientRect();
    const position = extractPosition(e);
    draggingOffset.current = {
      top: position.clientY - elementRect.top,
      left: position.clientX - elementRect.left,
      width: elementRect.width,
      height: elementRect.height
    }
  }

  const resizeMouseDownHandler = (direction: ResizeDirection) => {
    return (e: React.MouseEvent | React.TouchEvent) => {
      const el = element.current;
      if (!el) {
        return;
      }
      resizeDirection.current = direction;
      handleMouseDown(e);
      e.stopPropagation();
    }
  }

  return (
    <chakra.div
      zIndex={9}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      ref={element}
      borderRadius={10}
      border="3px solid transparent"
      transition="border-color 150ms"
      _hover={{
        borderColor: 'rgba(100, 223, 223, 1)'
      }}
    >
      <chakra.div width={3} height={3} top={0} left={0} position="absolute" zIndex={10} cursor="nw-resize"
                  onMouseDown={resizeMouseDownHandler('nw')}/>
      <chakra.div width={3} height={3} top={0} right={0} position="absolute" zIndex={10} cursor="ne-resize"
                  onMouseDown={resizeMouseDownHandler('ne')}/>
      <chakra.div width={3} height={3} bottom={0} left={0} position="absolute" zIndex={10} cursor="sw-resize"
                  onMouseDown={resizeMouseDownHandler('sw')}/>
      <chakra.div width={3} height={3} bottom={0} right={0} position="absolute" zIndex={10} cursor="se-resize"
                  onMouseDown={resizeMouseDownHandler('se')}/>
      {children}
    </chakra.div>
  );
};

export default DraggableBox;
