import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import * as React from "react";
import {useListMyPurchases} from "../../useWewatchApi";
import CenteredLoader from "../../components/CenteredLoader";
import {format} from "date-fns";

const maybeAddS = (input: string, num: number) => {
  if (num === 1) {
    return input;
  } else {
    return input + 's';
  }
}

const renderStatus = (tickets: Array<{status: 'INACTIVE' | 'ACTIVE' | 'EXPIRED' | 'CANCELLED'}>) => {
  const output = [];

  const activeTickets = tickets.filter(t => t.status === 'ACTIVE');
  const inactiveTickets = tickets.filter(t => t.status === 'INACTIVE');
  const expiredTickets = tickets.filter(t => t.status === 'EXPIRED');
  const cancelledTickets = tickets.filter(t => t.status === 'CANCELLED');

  if (activeTickets.length) {
    output.push(`${activeTickets.length} Active ${maybeAddS('Ticket', activeTickets.length)}`)
  }

  if (inactiveTickets.length) {
    output.push(`${inactiveTickets.length} Inactive ${maybeAddS('Ticket', inactiveTickets.length)}`)
  }

  if (expiredTickets.length) {
    output.push(`${expiredTickets.length} Expired ${maybeAddS('Ticket', expiredTickets.length)}`)
  }

  if (cancelledTickets.length) {
    output.push(`${cancelledTickets.length} Refunded ${maybeAddS('Ticket', cancelledTickets.length)}`)
  }

  return output.join(', ');
}

const MyPurchases = () => {
  const {loading, data} = useListMyPurchases();

  console.log(data);

  if (loading) {
    return <CenteredLoader/>;
  }

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>
            Purchase Date
          </Th>
          <Th>
            Movie
          </Th>
          <Th>
            Status
          </Th>
          <Th isNumeric>
            Price
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data?.map(purchase => (
          <Tr key={purchase.id}>
            <Td>
              {format(new Date(purchase.purchasedAt), 'MMM dd, yyyy')}
            </Td>
            <Td>
              {purchase.movieTitle}
            </Td>
            <Td>
              {renderStatus(purchase.tickets)}
            </Td>
            <Td isNumeric>
              ${purchase.settledPrice || purchase.initialPrice}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default MyPurchases;
