import React from 'react';
import {Route, Routes} from "react-router-dom";
import PlayPage from './pages/play';
import MoviePage from "./pages/movie/MoviePage";
import CheckoutPage from "./pages/movie/CheckoutPage";
import MePage from "./pages/me/MePage";
import HomePage from "./pages/home/HomePage";

function App() {

  return <Routes>
    <Route path="/play/:roomId" element={<PlayPage/>}/>
    <Route path="/movie/:movieId/play" element={<PlayPage/>}/>
    <Route path="/movie/:movieId/checkout" element={<CheckoutPage/>}/>
    <Route path="/movie/:movieId" element={<MoviePage/>}/>
    <Route path="/me/*" element={<MePage/>}/>
    <Route path="/" element={<HomePage/>}/>
  </Routes>
}

export default App;
