import {Box, Flex, Text, useToast} from "@chakra-ui/react";
import * as React from "react";
import {MessageKey} from "./client/ClientSocket";

export type CreateNotificationToast = (icon: React.ReactNode | undefined, title: React.ReactNode, description?: React.ReactNode) => void;

export const getNotificationMessage = (messageKey: MessageKey, isSelf: boolean) => {
  const haveHas = isSelf ? 'have' : 'has';
  switch (messageKey) {
    case 'PLAY_MOVIE':
      return `${haveHas} resumed the movie`;
    case 'PAUSED_MOVIE':
      return `${haveHas} paused the movie`;
    case 'START_RECORDING':
      return `${haveHas} started recording`;
    case 'STOP_RECORDING':
      return `${haveHas} stopped recording`;
    case 'JUMP_AHEAD':
      return `${haveHas} jumped forward`;
    case 'JUMP_BACK':
      return `${haveHas} jumped backward`;
  }
}

const useNotificationToast = (): CreateNotificationToast => {
  const toast = useToast();

  return (icon, title, description) => {
    toast({
      containerStyle: {
        margin: 0,
        minWidth: 0
      },
      render: () => {
        return <Flex
          backgroundColor="rgba(18, 18, 18, 0.8)"
          color="white"
          position="relative"
          p={3}
          alignItems="start"
          textAlign="start"
          minWidth="100px"
          maxWidth="300px"
          // mt="75px"
          // mb="-75px"
        >
          {icon}
          <Box flex={1} maxWidth="100%">
            <div>
              {title}
            </div>
            {description && (
              <div>
                <Text color="rgba(160, 160, 160, 1)">
                  {description}
                </Text>
              </div>
            )}
          </Box>
        </Flex>
      },
      position: 'top-right'
    })
  }
}

export default useNotificationToast;
