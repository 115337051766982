import {ClientSocket} from "./ClientSocket";
import {PlaybackState} from "../room/ShowroomState";
import {MoviePlayerListener} from "../room/MoviePlayerListener";
import {AutoplayPlaybackManager} from "../room/AutoplayPlaybackManager";
import {AutoplayHandler} from "../pages/play/AudoplayHandlerProvider";
import {PlayerRegistry} from "../room/PlayerRegistry";
import {ReactionPlaybackController} from "../room/ReactionPlaybackController";

type ServerTime = {
  timestamp: number
  asOf: Date
}

export class PlaybackController {

  private socket?: ClientSocket;
  private videoPlayer?: HTMLVideoElement;
  private readonly listeners = new Set<MoviePlayerListener>();
  public readonly autoplayManager: AutoplayPlaybackManager;
  public readonly players: PlayerRegistry;
  public reactionController?: ReactionPlaybackController;

  private lastServerPing?: ServerTime;

  private readonly onPlayerUpdate = () => {
    this.listeners.forEach(listener => {
      if (listener.onUpdate) {
        if (!this.reactionController) {
          return;
        }
        listener.onUpdate(this.reactionController.paused, this.reactionController.currentTime)
      }
    });
  }

  constructor(autoplayHandler: AutoplayHandler) {
    this.autoplayManager = new AutoplayPlaybackManager(autoplayHandler);
    this.players = new PlayerRegistry(this.autoplayManager);
  }

  set clientSocket(socket: ClientSocket | undefined) {
    this.socket = socket;
  }

  async syncWithServer(serverTimestamp: number, state: PlaybackState) {
    const paused = state === 'paused';

    if (!this.reactionController) {
      return;
    }

    console.log(`Current Time: ${this.reactionController.currentTime}; Server timestamp: ${serverTimestamp}`);
    if (this.reactionController.paused !== paused || Math.abs(this.reactionController.currentTime - serverTimestamp) > 2) {
      this.lastServerPing = {timestamp: serverTimestamp, asOf: new Date()};
      try {
        if (paused) {
          await this.reactionController.pause(serverTimestamp);
        } else {
          await this.reactionController.play(serverTimestamp);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  get currentTime(): number {
    return this.reactionController?.currentTime ?? 0;
  }

  get paused() {
    return this.reactionController?.paused ?? true;
  }

  play() {
    console.log('Resuming playback');
    if (this.socket) {
      this.socket.send('play', {timestamp: this.currentTime});
    } else {
      if (!this.reactionController) {
        console.error('Hmm, no playbackPlaylist')
      }
      this.reactionController?.play();
    }
  }

  pause() {
    console.log('Pausing playback');
    if (this.socket) {
      this.socket.send('pause', {timestamp: this.currentTime});
    } else {
      if (!this.reactionController) {
        console.error('Hmm, no playbackPlaylist')
      }
      this.reactionController?.pause();
    }
  }

  togglePlayPause() {
    console.log('Toggling play/pause');
    if (this.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  setTime(timestamp: number) {
    if (this.socket) {
      if (this.paused) {
        this.socket.send('pause', {timestamp: timestamp});
      } else {
        this.socket.send('play', {timestamp: timestamp});
      }
    } else {
      if (this.paused) {
        this.reactionController?.pause(timestamp);
      } else {
        this.reactionController?.play(timestamp);
      }
    }
  }

  registerListener(listener: MoviePlayerListener) {
    this.listeners.add(listener);
  }

  removeListener(listener: MoviePlayerListener): boolean {
    return this.listeners.delete(listener);
  }

  setReactionController(reactionController?: ReactionPlaybackController) {
    console.log('Setting new playback playlist: ' + reactionController);
    if (this.reactionController && !reactionController) {
      this.players.getAllPlayers().forEach(p => p.registerController(undefined));
    }
    this.reactionController = reactionController;
    if (reactionController) {
      reactionController.listener = () => this.onPlayerUpdate();
      this.onPlayerUpdate();
    }

    // @ts-ignore
    window['reactionController'] = reactionController;
  }

  attach(videoPlayer: HTMLVideoElement) {
    this.videoPlayer = videoPlayer;
    this.players.getPlayer('movie').attach(this.videoPlayer)
      .catch(e => { throw new Error(e)});;
  }

  detach() {
    if (this.videoPlayer) {
      this.players.removePlayer('movie');
    }
    this.videoPlayer = undefined;
  }

  attachReaction(id: string, videoPlayer: HTMLVideoElement) {
    this.players.getPlayer(id).attach(videoPlayer)
      .catch(e => { throw new Error(e)});;
  }

  detachReaction(id: string) {
    this.players.removePlayer(id);
  }
}
