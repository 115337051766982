import {EchoCancellationLoopback} from "./EchoCancellationLoopback";
import {isMobile, isSafari} from "react-device-detect";
import {AutoplayHandler} from "../pages/play/AudoplayHandlerProvider";

export class AutoplayPlaybackManager {

  private readonly autoplayHandler: AutoplayHandler;
  private readonly players = new Set<HTMLVideoElement>();
  private loopback?: EchoCancellationLoopback;

  constructor(autoplayHandler: AutoplayHandler) {
    this.autoplayHandler = autoplayHandler;
    if (!isSafari && !isMobile) {
      this.loopback = new EchoCancellationLoopback()
    }
  }

  add(element: HTMLVideoElement) {
    this.players.add(element);
    this.loopback?.addSource(element);
  }

  remove(element: HTMLVideoElement) {
    this.players.delete(element);
    this.loopback?.removeSource(element);
    this.autoplayHandler.removeCallback(element);
  }

  async play(element: HTMLVideoElement, timestamp?: number) {
    console.log('Playing at ' + timestamp + ': ' + element);
    if (timestamp !== undefined) {
      if (element.duration < timestamp) {
        this.pause(element, element.duration);
        return;
      }
      element.currentTime = timestamp;
    }
    await this.loopback?.resume();
    if (!element.paused) {
      // Already playing
      return;
    }
    const toUnMute: HTMLVideoElement[] = [];
    this.players.forEach(p => {
      if (p === element) {
        return;
      }
      if (!p.paused && !p.muted) {
        console.log('Muting', p);
        p.muted = true;
        toUnMute.push(p);
      }
    });
    try {
      console.log('Attempting to play', element);
      await element.play()
      console.log('Play successful');
    } catch (e) {
      console.log('Error while trying to play movie: ', e);
      this.autoplayHandler.addCallback(element, async () => {
        console.log('Autoplay handler invoked');
        await this.play(element);
      });
    } finally {
      toUnMute.forEach(p => {
        console.log('Unmuting', p);
        p.muted = false;
      });
      setTimeout(() => {
        toUnMute.forEach(p => {
          console.log('Mute status: ' + p.muted);;
          p.muted = false;
        });
      }, 100);
    }
  }

  pause(element: HTMLVideoElement, timestamp?: number) {
    if (timestamp !== undefined) {
      console.log(`Pausing at ${timestamp}. Current time ${element.currentTime}`, element);
      if (timestamp != element.currentTime) {
        element.currentTime = timestamp;
      }
    }
    this.autoplayHandler.removeCallback(element);
    if (element.paused) {
      return;
    }
    element.pause();
  }
}
