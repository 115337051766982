import {MessageKey} from "../client/ClientSocket";
import {
  memberAudioEnabledState,
  memberAudioTrackState,
  memberNameState,
  membersState,
  memberVideoEnabledState,
  memberVideoTrackState, selectedReactionIdState,
  selfDeviceState,
  showroomState
} from "./roomState";
import {SetterOrUpdater, useRecoilCallback, useRecoilState, useSetRecoilState} from "recoil";
import {MediaKind} from "mediasoup-client/lib/RtpParameters";
import {InputDeviceStatus} from "./InputDeviceController";
import {useMemo} from "react";
import {ShowroomState} from "./ShowroomState";

export interface WeWatchControllerListener {
  onAddMember: (memberId: string) => void
  onRemoveMember: (memberId: string) => void
  onAddTrack: (memberId: string, name: string, track: MediaStreamTrack) => void
  onDisableTrack: (memberId: string, kind: MediaKind) => void
  onEnableTrack: (memberId: string, kind: MediaKind) => void
  onSetSelfDevice: (kind: MediaKind, status: InputDeviceStatus, track?: MediaStreamTrack) => void
  onNotification: (memberId: string, message: MessageKey) => void
  onShowroomState: SetterOrUpdater<Partial<ShowroomState>>
  onChangeReaction: SetterOrUpdater<string | undefined>
}


const useInsertMember = () => {
  const [members, setMembers] = useRecoilState(membersState);

  return useRecoilCallback(
    () => (memberId: string) => {
      if (members.indexOf(memberId) < 0) {
        setMembers(members => {
          if (members.indexOf(memberId) < 0) {
            return [...members, memberId]
          } else {
            return members;
          }
        });
      }
    },
    [members]
  )
}

const useRemoveMember = () => {
  const [members, setMembers] = useRecoilState(membersState);

  return useRecoilCallback(
    () => (memberId: string) =>
      setMembers(members => members.filter(m => m !== memberId)),
    [members]
  )
}

const useAddTrack = () => {
  return useRecoilCallback(
    ({set}) => (memberId: string, name: string, track: MediaStreamTrack) => {
      if (track.kind === 'video') {
        set(memberVideoTrackState(memberId), track);
        set(memberVideoEnabledState(memberId), true);
      } else if (track.kind === 'audio') {
        set(memberAudioTrackState(memberId), track);
        set(memberAudioEnabledState(memberId), true);
      }
      set(memberNameState(memberId), name);
    },
    []
  )
}

const useDisableTrack = () => {
  return useRecoilCallback(
    ({set}) => (memberId: string, kind: MediaKind) => {
      if (kind === 'video') {
        set(memberVideoEnabledState(memberId), false);
      } else if (kind === 'audio') {
        set(memberAudioEnabledState(memberId), false);
      }
    },
    []
  )
}

const useEnableTrack = () => {
  return useRecoilCallback(
    ({set}) => (memberId: string, kind: MediaKind) => {
      if (kind === 'video') {
        set(memberVideoEnabledState(memberId), true);
      } else if (kind === 'audio') {
        set(memberAudioEnabledState(memberId), true);
      }
    },
    []
  )
}

const useSetSelfDevice = () => {
  return useRecoilCallback(
    ({set}) => (kind: MediaKind, status: InputDeviceStatus, track?: MediaStreamTrack) => {
      console.log(`Device ${kind} is now ${status}`)
      set(selfDeviceState(kind), {
        status,
        track
      });
    },
    []
  );
}

export const useWeWatchControllerListener = (): WeWatchControllerListener => {

  const insertMember = useInsertMember();
  const removeMember = useRemoveMember();
  const addTrack = useAddTrack();
  const disableTrack = useDisableTrack();
  const enableTrack = useEnableTrack();
  const setSelfDevice = useSetSelfDevice();
  const setShowroom = useSetRecoilState(showroomState);
  const setSelectedReactionId = useSetRecoilState(selectedReactionIdState);

  return useMemo(() => ({
    onAddMember: insertMember,
    onRemoveMember: removeMember,
    onAddTrack: addTrack,
    onDisableTrack: disableTrack,
    onEnableTrack: enableTrack,
    onSetSelfDevice: setSelfDevice,
    onNotification: (memberId, message) => { console.log(`Notification: ${memberId} - ${message}`)},
    onShowroomState: setShowroom,
    onChangeReaction: setSelectedReactionId
  }), [insertMember, removeMember, addTrack, disableTrack, enableTrack, setSelfDevice, setShowroom, setSelectedReactionId]);

}
