import * as React from "react";
import {Button, Menu, MenuButton, MenuItemOption, MenuList} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";

type DropdownSelectProps<T extends string> = {
  value?: T
  onChange: (val: T | undefined) => void
  options: { [key in T]: string }
  leftIcon?: React.ReactElement
  includeUndefined: true
} | {
  value?: T
  onChange: (val: T) => void
  options: { [key in T]: string }
  leftIcon?: React.ReactElement
  includeUndefined?: false
}

const DropdownSelect = <T extends string>({value, onChange, options, leftIcon, includeUndefined}: DropdownSelectProps<T>) => {

  return (
    <Menu matchWidth>
      <MenuButton
        as={Button}
        leftIcon={leftIcon}
        rightIcon={<ChevronDownIcon/>}
        textAlign="left"
        width="full"
      >
        {value ? (options[value] || value) : 'None'}
      </MenuButton>
      <MenuList>
        {includeUndefined && (
          <MenuItemOption
            onClick={() => onChange(undefined)}
            isChecked={value === undefined}
          >
            None
          </MenuItemOption>
        )}
        {Object.keys(options).map(val => {
          return (
            <MenuItemOption
              key={val}
              onClick={() => onChange(val as T)}
              isChecked={val === value}
            >
              {options[val as T]}
            </MenuItemOption>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default DropdownSelect;
