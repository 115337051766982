import * as React from "react";
import {useEffect} from "react";
import {useSetRecoilState} from "recoil";
import {forceMenuState} from "../../room/roomState";

const ForceMenuVisible = () => {

  const setForceMenuState = useSetRecoilState(forceMenuState);

  useEffect(() => {
    const rand = Math.random();

    setForceMenuState(s => [...s, rand]);

    return () => {
      setForceMenuState(s => s.filter(v => v !== rand))
    }
  }, [setForceMenuState]);

  return (
    <></>
  );
};

export default ForceMenuVisible;
