import * as React from "react";
import {AddUserIcon} from "../../icons";
import {Box, Button, Popover, PopoverContent, PopoverTrigger} from "@chakra-ui/react";
import SelectDevicePopup from "./SelectDevicePopup";
import {useSetRecoilState} from "recoil";
import {sidebarVisibleState, watchTogetherActivatedState} from "../../room/roomState";
import ForceMenuVisible from "./ForceMenuVisible";

const WatchWithFriendsButton = () => {
  const setWatchTogetherActivated = useSetRecoilState(watchTogetherActivatedState);
  const setSidebarVisible = useSetRecoilState(sidebarVisibleState);

  return (
    <Box>
      <Popover isLazy>
        {({onClose}) => (
          <>
            <PopoverTrigger>
              <Button
                colorScheme="cyan"
                leftIcon={<AddUserIcon boxSize={6}/>}
              >
                Watch with Friends
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <ForceMenuVisible/>
              <SelectDevicePopup
                helpText="Learn More About Co-Watching"
                helpLink="https://www.wewatch.best/faqs/watch-with-friends"
                onSubmit={() => {
                  setWatchTogetherActivated(true);
                  setSidebarVisible(true);
                  onClose();
                }}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default WatchWithFriendsButton;
