import {EventEmitter} from "eventemitter3";
import {useEffect, useState} from "react";

console.log('Loaded');

const devicesByType = {
  audio: new Map<string, MediaDeviceInfo>(),
  video: new Map<string, MediaDeviceInfo>()
}

const keysMatch = (a: Map<String, any>, b: Map<String, any>) => {
  if (a.size !== b.size) {
    return false;
  }

  for (const key of Array.from(a.keys())) {
    if (!b.has(key)) {
      return false;
    }
  }
  return true;
}

const updateDevices = async (kind: 'audio' | 'video') => {
  const devices = devicesByType[kind];

  const newDevices = new Map<string, MediaDeviceInfo>();

  const filteredDevices = (await navigator.mediaDevices.enumerateDevices())
    .filter(d => d.kind === (kind + 'input'));

  filteredDevices.forEach(device => {
    newDevices.set(device.deviceId, device);
  });

  if (!keysMatch(newDevices, devices)) {
    devicesByType[kind] = newDevices;
    eventEmitter.emit(kind);
  }
}

const eventEmitter = new EventEmitter<'audio' | 'video', any>();

export const hasDeviceAccess = async (types: 'audio' | 'video' | 'both'): Promise<boolean> => {
  const checkAudio = types !== 'video';
  const checkVideo = types !== 'audio';

  if (!checkAudio && !checkVideo) {
    return false;
  }

  try {
    await navigator.mediaDevices.getUserMedia({
      audio: checkAudio,
      video: checkVideo
    });
    return true;
  } catch (e) {
    if (e instanceof DOMException) {
      if (e.name === 'NotAllowedError' || e.name === 'SecurityError') {
        return false;
      }
      if (e.name === 'NotFoundError') {
        // No devices but we have access at least
        return true;
      }
    }
    console.log('Unexpected getUserMedia error', e);
    return false;
  }
}

const truePromise = async (): Promise<true> => {
  return true;
}

export const initializeDeviceAccess = async (types: 'audio' | 'video' | 'both'): Promise<boolean> => {
  const result = await hasDeviceAccess(types);
  if (result) {
    await Promise.all([
      types !== 'video' ? updateDevices('audio') : truePromise(),
      types !== 'audio' ? updateDevices('video') : truePromise(),
    ]);
  }
  return result;
}

export const useDevices = (kind: 'audio' | 'video'): MediaDeviceInfo[] => {
  const [devices, setDevices] = useState(Array.from(devicesByType[kind].values()))

  useEffect(() => {
    const handler = () => {
      setDevices(Array.from(devicesByType[kind].values()));
    }

    eventEmitter.on(kind, handler);

    return () => {
      eventEmitter.removeListener(kind, handler);
    }
  }, [kind]);

  return devices;
}

export const determineDefaultDevices = (): [string | undefined, string | undefined] => {
  const microphones = Array.from(devicesByType['audio'].values());
  const webcamDefault = Array.from(devicesByType['video'].values())?.[0];
  if (webcamDefault) {
    const matchingMicrophone = microphones.find(m => m.groupId === webcamDefault.groupId);
    if (matchingMicrophone) {
      return [webcamDefault.deviceId, matchingMicrophone.deviceId];
    }
  }
  return [webcamDefault?.deviceId, microphones?.[0]?.deviceId];
}

navigator.mediaDevices.ondevicechange = async (ev) => {
  console.log('Devices updated', ev)
  await Promise.all([
    updateDevices('audio'),
    updateDevices('video')
  ]);
}
