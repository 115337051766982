import axios from 'axios';
import {parseVtt, VttThumbnailFrame} from "./thumbnailVttParser";
import {toTimeString} from "./timestampUtils";

export class PreviewThumbnails {

  private readonly vttUrl: string;
  private thumbnailFrames?: VttThumbnailFrame[];

  constructor(vttUrl: string) {
    this.vttUrl = vttUrl;

    // noinspection JSIgnoredPromiseFromCall
    this.load();
  }

  updateThumbnail(containerElement: HTMLDivElement, imgElement: HTMLImageElement, timestampElement: HTMLDivElement, thumbnailTimestamp: number, timestamp: number) {
    const thumbnail = this.getThumbnail(thumbnailTimestamp);
    if (thumbnail) {
      if (thumbnail.spritePosition) {
        containerElement.style.width = `${thumbnail.spritePosition.width}px`;
        containerElement.style.height = `${thumbnail.spritePosition.height}px`;
        containerElement.style.position = 'relative';
        containerElement.style.overflow = 'hidden';
        imgElement.style.position = 'absolute';
        imgElement.style.left = `-${thumbnail.spritePosition.x}px`;
        imgElement.style.top = `-${thumbnail.spritePosition.y}px`;
        imgElement.style.maxWidth = 'initial';
      }
      imgElement.src = thumbnail.src;
    } else {
      imgElement.src = '';
    }
    timestampElement.innerHTML = toTimeString(timestamp);
  }

  private async load() {
    const {data: vttData} = await axios.get(this.vttUrl);

    const baseDir = this.vttUrl.substring(0, this.vttUrl.lastIndexOf('/'));

    this.thumbnailFrames = parseVtt(baseDir, vttData);
  }

  private getThumbnail(timestamp: number) {
    return this.thumbnailFrames?.find(f => f.startTime <= timestamp && f.endTime > timestamp);
  }
}
