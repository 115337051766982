import * as React from "react";
import {Box, Text, VStack} from "@chakra-ui/react";
import {useWeWatchController} from "../../room/WeWatchProvider";
import {useLoadTicket} from "../../useWewatchApi";
import ActivateTicketDialog from "./ActivateTicketDialog";
import {Elements} from "@stripe/react-stripe-js";
import {stripe} from "../../stripeConfig";
import Checkout from "../../components/checkout/Checkout";
import FullscreenLoader from "../../components/FullscreenLoader";
import ForceMenuVisible from "./ForceMenuVisible";

const ContinueWatchingContent = ({forceReload}: { forceReload: () => void }) => {
  const {movieId} = useWeWatchController();

  const {data, forceReload: forceReloadLocal} = useLoadTicket(movieId);

  return (
    <VStack margin="auto" py={6}>
      <ForceMenuVisible/>
      <Text fontSize="4xl">
        Continue Watching
      </Text>
      {data ? (
        data.numberInactiveTickets >= 1 ? (
          <ActivateTicketDialog movieId={movieId} onSuccess={forceReload}/>
        ) : (
          <Box maxWidth="900px">
            <Elements stripe={stripe}>
              <Checkout
                movieId={movieId}
                movieTitle={data.movieTitle}
                price={3.99}
                onSuccess={async () => {
                  await forceReloadLocal();
                  forceReload();
                }}
              />
            </Elements>
          </Box>
        )
      ) : (
        <FullscreenLoader title="Loading..."/>
      )}
    </VStack>
  );
};

export default ContinueWatchingContent;
