import * as React from "react";
import {useState} from "react";
import {Button, Input, InputGroup, InputRightElement} from "@chakra-ui/react";

type PromoCodeInputParams = {
  value: string
  onApply: (code: string) => void
}

const PromoCodeInput = ({value, onApply}: PromoCodeInputParams) => {

  const [inputValue, setInputValue] = useState(value);

  return (
    <InputGroup size="md" mb={2}>
      <Input
        placeholder="Enter Code"
        textTransform="uppercase"
        _placeholder={{
          textTransform: 'none'
        }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value.toUpperCase())}
      />
      <InputRightElement width="4.5rem">
        <Button variant="ghost" h="1.75rem" size="sm" onClick={() => onApply(inputValue)}>
          Apply
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PromoCodeInput;
