import {joinShowroom, startShowroom} from "../http";
import {PlaybackController} from "../client/PlaybackController";
import {ClientSocket} from "../client/ClientSocket";
import {WeWatchControllerListener} from "./WeWatchControllerListener";
import {ShowroomClient} from "./ShowroomClient";

const timeoutPromise = <T>(timeout: number, promise: Promise<T>): Promise<T> => {
  return Promise.race([
    promise,
    new Promise<T>((resolve, reject) => {
      setTimeout(() => reject(), timeout);
    })
  ]);
}

export type WeWatchControllerStatus = 'solo' | 'creating' | 'readyToJoin' | 'in_session'

export class WeWatchController {

  public readonly movieId: string;
  public readonly playbackController: PlaybackController;

  private _status: WeWatchControllerStatus = 'solo';
  private _roomId?: string;
  private _token?: string = undefined;
  private _socket?: ClientSocket;
  private _showroomClient?: ShowroomClient;
  private _listeners: Array<WeWatchControllerListener> = [];
  private _selectedReactionId?: string;

  private constructor(movieId: string, playbackController: PlaybackController, listener: WeWatchControllerListener, token?: string) {
    this.movieId = movieId;
    this.playbackController = playbackController;
    this._listeners.push(listener);
    this._token = token;
  }

  static fromMovieId(movieId: string, playbackController: PlaybackController, listener: WeWatchControllerListener, token?: string): WeWatchController {
    return new WeWatchController(movieId, playbackController, listener, token);
  }

  static async fromShowroomId(roomId: string, playbackController: PlaybackController, listener: WeWatchControllerListener, token: string): Promise<WeWatchController> {
    const joinResponse = await joinShowroom(roomId);
    if (joinResponse.error) {
      throw new Error('Cannot join showroom ' + roomId);
    }

    const controller = new WeWatchController(joinResponse.movieId, playbackController, listener, token);
    controller._roomId = roomId;
    controller._status = 'readyToJoin';
    return controller;
  }

  get status(): WeWatchControllerStatus {
    return this._status;
  }

  get roomId(): string | undefined {
    return this._roomId;
  }

  get showroomClient(): ShowroomClient | undefined {
    return this._showroomClient;
  }

  setToken(token: string | undefined) {
    if (token) {
      this._token = token;
    } else {
      this.disconnect();
      this._token = token;
    }
  }

  selectReaction(reactionId?: string) {
    if (reactionId === this._selectedReactionId) {
      return;
    }

    if (this.showroomClient) {
      if (this.showroomClient.latestShowroomState?.selectedReactionId !== reactionId) {
        this.showroomClient.selectReaction(reactionId);
      }
    }
    this._selectedReactionId = reactionId;
    this._listeners.forEach(l => l.onChangeReaction(reactionId));
  }

  async startShowroom() {
    if (this._status !== 'solo') {
      throw new Error(`Cannot start showroom when in status ${this._status}`);
    }

    console.log(`Creating showroom for movie ${this.movieId}`);
    this._status = 'creating';

    this._roomId = await startShowroom(this.movieId);
    console.log(`Showroom created with id ${this._roomId}`);
    this._status = 'readyToJoin';
  }

  async attemptJoin(): Promise<boolean> {
    if (this._status !== 'readyToJoin') {
      throw new Error(`Cannot start showroom when not in status ${this._status}`);
    }

    if (!this._token) {
      throw new Error('Cannot start showroom without token');
    }

    if (!this.roomId) {
      throw new Error('Cannot start showroom without room id');
    }

    const response = await joinShowroom(this.roomId);

    if (response.error || !response.mediaServerUrl) {
      return false;
    }

    const mediaServerUrl = response.mediaServerUrl;
    const roomId = this.roomId;

    return await timeoutPromise(5000, new Promise((resolve, reject) => {
      const socket = new ClientSocket(mediaServerUrl, roomId);
      this._socket = socket;

      this.playbackController.clientSocket = socket;

      socket.on('ready', async () => {

        if (!this._token) {
          reject('Cannot authenticate showroom without token');
          return;
        }

        const authResponse = await socket.request('authenticate', {bearer: this._token});

        if (authResponse.error) {
          reject(authResponse.error);
          return;
        }

        const showroomClient = new ShowroomClient(socket);
        this._showroomClient = showroomClient;

        this._listeners.forEach(listener => {
          showroomClient.on('addMember', listener.onAddMember);
          showroomClient.on('removeMember', listener.onRemoveMember);
          showroomClient.on('addTrack', listener.onAddTrack);
          showroomClient.on('disableTrack', listener.onDisableTrack);
          showroomClient.on('enableTrack', listener.onEnableTrack);
          showroomClient.on('setSelfDevice', listener.onSetSelfDevice);
          showroomClient.on('notification', listener.onNotification);
          showroomClient.on('showroomState', listener.onShowroomState);
        });
        showroomClient.on('showroomState', (state) => {
          this.playbackController.syncWithServer(state.timestamp, state.playbackState);
          this.selectReaction(state.selectedReactionId);
        })

        await showroomClient.connect();

        if (this._selectedReactionId) {
          showroomClient.selectReaction(this._selectedReactionId);
        }

        this._status = 'in_session';
        resolve(true);
      });

      socket.connect();
    }));
  }

  disconnect() {
    console.log('Disconnecting WeWatchController');
    this._socket?.disconnect();
    this.playbackController.clientSocket = undefined;
    this._socket = undefined;
  }
}
