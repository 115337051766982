import * as React from "react";
import {IconButton} from "@chakra-ui/react";
import {ExitFullScreenIcon, FullScreenIcon} from "../../icons";
import {useRecoilValue} from "recoil";
import {fullScreenState} from "../../room/roomState";
import fscreen from "fscreen";

const FullScreenButton = () => {

  const fullScreen = useRecoilValue(fullScreenState);

  if (!fscreen.fullscreenEnabled) {
    return null;
  }

  return (
    <IconButton
      aria-label="Fullscreen"
      icon={fullScreen ? <ExitFullScreenIcon/> : <FullScreenIcon/>}
      variant="ghost"
      onClick={() => {
        if (fullScreen) {
          fscreen.exitFullscreen();
        } else {
          fscreen.requestFullscreen(window.document.body);
        }
      }}
    />
  );
};

export default FullScreenButton;
