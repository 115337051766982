import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Split from "split.js";
import {Box, Flex} from "@chakra-ui/react";

type SplitProps = {
  enabled: boolean
  children: [React.ReactElement, React.ReactElement]
}

const SplitContainer = (props: SplitProps) => {

  const [main, sidebar] = props.children;
  const {enabled} = props;

  const [sizes, setSizes] = useState<number[]>([100, 0]);

  const mainRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (enabled) {
      const split = Split([mainRef.current!, sidebarRef.current!], {
        sizes: sizes,
        minSize: 200,
        gutterSize: 5,
        onDragEnd: setSizes
      });

      return () => split.destroy();
    }
  }, [enabled]);

  return <Flex width="full" flexDirection="row" position="fixed" height="full">
    <Box ref={mainRef} width="full" height="full">
      {main}
    </Box>
    {enabled && <Box ref={sidebarRef} height="full">
      {sidebar}
    </Box>}
  </Flex>
};

export default SplitContainer;
